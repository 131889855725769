@charset "UTF-8";

@import "../foundation/variable";
@import "../foundation/mixin";
@import "../foundation/extend";

$base-img-path: "/bob/pc/img/";


/* ========================
 * 共通フッター
 * ===================== */

.lo_footer {
	padding: 20px 20px 16px;
	background: #fff;

	.footer-nav,
	.footer-link,
	.footer-info-link {
		margin: 0;
	}

	.footer-main {
		margin-bottom: 16px;
	}

	.footer-nav {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 3px;

		li {
			&:not(:last-child) {
				&::after {
					content: '｜';
				}
			}

			a {
				font-size: 12px;
				line-height: 1.5;
				&:active, &:link, &:visited {
					color: $base-link-color;
				}
				&:hover {
					opacity: 1;
					text-decoration: underline;
				}
			}
		}
	}
	.footer-link {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 3px;
		li {
			&:not(:last-child) {
				margin-right: 5px;
			}
			a {
				font-size: 12px;
				line-height: 1.5;
				&:active, &:link, &:visited {
					color: $base-link-color;
				}
				&:hover {
					opacity: 1;
					text-decoration: underline;
				}
			}
		}
	}
	.footer-sitemap {
		a {
			font-size: 12px;
			line-height: 1.5;
			&:active, &:link, &:visited {
				color: $base-link-color;
			}
		}
	}
	.footer-info {
		display: flex;
		flex-wrap: wrap;
		//width: 100%;
	}
	.footer-info-txt {
		width: calc(100% - 193px);
	}
	.footer-logo {
		padding: 5px 16px 0 0;
		width: 177px;
		a {
			&:hover {
				opacity: 0.7;
				text-decoration: none;
			}
		}
	}
	.footer-info-link {
		display: flex;
		flex-wrap: wrap;
		li {
			&:not(:last-child) {
				margin-right: 10px;
			}
			a {
				font-size: 10px;
				line-height: 1.5;

				&:active, &:link, &:visited {
					color: $base-link-color;
				}
				&:hover {
					opacity: 1;
					text-decoration: underline;
				}
			}
		}
	}
	.footer-copyright {
		margin: 0;
		small {
			font-size: 10px;
			color: #c1c1c1;
		}
	}
	.footer-page-top {
		position: fixed;
		right: 30px;
		bottom: 30px;
		z-index: 100;
		margin-bottom: 0;
		a {
			position: relative;
			display: block;
			border-radius: 4px;
			width: 49px;
			height: 49px;
			background: rgba(0, 0, 0, .3);
			text-align: center;
			color: #fff;
		}
		.ico {
			position: absolute;
			left: 50%;
			top: 50%;
			display: inline-block;
			font-size: 3.4rem;
			transform: rotate(180deg) translate(50%, 48%);
		}
	}

	// 取扱い商品数、出版社数
	.foot-bottomBookCount {
		font-size: 13px;
		font-weight: bold;
		margin-bottom: 6px;
	}
}
