@charset "UTF-8";
@import "../../../anne/scss/sp/atoms/colors";
@import "../../../anne/scss/sp/templates/layout/font-variable";

.bw_frame-content:after, .bw_hdg-lv1-link .lv1-link:after, .bw_hdg-lv2-link:after, .bw_hdg-lv2-sub:after, .bw_list-desc:after, .bw_list-horizontal:after, .bw_link-product-rotate .product-rotate-list:after, .bw_link-bnr-rotate .bnr-rotate-list:after, .bw_link-product-card:after, .bw_link-product-card .product-card-state:after, .js-available .bw_link-product-card .product-card-link:after, .bw_link-recommend .recommend-list:after, .lite-modal-pager:after {
    display: block;
    content: '';
    clear: both;
}

/* ============================
 * HTML5 Elements
 * ========================== */
section,
article,
aside,
header,
footer,
nav,
main,
figure,
figcaption {
    display: block;
}

audio,
video,
canvas {
    display: inline-block;
}

/* ============================
 * Elements Normalize
 * ========================== */
html {
    font-family: $base-font-family;
    font-size: 62.5%;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    color: #2f2f2f;
}

a {
    background-color: transparent;
    color: $base-link-color;
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
}

a:hover, a:active, a:visited {
    outline: none;
}

a:hover, a:active, a:focus {
    text-decoration: underline;
}

em {
    font-style: normal;
    font-weight: bold;
}

b,
strong {
    font-weight: bold;
}

i,
dfn {
    font-style: normal;
}

sup,
sub {
    position: relative;
    line-height: 0;
    font-size: 75%;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

iframe {
    border: none;
}

img {
    border: none;
    max-width: 100%;
    height: auto;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

svg:not(:root) {
    overflow: hidden;
}

code,
kbd,
pre,
samp {
    font-family: $base-font-family;
    font-size: 1.3rem;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

button,
input,
select,
textarea {
    font-family: inherit;
    margin: 0;
}

textarea {
    overflow: auto;
}

button,
input[type="submit"],
label {
    cursor: pointer;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    display: none;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

button {
    outline: none;
}

/* ========================
 * 共通
 * ===================== */
.nowrap {
    white-space: nowrap;
}

/* ========================
 * コンテナ
 * ===================== */
.bw_frame-container {
    min-width: 1080px;
    background: #eef0f2;
}

/* ========================
 * コンテンツ
 * ===================== */
.bw_frame-content {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 28px;
    padding: 0 16px;
    min-width: 1060px;
    width: 100%;
}

.bw_frame-content.frame-content-fixed {
    margin: 0 auto;
}

@media screen and (min-width: 1030px) {
    .bw_frame-content.frame-content-fixed {
        padding: 0;
    }
}

.frame-content-2 .bw_frame-main {
    margin-right: 239px;
}

.frame-content-2 .bw_frame-sub {
    position: absolute;
    right: 16px;
    top: 0;
    width: 228px;
}

/* ========================
 * 見出しレベル1
 * ===================== */
.bw_hdg-lv1 {
    margin-bottom: 12px;
    padding: 10px 20px;
}

.akihabara .bw_hdg-lv1 {
    background-color: #ffa91c;
}

.shinjuku .bw_hdg-lv1 {
    background-color: #0094d6;
}

.yaesu .bw_hdg-lv1 {
    background-color: #5a1818;
}

.ikebukuro .bw_hdg-lv1 {
    background-color: #ff8ada;
}

.newuser .bw_hdg-lv1 {
    background-color: #7bc8ce;
}

.otona .bw_hdg-lv1 {
    background-color: #f36381;
}

.goods .bw_hdg-lv1 {
    background-color: #013e8a;
}

.bw_hdg-lv1 .lv1-txt {
    font-size: 1.5rem;
    line-height: 1.2;
    color: #fff;
}

.bw_hdg-lv1-link {
    position: relative;
    padding-right: 77px;
}

.bw_hdg-lv1-link .lv1-link {
    position: absolute;
    right: 11px;
    top: 7px;
}

.bw_hdg-lv1-link .lv1-link-item {
    float: left;
    margin-left: 5px;
}

.bw_hdg-lv1-link .lv1-link-item:first-child {
    margin-left: 0;
}

/* ========================
 * 見出しレベル2
 * ===================== */
.bw_hdg-lv2 {
    margin-bottom: 6px;
    border-style: solid;
    border-width: 0 0 2px;
    padding: 8px 20px 7px;
    background: #fff;
}

.akihabara .bw_hdg-lv2 {
    border-color: #ffa91c;
}

.shinjuku .bw_hdg-lv2 {
    border-color: #0094d6;
}

.yaesu .bw_hdg-lv2 {
    border-color: #5a1818;
}

.ikebukuro .bw_hdg-lv2 {
    border-color: #ff8ada;
}

.newuser .bw_hdg-lv2 {
    border-color: #7bc8ce;
}

.otona .bw_hdg-lv2 {
    border-color: #f36381;
}

.goods .bw_hdg-lv2 {
    border-color: #013e8a;
}

.bw_hdg-lv2 .lv2-txt {
    font-size: 1.5rem;
}

.akihabara .bw_hdg-lv2 .lv2-txt {
    color: #d98700;
}

.shinjuku .bw_hdg-lv2 .lv2-txt {
    color: #0094d6;
}

.yaesu .bw_hdg-lv2 .lv2-txt {
    color: #5a1818;
}

.ikebukuro .bw_hdg-lv2 .lv2-txt {
    color: #ff8ada;
}

.newuser .bw_hdg-lv2 .lv2-txt {
    color: #7bc8ce;
}

.otona .bw_hdg-lv2 .lv2-txt {
    color: #f36381;
}

.goods .bw_hdg-lv2 .lv2-txt {
    color: #013e8a;
}

.bw_hdg-lv2-link .lv2-txt {
    float: left;
}

.bw_hdg-lv2-link .lv2-link {
    float: right;
    margin: 0;
}

.bw_hdg-lv2-link .lv2-link a {
    position: relative;
    top: .2em;
    font-size: 1.15rem;
    font-weight: bold;
    color: #ffa91c;
}

.bw_hdg-lv2-link .lv2-small {
    display: table-cell;
}

.bw_hdg-lv2-sub {
    padding-right: 15px;
}

.bw_hdg-lv2-sub .lv2-txt {
    float: left;
}

.bw_hdg-lv2-sub .lv2-sub {
    position: relative;
    float: right;
    top: 2px;
    margin: 0;
}

.bw_hdg-lv2.bw_hdg-lv2-price {
    position: relative;
    padding: 15px 235px 14px 12px;
}

.bw_hdg-lv2.bw_hdg-lv2-price .lv2-content {
    display: table;
    width: 100%;
}

.bw_hdg-lv2.bw_hdg-lv2-price .lv2-txt,
.bw_hdg-lv2.bw_hdg-lv2-price .lv2 .bw_txt-price {
    display: table-cell;
}

.bw_hdg-lv2.bw_hdg-lv2-price .lv2-main {
    display: inline-block;
    font-size: 1.5rem;
}

.bw_hdg-lv2.bw_hdg-lv2-price .lv2-small {
    font-weight: bold;
}

.bw_hdg-lv2.bw_hdg-lv2-price .lv2-btn {
    position: absolute;
    right: 20px;
    top: 10px;
}

.bw_hdg-lv2.bw_hdg-lv2-price .bw_txt-price {
    font-size: 1.5rem;
    text-align: right;

    .txt-price-yen {
        font-size: 1.2rem;
    }

    .txt-price-tax {
        font-size: 1.2rem;
    }
}

.bw_hdg-lv2.bw_hdg-lv2-price .bw_txt-price .price-small {
    font-size: 1.3rem;
    -webkit-transform: none;
    transform: none;
}

.bw_hdg-lv2.bw_hdg-lv2-price .bw_btn {
    box-sizing: border-box;
    width: 202px;
}

/* ========================
 * 金額
 * ===================== */
.bw_txt-price {
    margin: 0;
    font-weight: bold;
    color: #e40007;
}

.bw_txt-price .price-small {
    margin: 0 4px 0 2px;
    font-size: 1rem;
    font-weight: normal;
}

.bw_txt-price .price-small + .price-small {
    margin-left: 2px;
}

.bw_txt-price .ico-series-set {
    position: relative;
    top: 2px;
    margin-left: 4px;
    font-size: 1.7rem;
    color: #878787;
}

/* ========================
 * 予約
 * ===================== */
.bw_txt-reserve {
    margin: 0;
    font-weight: bold;
    color: #0969a5;
}

.bw_txt-reserve .reserve-sub {
    padding: 0 2px;
}

/* ========================
 * 出版社・著者
 * ===================== */
.bw_txt-publisher {
    margin-bottom: 0;
    font-size: 1rem;
    color: #929292;
}

/* ========================
 * 記述リスト
 * ===================== */
.bw_list-desc {
    margin: 0;
}

.bw_list-desc .desc-title, .bw_list-desc .desc-content {
    float: left;
    margin-bottom: 12px;
}

.bw_list-desc .desc-title:last-child, .bw_list-desc .desc-content:last-child {
    margin-bottom: 0;
}

.bw_list-desc .desc-title {
    font-weight: bold;
    padding-right: 19px;
    clear: left;
}

.bw_list-desc .desc-content img {
    vertical-align: middle;
}

.bw_list-desc.bw_list-desc-colon .desc-title {
    padding-right: 14px;
}

.bw_list-desc.bw_list-desc-colon .desc-title:after {
    content: '：';
}

/* ========================
 * 横並びリスト
 * ===================== */
.bw_list-horizontal {
    margin: 0 0 0 -18px;
}

.bw_list-horizontal .horizontal-item {
    float: left;
    margin-left: 18px;
}

.bw_list-horizontal .horizontal-img {
    display: block;
    margin-bottom: 4px;
    text-align: center;
}

/* ========================
 * パンくず
 * ===================== */
.bw_link-breadcrumb {
    box-sizing: border-box;
    margin: 12px 0;
    padding: 0 16px;
    min-width: 960px;
    font-size: 0;
    color: #a1a1a1;
}

.bw_link-breadcrumb a {
    color: currentColor;
    font-size: 1rem;
}

.bw_link-breadcrumb .breadcrumb-item {
    display: inline-block;

    a:not(:last-of-type):after {
        content: '/';
        padding: 0 2px;
        font-size: 1rem;
    }
}

.bw_link-breadcrumb .breadcrumb-item:after {
    content: '＞';
    padding: 0 2px;
    font-size: 1rem;
}

.bw_link-breadcrumb .breadcrumb-item:last-child:after {
    display: none;
}

.bw_link-breadcrumb .breadcrumb-current {
    font-weight: normal;
}

/* ========================
 * もっと見る型リンク
 * ===================== */
.bw_link-more {
    margin: -20px 0 22px;
}

.bw_link-more .more-link {
    margin-bottom: 5px;
}

.bw_link-more .more-link:last-child {
    margin-bottom: 0;
}

.bw_link-more a {
    position: relative;
    display: block;
    padding: 13px 26px 12px 11px;
    background: #fff;
    line-height: 1;
    font-weight: bold;
    text-align: right;
}

.bw_link-more a:after {
    position: absolute;
    right: 11px;
    top: 50%;
    content: '\e914';
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: 'bookwalkersvg';
    font-weight: normal;
    color: #8b8b8b;
}

/* ========================
 * バナーリスト
 * ===================== */
.bw_link-bnr .bnr-item {
    margin-bottom: 5px;
    padding: 0 16px;
    text-align: center;
}

.bw_link-bnr .bnr-item a {
    position: relative;
    display: inline-block;
    max-width: 100%;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
    z-index: 0;
}

.bw_link-bnr .bnr-item a:hover, .bw_link-bnr .bnr-item a:focus {
    opacity: .7;
}

.bw_link-bnr .bnr-item:last-child {
    margin-bottom: 0;
}

.bw_frame-main .bw_link-bnr .bnr-item {
    padding: 0;
}

/* ========================
 * ローテート式商品リスト
 * ===================== */
.bw_link-product-rotate {
    padding: 0 23px 23px;
}

.bw_link-product-rotate .product-rotate-list {
    margin: 0 0 0 -20px;
}

.bw_link-product-rotate .product-rotate-main {
    padding-top: 20px;
}

.bw_link-product-rotate .product-rotate-item {
    float: left;
    margin-left: 20px;
    width: 160px;
}

.bw_link-product-rotate .product-rotate-item > a {
    display: block;
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
}

.bw_link-product-rotate .product-rotate-item > a:hover, .bw_link-product-rotate .product-rotate-item > a:focus {
    text-decoration: none;
    opacity: .7;
}

.bw_link-product-rotate .product-rotate-intro {
    position: relative;
    display: table-cell;
    box-sizing: border-box;
    border: 1px solid #bcbcbc;
    width: 160px;
    vertical-align: middle;
    text-align: center;
}

.bw_link-product-rotate .product-rotate-intro > img {
    max-width: 158px;
    max-height: 230px;
}

.bw_link-product-rotate .bw_txt-price {
    margin-top: 6px;
    width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.bw_link-product-rotate .bw_icon-new {
    right: -8px;
    top: -6px;
}

/* ========================
 * ローテート式バナーリスト
 * ===================== */
.bw_link-bnr-rotate {
    padding: 20px 23px;
}

.bw_link-bnr-rotate .bnr-rotate-list {
    margin: 0;
}

.bw_link-bnr-rotate .bnr-rotate-item {
    float: left;
    margin-right: 20px;
    text-align: center;
}

.bw_link-bnr-rotate .bnr-rotate-item a {
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
}

.bw_link-bnr-rotate .bnr-rotate-item a:hover, .bw_link-bnr-rotate .bnr-rotate-item a:focus {
    opacity: .7;
}

/* ========================
 * カード型商品リスト
 * ===================== */
.bw_link-product-card {
    margin: -11px 0 22px -10px;
}

.bw_link-product-card .product-card-item {
    float: left;
    margin: 11px 0 0 10px;
    width: 170px;
}

.bw_link-product-card .product-card-item.product-card-item-purchased .product-card-content {
    background: #ddd;
}

.bw_link-product-card .product-card-item.is-completed .product-card-content, .bw_link-product-card .product-card-item.is-processed .product-card-content {
    border-top-color: #999;
    box-shadow: 0 4px 0 0 #999 inset;
}

.bw_link-product-card .product-card-item.is-completed .product-card-content {
    border-top-color: #999;
}

.bw_link-product-card .product-card-item.is-completed .product-card-title:before {
    content: url("../img/pc/icon-check.png");
}

.bw_link-product-card .product-card-content {
    position: relative;
    display: table;
    box-sizing: border-box;
    padding: 13px 15px 18px;
    width: 100%;
    background: #fff;
}

.akihabara .bw_link-product-card .product-card-content {
    box-shadow: 0 4px 0 0 #ffa91c inset;
}

.shinjuku .bw_link-product-card .product-card-content {
    box-shadow: 0 4px 0 0 #0094d6 inset;
}

.yaesu .bw_link-product-card .product-card-content {
    box-shadow: 0 4px 0 0 #5a1818 inset;
}

.ikebukuro .bw_link-product-card .product-card-content {
    box-shadow: 0 4px 0 0 #ff8ada inset;
}

.newuser .bw_link-product-card .product-card-content {
    box-shadow: 0 4px 0 0 #7bc8ce inset;
}

.otona .bw_link-product-card .product-card-content {
    box-shadow: 0 4px 0 0 #f36381 inset;
}

.goods .bw_link-product-card .product-card-content {
    box-shadow: 0 -4px 0 0 #013e8a;
}

.bw_link-product-card .product-card-content:hover, .bw_link-product-card .product-card-content:focus {
    opacity: .7;
}

.bw_link-product-card .product-card-ribbon {
    position: absolute;
    right: -5px;
    top: -4px;
    z-index: 11;
}

.bw_link-product-card .product-card-desc {
    display: table-footer-group;
}

.bw_link-product-card .product-card-intro {
    display: table-header-group;
}

.bw_link-product-card .product-card-intro-inner {
    position: relative;
    display: table;
    width: 100%;
}

.bw_link-product-card .product-card-img {
    position: relative;
    display: table-cell;
    padding-bottom: 27px;
    height: 172px;
    text-align: center;
    vertical-align: middle;
}

.bw_link-product-card .product-card-img img {
    box-sizing: border-box;
    border: 1px solid #bcbcbc;
    max-width: 135px;
    max-height: 170px;
}

.bw_link-product-card .product-card-info {
    position: absolute;
    right: -10px;
    bottom: 5px;
}

.bw_link-product-card .product-card-state {
    position: absolute;
    left: 0;
    bottom: -17px;
}

.bw_link-product-card .product-card-state-item {
    float: left;
    margin-left: 2px;
}

.bw_link-product-card .product-card-state-item:first-child {
    margin-left: 0;
}

.bw_link-product-card .product-card-info-item {
    margin-bottom: 2px;
}

.bw_link-product-card .product-card-info-item:last-child {
    margin-bottom: 0;
}

.bw_link-product-card .product-card-title {
    display: block;
    margin-bottom: 11px;
    height: 3.6em;
    font-weight: bold;
    line-height: 1.3;
    color: #1d3850;
}

.bw_link-product-card .product-card-title h2 {
    display: inline;
    font-size: 1.3rem;
}

.bw_link-product-card .product-card-publisher {
    margin: 0;
}

.bw_link-product-card .product-card-publisher-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 140px;
}

.bw_link-product-card .product-card-label {
    margin: 0;
}

.bw_link-product-card .product-card-hover {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    border: 6px solid #98dabd;
    border-radius: 3px;
    width: 93%;
    height: 97%;
    opacity: .9;
    z-index: 10;
    cursor: pointer;
}

.bw_link-product-card .product-card-genre {
    position: relative;
    left: 0;
    top: 0;
}

.bw_link-product-card .product-card-link {
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 5px 0 0;
    width: 100%;
    background: #98dabd;
}

.bw_link-product-card .product-card-link-item {
    margin-bottom: 5px;
}

.bw_link-product-card .product-card-link-item a {
    display: block;
    border-radius: 3px;
    height: 29px;
    text-align: center;
    font-family: $base-font-family;
    font-size: 1.1rem;
    line-height: 29px;
    color: #fff;
}

.bw_link-product-card .product-card-link-item a:hover, .bw_link-product-card .product-card-link-item a:focus, .bw_link-product-card .product-card-link-item a.is-completed, .bw_link-product-card .product-card-link-item a.is-processed {
    background: #70b395;
    text-decoration: none;
}

.bw_link-product-card .product-card-link-item .ico {
    position: relative;
    top: 2px;
    margin-right: 3px;
    font-size: 1.4rem;
}

.bw_link-product-card .product-card-link-item .ico.ico-free {
    top: 3px;
    font-size: 1.8rem;
}

.bw_link-product-card .product-card-link-item:last-child {
    clear: both;
    margin-bottom: 0;
}

.bw_link-product-card .product-card-link-item.product-card-col-2 {
    float: left;
    width: 50%;
}

.bw_link-product-card.bw_link-product-card-adult .product-card-hover {
    border: 6px solid #b8a610;
}

.bw_link-product-card.bw_link-product-card-adult .product-card-link {
    background: #b8a610;
}

.bw_link-product-card.bw_link-product-card-adult .product-card-link-item a:hover, .bw_link-product-card.bw_link-product-card-adult .product-card-link-item a:focus, .bw_link-product-card.bw_link-product-card-adult .product-card-link-item a.is-completed, .bw_link-product-card.bw_link-product-card-adult .product-card-link-item a.is-processed {
    background: #8e8004;
    text-decoration: none;
}

.bw_link-product-card .bw_icon-deal {
    position: absolute;
    left: -15px;
    top: -9px;
}

.bw_link-product-card .bw_icon-coin {
    position: absolute;
    left: -15px;
    bottom: 28px;
}

.bw_link-product-card .bw_icon-completion {
    position: absolute;
    top: 185px;
    left: 16px;
}

.bw_link-product-card .bw_icon-genre {
    padding: 2px 8px;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
}

.bw_link-product-card .bw_txt-price {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 140px;
}

/* ========================
 * ページャー
 * ===================== */
.bw_link-pager {
    display: table;
    box-sizing: border-box;
    padding: 8px 10px;
    width: 100%;
    background: #fff;
}

.bw_link-pager .pager-main, .bw_link-pager .pager-sub {
    display: table-cell;
    vertical-align: middle;
}

.bw_link-pager .pager-main {
    width: 100%;
}

.bw_link-pager .pager-main ul {
    margin: 0;
}

.bw_link-pager .pager-sub {
    white-space: nowrap;
}

.bw_link-pager .pager-sub p {
    margin: 0;
}

.bw_link-pager .pager-list {
    position: relative;
    display: table;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 44px;
}

.bw_link-pager .pager-list a,
.bw_link-pager .pager-list .pager-num-current {
    display: block;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px 8px;
    height: 24px;
    font-weight: normal;
}

.bw_link-pager .pager-list span {
    display: block;
}

.bw_link-pager .pager-num-list {
    font-size: 0;
    text-align: center;
}

.bw_link-pager .pager-num-item {
    display: inline-block;
    font-size: 1.3rem;
}

.bw_link-pager .pager-num-current {
    background: #bbbaad;
    color: #fff;
}

.bw_link-pager .pager-direction-item {
    position: absolute;
}

.bw_link-pager .pager-direction-item-prev {
    left: 0;
    top: 0;
}

.bw_link-pager .pager-direction-item-prev .ico-triangle {
    position: relative;
    top: -1px;
    padding: 0;
    -webkit-transform: rotate(180deg) scale(0.8);
    transform: rotate(180deg) scale(0.8);
}

.bw_link-pager .pager-direction-item-next {
    right: 0;
    top: 0;
}

.bw_link-pager .pager-direction-item-next .ico-triangle {
    padding: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
}

.bw_link-pager .pager-direction-item a {
    box-shadow: 0 -2px 0 0 #807e6a inset;
    padding: 7px;
    background: #a4a397;
    font-size: 1rem;
    color: #fff;
}

.bw_link-pager .pager-direction-item a:hover, .bw_link-pager .pager-direction-item a:focus {
    box-shadow: 0 2px 0 0 #807e6a inset;
    padding: 8px 7px 6px;
    text-decoration: none;
}

/* ========================
 * おすすめ情報
 * ===================== */
.bw_link-recommend {
    padding: 20px 17px;
}

.bw_link-recommend .recommend-content {
    padding: 15px;
    background: #f8fafb;
}

.bw_link-recommend .recommend-list {
    margin: 0;
    padding: 10px 10px 0;
}

.bw_link-recommend .recommend-item {
    float: left;
    margin: 0 15px 0 0;
}

.bw_link-recommend .recommend-item a {
    position: relative;
    display: table-cell;
    border: 1px solid #eef0f2;
    width: 132px;
    height: 132px;
    background: #fff;
    text-align: center;
    vertical-align: middle;
    outline: none;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
}

.bw_link-recommend .recommend-item a:hover, .bw_link-recommend .recommend-item a:focus {
    opacity: .7;
    text-decoration: none;
}

.bw_link-recommend .recommend-item a img {
    max-width: 112px;
    max-height: 112px;
}

.bw_link-recommend .recommend-item .bw_icon-circle {
    position: absolute;
    left: -6px;
    top: -6px;
    width: 28px;
    height: 28px;
    font-size: 1.6rem;
    line-height: 1.9;
    z-index: 5;
}

.bw_link-recommend .recommend-item .bw_icon-circle.bw_icon-circle-twitter {
    line-height: 1.8;
}

.bw_link-recommend .recommend-content-free {
    box-sizing: border-box;
    padding: 10px;
    max-width: 800px;
    background: #fff;
}

.bw_link-recommend .recommend-content-free > *:last-child {
    margin-bottom: 0 !important;
}

.bw_link-recommend .recommend-content-free-txt {
    width: 800px;
}

.bw_link-recommend .recommend-img {
    margin-bottom: 10px;
}

.bw_link-recommend .recommend-img img {
    max-width: 780px;
    max-height: 700px;
}

.bw_link-recommend .recommend-thumb-img {
    display: block;
}

.bw_link-recommend .recommend-thumb-img + .recommend-thumb-txt {
    margin-top: 4px;
}

.bw_link-recommend .recommend-thumb-txt {
    display: block;
    padding: 0 8px;
    font-weight: bold;
}

.bw_link-recommend .recommend-thumb-youtube {
    position: relative;
}

.bw_link-recommend .recommend-thumb-youtube a {
    background: transparent;
    z-index: 2;
}

.bw_link-recommend .recommend-thumb-youtube-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 132px;
    height: 132px;
    overflow: hidden;
    z-index: 1;
}

.bw_link-recommend .recommend-thumb-youtube-content > iframe {
    margin-left: -55px;
}

/* ========================
 * ボタン
 * ===================== */
.bw_btn {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    box-shadow: 0 -3px 0 0 rgba(0, 0, 0, 0.3) inset;
    border-radius: 3px;
    padding: 0 10px;
    height: 35px;
    font-family: $base-font-family;
    line-height: 35px;
    font-weight: bold;
}

.bw_btn.is-processed {
    box-shadow: none !important;
    border-width: 2px;
    border-style: solid;
}

.bw_btn.is-processed:hover, .bw_btn.is-processed:focus {
    box-shadow: none;
    padding: 0 10px;
    line-height: 35px;
}

.bw_btn.is-completed {
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.3) inset !important;
    background: #4d4d4d !important;
    line-height: 37px !important;
    color: #aaa !important;
}

.bw_btn.is-completed:hover, .bw_btn.is-completed:focus {
    padding: 0 10px;
    line-height: 37px;
}

.bw_btn:hover, .bw_btn:focus {
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.3) inset;
    padding: 2px 10px 0;
    line-height: 33px;
    text-decoration: none;
}

.bw_btn .ico {
    position: relative;
}

.bw_btn .btn-txt-sub {
    margin-left: 4px;
}

.bw_btn.bw_btn-default {
    background: #e0e0e0;
    color: #000;
    text-align: center;
}

.bw_btn.bw_btn-primary {
    background: #518e48;
    color: #fff;
}

.bw_btn.bw_btn-primary .ico {
    position: relative;
    top: 2px;
    font-size: 1.7rem;
}

.bw_btn.bw_btn-primary .ico-free {
    top: 8px;
    font-size: 3.5rem;
    line-height: 0;
}

.bw_btn.bw_btn-primary.is-processed {
    border: 2px solid #518e48;
    background: #ffffff;
    color: #518e48;
}

.bw_btn.bw_btn-buy-gift {
    background: #93bc4e;
    color: #fff;
}

.bw_btn.bw_btn-reserve {
    background: #0969a5;
    text-align: center;
    color: #fff;
}

.bw_btn.bw_btn-reserve .ico {
    position: relative;
    top: 3px;
    font-size: 1.8rem;
}

.bw_btn.bw_btn-reserve.is-processed {
    border: 2px solid #0969a5;
    background: #fff;
    color: #0969a5;
}

.bw_btn.bw_btn-check {
    box-shadow: 0 -3px 0 0 #4e4e4e inset;
    background: #e0e0e0;
    color: #000;
    text-align: center;
}

.bw_btn.bw_btn-check:hover, .bw_btn.bw_btn-check:focus {
    box-shadow: 0 3px 0 0 #4e4e4e inset;
}

.bw_btn.bw_btn-follow {
    background: #e0e0e0;
    color: #000;
    font-size: 1rem;
    text-align: center;
}

.bw_btn.bw_btn-follow .ico {
    top: 3px;
    line-height: 1;
    font-size: 1.6rem;
}

.bw_btn.bw_btn-follow.bw_btn-small {
    height: 18px;
}

.bw_btn.bw_btn-follow.bw_btn-small .ico {
    top: 0;
    font-size: 1.6rem !important;
}

.bw_btn.bw_btn-follow.is-completed {
    cursor: pointer;
}

.bw_btn.bw_btn-trial {
    background: #7bc8ce;
    box-shadow: 0 -3px 0 0 rgba(0, 0, 0, 0.25) inset;
    color: #fff;
}

.bw_btn.bw_btn-trial:hover, .bw_btn.bw_btn-trial:focus {
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.25) inset;
    text-decoration: none;
}

.bw_btn.bw_btn-trial .ico-book {
    top: 1px;
    font-size: 1.5rem;
}

.bw_btn.bw_btn-purchase {
    background: #f99403;
    color: #fff;
}

.bw_btn.bw_btn-purchase .ico-purchase {
    position: relative;
    top: 2px;
    margin-right: 2px;
    font-size: 1.7rem;
}

.bw_btn.bw_btn-share-url {
    box-sizing: border-box;
    height: 25px;
    padding: 4px 5px;
    background: #8b99a1;
    color: #fff;
    font-size: 1.5rem;
    line-height: 1;
}

.bw_btn.bw_btn-share-url:hover, .bw_btn.bw_btn-share-url:focus {
    text-decoration: none;
}

.bw_btn.bw_btn-twitter {
    box-sizing: border-box;
    height: 25px;
    padding: 4px 5px;
    background: #55acee;
    color: #fff;
    font-size: 1.5rem;
    line-height: 1;
}

.bw_btn.bw_btn-twitter:hover, .bw_btn.bw_btn-twitter:focus {
    text-decoration: none;
}

.bw_btn.bw_btn-facebook {
    box-sizing: border-box;
    height: 25px;
    padding: 4px 1px 1px 6px;
    background: #3b5998;
    color: #fff;
    font-size: 1.8rem;
    line-height: 1;
}

.bw_btn.bw_btn-facebook .ico-facebook {
    position: relative;
    left: 2px;
    top: 1px;
}

.bw_btn.bw_btn-facebook:hover, .bw_btn.bw_btn-facebook:focus {
    text-decoration: none;
}

.bw_btn.bw_btn-other {
    background: #a4a397;
    color: #fff;
}

.bw_btn.bw_btn-small {
    position: relative;
    display: block;
    box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.3) inset;
    padding: 0;
    width: 29px;
    height: 28px;
    line-height: 14px;
    text-align: center;
}

.bw_btn.bw_btn-small.bw_btn-check {
    box-shadow: 0 -2px 0 0 #4e4e4e inset;
}

.bw_btn.bw_btn-small.bw_btn-check:hover, .bw_btn.bw_btn-small.bw_btn-check:focus {
    box-shadow: 0 2px 0 0 #4e4e4e inset;
}

.bw_btn.bw_btn-small .btn-txt {
    position: absolute;
    left: 50%;
    display: block;
    margin-top: 2px;
    width: 200%;
    font-size: 1rem;
    line-height: 1;
    white-space: nowrap;
    -webkit-transform: scale(0.7) translateX(-71%);
    transform: scale(0.7) translateX(-71%);
}

.bw_btn.bw_btn-small .ico {
    display: block;
    font-size: 1.3rem !important;
}

.bw_btn.bw_btn-small .ico-free {
    top: 0;
    line-height: .6;
    font-size: 2rem !important;
}

.bw_btn.bw_btn-small .ico-check {
    top: 3px;
}

.bw_btn.bw_btn-small:hover, .bw_btn.bw_btn-small:focus {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3) inset;
}

.bw_btn.bw_btn-small:hover .ico, .bw_btn.bw_btn-small:focus .ico {
    top: 3px;
}

.bw_btn.bw_btn-small:hover .ico-free, .bw_btn.bw_btn-small:focus .ico-free {
    top: 1px;
}

.bw_btn.bw_btn-small:hover .ico-check, .bw_btn.bw_btn-small:focus .ico-check {
    top: 4px;
}

.bw_btn.bw_btn-small:hover .btn-txt, .bw_btn.bw_btn-small:focus .btn-txt {
    margin-top: 3px;
}

.bw_btn.bw_btn-small.is-processed:hover, .bw_btn.bw_btn-small.is-processed:focus {
    padding: 0;
    line-height: 14px;
}

.bw_btn.bw_btn-small.is-processed:hover .ico, .bw_btn.bw_btn-small.is-processed:focus .ico {
    top: 2px !important;
}

.bw_btn.bw_btn-small.is-processed:hover .btn-txt, .bw_btn.bw_btn-small.is-processed:focus .btn-txt {
    margin-top: 2px;
}

.bw_btn.bw_btn-small.is-completed {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3) inset;
    padding: 0;
}

.bw_btn.bw_btn-small.is-completed .ico {
    top: 2px !important;
}

.bw_btn.bw_btn-small.is-completed:hover .btn-txt, .bw_btn.bw_btn-small.is-completed:focus .btn-txt {
    margin-top: 2px;
}

.bw_btn.bw_btn-block {
    display: block;
    text-align: center;
}

.bw_btn.bw_btn-login {
    box-shadow: none;
    border: 1px solid #cbced1;
    color: #1d4a6d;
    background-color: white;
}

.bw_btn.bw_btn-login:hover {
    opacity: .7;
}

.btn-tooltip {
    position: absolute;
    left: 0;
    top: -32px;
    border-width: 2px;
    border-style: solid;
    padding: 0 10px;
    width: auto;
    height: 27px;
    border-radius: 3px;
    background: #fff;
    font-size: 1.2rem;
    line-height: 27px;
    font-weight: normal;
    color: #2f2f2f;
    white-space: nowrap;
    z-index: 3;
}

.akihabara .btn-tooltip {
    border-color: #ffa91c;
}

.shinjuku .btn-tooltip {
    border-color: #0094d6;
}

.yaesu .btn-tooltip {
    border-color: #5a1818;
}

.ikebukuro .btn-tooltip {
    border-color: #ff8ada;
}

.newuser .btn-tooltip {
    border-color: #7bc8ce;
}

.otona .btn-tooltip {
    border-color: #f36381;
}

.goods .btn-tooltip {
    border-color: #013e8a;
}

[class^="ico-"] {
    font-family: "bookwalker" !important;
}

/* ========================
 * ジャンル
 * ===================== */
.bw_icon-genre {
    display: inline-block;
    padding: 2px 4px;
    border-radius: 8px;
    font-family: $base-font-family;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1;
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
}

.bw_icon-genre.bw_icon-genre-book {
    background: #5ec02c;
    color: #fff;
}

.bw_icon-genre.bw_icon-genre-comic {
    background: #00a4db;
    color: #fff;
}

.bw_icon-genre.bw_icon-genre-novel {
    background: #f6ae00;
    color: #fff;
}

.bw_icon-genre.bw_icon-genre-other {
    background: #a4a397;
    color: #fff;
}

/* ========================
 * 完結
 * ===================== */
.bw_icon-completion {
    display: inline-block;
    padding: 4px 6px;
    background: #244c07;
    font-family: $base-font-family;
    line-height: 1;
    vertical-align: baseline;
    font-size: 1rem;
    color: #fff;
}

/* ========================
 * NEW
 * ===================== */
.bw_icon-new {
    position: absolute;
    right: -5px;
    top: -4px;
}

/* ========================
 * 予約
 * ===================== */
.bw_icon-reserve {
    position: absolute;
    right: -5px;
    top: -4px;
}

/* ========================
 * オトク
 * ===================== */
.bw_icon-deal {
    position: absolute;
    left: 0;
    top: 0;
}

/* ========================
 * コイン
 * ===================== */
.bw_icon-coin {
    position: absolute;
    left: 0;
    bottom: 0;
}

/* ========================
 * 無料
 * ===================== */
.bw_icon-free {
    position: absolute;
    right: -11px;
    top: -11px;
}

/* ========================
 * 限定特典
 * ===================== */
.bw_icon-amenity {
    position: absolute;
    right: -13px;
    bottom: -10px;
    z-index: 15;
}

/* ========================
 * 丸型アイコン
 * ===================== */
.bw_icon-circle {
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 22px;
    font-size: 1.1rem;
    text-align: center;
    color: #fff;
}

.bw_icon-circle.bw_icon-circle-free {
    background: #4f409f;
}

.bw_icon-circle.bw_icon-circle-twitter {
    background: #55acee;
}

.bw_icon-circle.bw_icon-circle-youtube {
    background: #244c07;
}

.bw_icon-circle.bw_icon-circle-staff {
    background: #7d0022;
}

.bw_icon-circle.bw_icon-circle-editor {
    background: #6a3906;
}

.bw_icon-circle.bw_icon-circle-author {
    background: #001c58;
}

/* ========================
 * 種類
 * ===================== */
.bw_icon-type {
    display: inline-block;
    border-width: 2px;
    border-style: solid;
    padding: 5px 8px 4px;
    font-size: 1.4rem;
    font-family: $base-font-family;
    line-height: 1;
    font-weight: bold;
    vertical-align: top;
}

.akihabara .bw_icon-type {
    border-color: #ffa91c;
}

.shinjuku .bw_icon-type {
    border-color: #0094d6;
}

.yaesu .bw_icon-type {
    border-color: #5a1818;
}

.ikebukuro .bw_icon-type {
    border-color: #ff8ada;
}

.newuser .bw_icon-type {
    border-color: #7bc8ce;
}

.otona .bw_icon-type {
    border-color: #f36381;
}

.goods .bw_icon-type {
    border-color: #013e8a;
}

.akihabara .bw_icon-type {
    color: #d98700;
}

.shinjuku .bw_icon-type {
    color: #0094d6;
}

.yaesu .bw_icon-type {
    color: #5a1818;
}

.ikebukuro .bw_icon-type {
    color: #ff8ada;
}

.newuser .bw_icon-type {
    color: #7bc8ce;
}

.otona .bw_icon-type {
    color: #f36381;
}

.goods .bw_icon-type {
    color: #013e8a;
}

/* ========================
 * 強調アイコン・ラベル
 * ===================== */
.bw_icon-primary {
    display: inline-block;
    border-width: 2px;
    border-style: solid;
    padding: 3px 8px;
    font-size: 1.3rem;
    font-family: $base-font-family;
    line-height: 1;
    vertical-align: top;
    color: #fff;
}

.akihabara .bw_icon-primary {
    background-color: #ffa91c;
}

.shinjuku .bw_icon-primary {
    background-color: #0094d6;
}

.yaesu .bw_icon-primary {
    background-color: #5a1818;
}

.ikebukuro .bw_icon-primary {
    background-color: #ff8ada;
}

.newuser .bw_icon-primary {
    background-color: #7bc8ce;
}

.otona .bw_icon-primary {
    background-color: #f36381;
}

.goods .bw_icon-primary {
    background-color: #013e8a;
}

/* ========================
 * 商品ステータスアイコン
 * ===================== */
.bw_icon-state {
    display: inline-block;
    padding: 4px 6px;
    font-family: $base-font-family;
    line-height: 1;
    vertical-align: baseline;
    font-size: 1rem;
    color: #fff;
}

.bw_icon-state.bw_icon-state-volume {
    background: #fe4e04;
}

.bw_icon-state.bw_icon-state-mega {
    background: #a00d0d;
}

.bw_icon-state.bw_icon-state-pre {
    background: #004e7f;
}

.bw_icon-state.bw_icon-state-amenity {
    background: #4e4095;
}

.bw_icon-state.bw_icon-state-completion {
    background: #244c07;
}

.bw_icon-state.bw_icon-state-adult {
    background: #f36381;
}

/* ========================
 * 共通ボックス
 * ===================== */
.bw_box-main {
    margin-bottom: 22px;
    background: #fff;
}

.bw_box-main > *:last-child {
    margin-bottom: 0 !important;
}

/* ========================
 * コメントボックス
 * ===================== */
.bw_box-comment {
    position: relative;
}

.bw_box-comment .comment-hdg {
    position: relative;
    display: table;
    box-sizing: border-box;
    border: 1px solid #eff1f3;
    border-bottom: none;
    width: 100%;
    padding: 12px 14px 12px 10px;
    background: #f9f9f9;
}

.bw_box-comment .comment-hdg-txt, .bw_box-comment .comment-date {
    display: table-cell;
    vertical-align: middle;
}

.bw_box-comment .comment-hdg-txt {
    width: 100%;
    font-size: 1.6rem;
    font-weight: bold;
}

.bw_box-comment .comment-hdg-txt:before {
    position: absolute;
    left: 44px;
    bottom: -2px;
    content: '';
    border-style: solid;
    border-color: transparent transparent #acacac;
    border-width: 11px 10px;
}

.bw_box-comment .comment-hdg-txt:after {
    position: absolute;
    left: 47px;
    bottom: -2px;
    content: '';
    border-style: solid;
    border-color: transparent transparent #fff;
    border-width: 8px 7px;
}

.bw_box-comment .comment-hdg-sub {
    margin-left: 3px;
    font-size: 1.3rem;
}

.bw_box-comment .comment-date {
    padding-left: 12px;
    font-size: 1.1rem;
    color: #999;
    white-space: nowrap;
}

.bw_box-comment .comment-content {
    box-sizing: border-box;
    border: 2px solid #acacac;
    padding: 13px 20px 17px;
}

.bw_box-comment .comment-content p {
    margin-bottom: 20px;
    line-height: 1.85;
}

.bw_box-comment .comment-content > *:last-child {
    margin-bottom: 0;
}

.bw_box-comment .comment-txt-lead {
    font-weight: bold;
}

.bw_box-comment .comment-txt {
    height: 185px;
    overflow: hidden;
}

.bw_box-comment .comment-txt > *:last-child {
    margin-bottom: 0;
}

/* ========================
 * 追従バナー
 * ===================== */
.bw_box-fixed-bnr {
    position: fixed;
    bottom: 80px;
    right: 2px;
    box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.35);
    border-radius: 3px;
    overflow: hidden;
    z-index: 150;
}

.bw_box-fixed-bnr .fixed-bnr-hdg {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 151;
}

.bw_box-fixed-bnr .fixed-bnr-content {
    position: relative;
    box-sizing: border-box;
    border-radius: 0 3px 3px 0;
    padding: 5px;
    background: #7bc8ce;
    width: 240px;
}

.bw_box-fixed-bnr .fixed-bnr-lead {
    margin-bottom: 9px;
    padding: 8px 0 0 14px;
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: bold;
    color: #fff;
}

.bw_box-fixed-bnr .fixed-bnr-txt {
    margin: 0;
}

.bw_box-fixed-bnr .fixed-toggle-bnr-close {
    margin: 0;
}

.bw_box-fixed-bnr .fixed-toggle-bnr-close button {
    position: absolute;
    right: 7px;
    top: 8px;
    box-sizing: border-box;
    border: none;
    background: transparent;
    padding: 0;
    font-size: 0;
}

/* ========================
 * サイドコンテンツボックス
 * ===================== */
.bw_sub-box {
    margin-bottom: 22px;
    background: #fff;
}

.bw_sub-box .box-hdg {
    border-bottom: 2px solid #cbced1;
    padding: 9px 12px 8px;
    font-size: 1.3rem;
    line-height: 1.38;
}

.bw_sub-box .box-content > *:last-child {
    margin-bottom: 0 !important;
}

.bw_sub-box .box-item a {
    display: block;
    padding: 11px 16px 10px;
    line-height: 1.2;
}

.bw_sub-box.bw_sub-box-follow .box-content {
    padding: 20px 10px;
}

.bw_sub-box.bw_sub-box-follow .box-txt {
    position: relative;
    margin: 0;
    padding: 7px 11px;
    background: #eef0f2;
    font-size: 1.2rem;
    line-height: 1.83;
}

.bw_sub-box.bw_sub-box-follow .box-txt:before {
    position: absolute;
    left: 50%;
    top: -22px;
    content: '';
    margin-left: -12px;
    border-style: solid;
    border-width: 11px 12px;
    border-color: transparent transparent #eef0f2;
}

.bw_sub-box.bw_sub-box-follow .box-btn {
    text-align: center;
}

.bw_sub-box.bw_sub-box-follow .bw_btn {
    display: inline-block;
}

.bw_sub-box.bw_sub-box-txt-link .box-item:nth-child(2n+1) a {
    background: #f8fafb;
}

.bw_sub-box.bw_sub-box-recommend .box-hdg {
    margin-bottom: 13px;
}

.bw_sub-box.bw_sub-box-recommend .box-content {
    padding-bottom: 13px;
}

.bw_sub-box.bw_sub-box-recommend .box-item {
    display: table;
    box-sizing: border-box;
    padding: 7px 10px;
    width: 100%;
    direction: rtl;
}

.bw_sub-box.bw_sub-box-recommend .box-item a {
    padding: 0;
}

.bw_sub-box.bw_sub-box-recommend .box-txt, .bw_sub-box.bw_sub-box-recommend .box-img {
    display: table-cell;
    vertical-align: top;
    direction: ltr;
}

.bw_sub-box.bw_sub-box-recommend .box-txt {
    padding-left: 8px;
}

.bw_sub-box.bw_sub-box-recommend .box-img {
    position: relative;
    width: 72px;
}

.bw_sub-box.bw_sub-box-recommend .box-img span {
    display: table-cell;
    border-radius: 1px;
    border: 1px solid #c1c1c1;
    width: 70px;
    height: 70px;
    vertical-align: middle;
    text-align: center;
}

.bw_sub-box.bw_sub-box-recommend .box-img span img {
    max-width: 70px;
    max-height: 100%;
}

.bw_sub-box.bw_sub-box-recommend .box-img a {
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
}

.bw_sub-box.bw_sub-box-recommend .box-img a:hover, .bw_sub-box.bw_sub-box-recommend .box-img a:focus {
    opacity: .7;
}

.bw_sub-box.bw_sub-box-recommend .box-category {
    position: absolute;
    right: -5px;
    top: -4px;
    margin: 0;
}

.bw_sub-box.bw_sub-box-recommend .box-title {
    display: inline-block;
    margin-bottom: 2px;
    line-height: 1.23;
    font-weight: bold;
    color: #1d3850;
}

.bw_sub-box.bw_sub-box-recommend .bw_icon-genre {
    margin-left: 2px;
}

.bw_sub-box .bw_txt-publisher {
    width: 128px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.bw_sub-box .bw_txt-price {
    margin-bottom: 1px;
    width: 128px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* ========================
 * flexible slider
 * ===================== */
.fn-flexible-slider,
.fn-bnr-slider {
    position: relative;
}

.fn-flexible-slider .flexible-slider-direction,
.fn-bnr-slider .flexible-slider-direction {
    margin: 0;
}

.fn-flexible-slider .flexible-slider-direction button,
.fn-bnr-slider .flexible-slider-direction button {
    box-sizing: border-box;
    border: none;
    width: 33px;
    height: 108px;
    background: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    -webkit-transition: background .5s ease;
    transition: background .5s ease;
}

.fn-flexible-slider .flexible-slider-direction button .ico-angle-right,
.fn-bnr-slider .flexible-slider-direction button .ico-angle-right {
    font-size: 2.4rem;
    color: #777879;
}

.fn-flexible-slider .flexible-slider-direction button:hover, .fn-flexible-slider .flexible-slider-direction button:focus,
.fn-bnr-slider .flexible-slider-direction button:hover,
.fn-bnr-slider .flexible-slider-direction button:focus {
    background: white;
}

.fn-flexible-slider .flexible-slider-direction-prev, .fn-flexible-slider .flexible-slider-direction-next,
.fn-bnr-slider .flexible-slider-direction-prev,
.fn-bnr-slider .flexible-slider-direction-next {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 5;
}

.fn-flexible-slider .flexible-slider-direction-prev,
.fn-bnr-slider .flexible-slider-direction-prev {
    left: 0;
}

.fn-flexible-slider .flexible-slider-direction-prev button,
.fn-bnr-slider .flexible-slider-direction-prev button {
    border-radius: 0 4px 4px 0;
}

.fn-flexible-slider .flexible-slider-direction-prev button .ico-angle-right,
.fn-bnr-slider .flexible-slider-direction-prev button .ico-angle-right {
    display: inline-block;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.fn-flexible-slider .flexible-slider-direction-next,
.fn-bnr-slider .flexible-slider-direction-next {
    right: 0;
}

.fn-flexible-slider .flexible-slider-direction-next button,
.fn-bnr-slider .flexible-slider-direction-next button {
    border-radius: 4px 0 0 4px;
}

.fn-flexible-slider .flexible-slider-pager,
.fn-bnr-slider .flexible-slider-pager {
    position: absolute;
    left: 50%;
    bottom: 18px;
    box-sizing: border-box;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.fn-flexible-slider .flexible-slider-pager button,
.fn-bnr-slider .flexible-slider-pager button {
    border-radius: 50%;
    border: none;
    padding: 0;
    width: 18px;
    height: 18px;
    background: #bbbaad;
    overflow: hidden;
    text-indent: -500px;
}

.fn-flexible-slider .flexible-slider-pager-item,
.fn-bnr-slider .flexible-slider-pager-item {
    display: inline-block;
    margin-left: 18px;
}

.fn-flexible-slider .flexible-slider-pager-item:first-child,
.fn-bnr-slider .flexible-slider-pager-item:first-child {
    margin-left: 0;
}

.akihabara .fn-flexible-slider .flexible-slider-pager-item.is-current button, .akihabara
.fn-bnr-slider .flexible-slider-pager-item.is-current button {
    background-color: #ffa91c;
}

.shinjuku .fn-flexible-slider .flexible-slider-pager-item.is-current button, .shinjuku
.fn-bnr-slider .flexible-slider-pager-item.is-current button {
    background-color: #0094d6;
}

.yaesu .fn-flexible-slider .flexible-slider-pager-item.is-current button, .yaesu
.fn-bnr-slider .flexible-slider-pager-item.is-current button {
    background-color: #5a1818;
}

.ikebukuro .fn-flexible-slider .flexible-slider-pager-item.is-current button, .ikebukuro
.fn-bnr-slider .flexible-slider-pager-item.is-current button {
    background-color: #ff8ada;
}

.newuser .fn-flexible-slider .flexible-slider-pager-item.is-current button, .newuser
.fn-bnr-slider .flexible-slider-pager-item.is-current button {
    background-color: #7bc8ce;
}

.otona .fn-flexible-slider .flexible-slider-pager-item.is-current button, .otona
.fn-bnr-slider .flexible-slider-pager-item.is-current button {
    background-color: #f36381;
}

.goods .fn-flexible-slider .flexible-slider-pager-item.is-current button, .goods
.fn-bnr-slider .flexible-slider-pager-item.is-current button {
    background-color: #013e8a;
}

.fn-bnr-slider .flexible-slider-direction-prev, .fn-bnr-slider .flexible-slider-direction-next {
    -webkit-transform: translateY(-61%);
    transform: translateY(-61%);
}

.fn-bnr-slider.is-active {
    padding-bottom: 55px;
}

/* ========================
 * ReadMore
 * ===================== */
.read-more-btn {
    position: absolute;
    right: 17px;
    bottom: 16px;
    margin-bottom: 0;
}

.read-more-btn button {
    display: inline-block;
    border: 1px solid #b3cee3;
    padding: 6px;
    background: #f8fafb;
    font-size: 1.4rem;
    line-height: 1;
    color: #1d3850;
}

.read-more-btn button:hover, .read-more-btn button:focus {
    text-decoration: underline;
}

/* ========================
 * FixedBnr
 * ===================== */
.fn-fixed-toggle-bnr {
    right: -198px;
}

/* ========================
 * LiteModal
 * ===================== */
.lite-modal-layer {
    background: rgba(0, 0, 0, 0.5);
}

.lite-modal-pager {
    margin: 0;
}

.lite-modal-pager .lite-modal-pager__item {
    position: absolute;
    top: 50%;
    margin-top: -50px;
}

.lite-modal-pager .lite-modal-pager__item--prev {
    left: -100px;
}

.lite-modal-pager .lite-modal-pager__item--prev .ico-angle-right {
    transform: rotate(180deg);
}

.lite-modal-pager .lite-modal-pager__item--next {
    right: -100px;
}

.lite-modal-pager .lite-modal-pager__item button {
    border: none;
    padding: 0;
    background: transparent;
}

.lite-modal-pager .lite-modal-pager__item .ico-angle-right {
    position: relative;
    display: inline-block;
    color: #fff;
    font-size: 9rem;
}

.lite-modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    margin: 0;
}

.lite-modal-close button {
    border: none;
    padding: 0;
    background: transparent;
}

.lite-modal-target {
    display: none;
}
