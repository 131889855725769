@charset "UTF-8";
/* for HP reader */
#skiplinkSec,
#headAnc,
#naviAnc,
#mainAnc,
#endMainAnc {
	display: none;
}

#pagetop {
	display: block;
	width: 1px;
	height: 1px;
	line-height: 0;
	margin-top: -1px;
	text-indent: -9999em;
	font-size: 0;
}
