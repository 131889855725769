@charset "UTF-8";

.nav-footer-container {
    @extend %clearfix;

    background-color: #FFF;
    margin-bottom: 12px;
    min-width: 1050px;

    .nav-footer-item {
        border-right: 3px solid #eef0f2;
        float: left;
        margin: 10px 0;

        &:last-child {
            border-right: none;
        }

        h2 {
            font-size: 11px;
            color: #2f2f2f;
            background-color: #eef0f2;
            margin: 7px 10px 7px;
            padding: 5px 15px;
            border-radius: 4px;
        }
    }

    .item-column-03 {
        width: 18%;
    }

    .item-column-06 {
        width: 80%;
    }
}

.footer-item-wrap {
    @extend %clearfix;
    margin: 0 15px 7px 15px;

    ul {
        float: left;
        border-right: 2px dashed #eef0f2;
        font-size: 10px;
        color: #1d4a6d;
        min-width: 135px;
        margin: 5px 5px 0 5px;

        &:last-child {
            border-right: none;
        }
    }

    .indent-1 {
        text-indent: 1em;
    }

    .indent-2 {
        text-indent: 2em;

        &:before {
            content: "┗";
        }

        a {
            padding-left: 5px;
        }
    }
}
