@charset "UTF-8";

@import "../../../anne/scss/sp/templates/layout/font-variable";

.sectionIn {
	position: relative;
}
.sectionWrap {
	display: block;
	margin-left: 0;
	overflow: hidden;
	position: relative;
	top: 0;
	width: 100%;
	z-index: 9;
}
.purchaseListOuter {
	left: -1600px;
	position: relative;
	width: 100000px;
}
.purchaseListInner {
	position: relative;
}
.purchaseListInner .itemWrap {
	display: block;
	float: left;
	margin: 0 9px 0 0;
	position: relative;
	width: 105px;
	height: 145px;
}
.purchaseListInner .itemWrap .item {
	display: block;
	margin: 0 auto;
	position: absolute;
	text-align: center;
	z-index: 10;
	background: #ffffff;
	width: 105px;
	height: 145px;
}
.itemWrap .item .image a img {
	height: 121px;
	border: 1px solid #b7b7b7;
	margin: 12px 0 0 0;
}
.btn-bookItem-left-now {
	position: absolute;
	left: 0;
	width: 26px;
	z-index: 11;
}
.btn-bookItem-right-now {
	position: absolute;
	width: 26px;
	right: 0;
	z-index: 11;
}
.btn-bookItem-left-now a,
.btn-bookItem-right-now a {
	text-indent: -9999px;
	overflow: hidden;
	display: block;
	width: 25px;
	height: 145px;
	position: absolute;
	top: 40%;
	z-index: 11;
}
.btn-bookItem-left-now a {
	background: url(/louis/pc/img/common/btn-bookitem-left.png) no-repeat -1px 47%;
	border-left: 4px solid #d4d4d4;
}
.btn-bookItem-right-now a {
	background: url(/louis/pc/img/common/btn-bookitem-right.png) no-repeat 1px 47%;
	border-right: 4px solid #d4d4d4;
}
.btn-bookItem-left-now a:hover {
	background: url(/louis/pc/img/common/btn-bookitem-left.png) no-repeat -27px 47%;
	border-left: 4px solid #404040;
}
.btn-bookItem-right-now a:hover {
	background: url(/louis/pc/img/common/btn-bookitem-right.png) no-repeat -25px 47%;
	border-right: 4px solid #404040;
}
#purchaseBalloon {
	background: none repeat scroll 0 0 #efefeb;
	border: 1px solid #efefeb;
	/*display: none;*/
	padding: 10px;
	position: absolute;
	z-index: 10;
	top: -20px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	filter: alpha(opacity=80);
	-moz-opacity: 0.8;
	opacity: 0.8;
}
.balloonDate {
	font-size: 85%;
	margin: 0 0 3px 0;
}
.balloonTitle {
	font-weight: bold;
}



.hide {
	display: none;
}

#firstComeWrapLogined .sideRecBox .nextrank{border:solid 2px #e2e3e7; margin: 0 0 5px 0; padding:5px 10px 4px 10px; text-align:center;}
#firstComeWrapLogined .sideRecBox .nextrank img{margin:0;}
#firstComeWrapLogined .sideRecBox .nextpay{color: #e40007; font-size:110%; font-weight:bold;line-height:120%; margin: 0 2px;}
#firstComeWrapLogined .sideRecBox .rank-txt{font-weight:bold;}


#firstComeWrapLogined .member-rank .icon {
	background-position: 0 50%;
	background-repeat: no-repeat;
	background-size: 19px 19px;
	display: inline-block;
	font-size: 96%;
	font-family: $base-font-family;
	font-weight: bold;
	line-height: 19px;
	letter-spacing: 1px;
	padding: 5px 2px 2px 22px;
	margin-top: -3px;
}
#firstComeWrapLogined .member-rank .icon-rank-light {
	background-image: url(/louis/pc/img/member-rank/light-2.png);
}
#firstComeWrapLogined .member-rank .icon-rank-regular {
	background-image: url(/louis/pc/img/member-rank/regular-2.png);
}
#firstComeWrapLogined .member-rank .icon-rank-jack {
	background-image: url(/louis/pc/img/member-rank/jack-2.png);
}
#firstComeWrapLogined .member-rank .icon-rank-queen {
	background-image: url(/louis/pc/img/member-rank/queen-2.png);
}
#firstComeWrapLogined .member-rank .icon-rank-king {
	background-image: url(/louis/pc/img/member-rank/king-2.png);
}
#firstComeWrapLogined .member-rank .icon-rank-joker {
	background-image: url(/louis/pc/img/member-rank/joker-2.png);
}
#firstComeWrapLogined .member-rank .icon-rank-ace {
	background-image: url(/louis/pc/img/member-rank/ace-2.png);
}
