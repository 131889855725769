@import "colors";
@import "z-index";

// 操作完了時のポップアップ
// 予約完了、著者・レーベルフォロー、この本は持っています登録

@mixin done-popup-base {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: $popup-z-index;
    width: 80%;
    max-width: 480px;
    padding: 12px;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem;
    text-align: center;
    color: $base-text-color;
    border-radius: 4px;
    border: 1px solid $base-color;
    background: #fff;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, .5);
    transform: translateX(-50%) translateY(-50%);
    .ico {
        margin-bottom: 4px;
    }
    .a-done-popup-link {
        text-decoration: underline;
    }
}

.a-done-popup {
    display: none;
}

.a-done-popup--on {
    @include done-popup-base;

    // 予約時
    .ico-alerm {
        color: $strong-blue;
    }

    // 著者・レーベルフォロー
    .ico-follow {
        color: $active-text-color;
    }

    // この本は持っています
    .ico-allread {
        color: $active-text-color;
    }
}
