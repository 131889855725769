@charset "UTF-8";

//標準のfont-familyはanneで定義されているので読み込み
@import "../../../anne/scss/sp/templates/layout/font-variable";
$base-font-size: 1.3rem;
$base-text-color: #2f2f2f;
$base-margin: 22px;
$base-link-color: #1d4a6d;
$base-color: #1d3850;

// カラー配列
$theme-colors: (
	"turquoise": #22b8c6,
	"green": #54af57,
	"orange": #ffa91c,
	"red": #ff4f82,
	"blue": #3385f5,
	"gray": #868686,
);

// ショップ別カラー
$store-colors: (
	"akihabara": #ffa91c,
	"shinjuku": #0094d6,
	"yaesu": #5a1818,
	"ikebukuro": #ff8ada,
	"newuser": #7bc8ce,
	"otona": #f36381,
	"goods": #013e8a
);

$anne-img-path: "/anne/img/common/";

$hover-menu-z-index: 10000;
