@charset "UTF-8";

/***************************************
*
*	1. float reset
*	2. font property
*	3. float set
*	4. text align
*	5. space
*
***************************************/

/* 1. float reset */
/* ------------------------------------------------------------------------------------ */

.clearfix {
	zoom: 1;/* for IE 5.5-7 */
}

.clearfix:after {
	content: " ";
	display: block;
	visibility: hidden;
	height: 0.1px;
	clear: both;
	font-size: 0.1;
	line-height: 0;
}

* html .clearfix {
	display: inline-block;
}

/*¥*/
* html .clearfix { height: 1%;}
.clearfix { display: block;}
/**/

.clr {
	clear: both;
	overflow: hidden;
	width: 1px;
	height: 1px;
	margin: 0 -1px -1px 0;
	border: 0;
	padding: 0;
	font-size: 0;
	line-height: 0;
}

/* 2. font property */
/* ------------------------------------------------------------------------------------ */

.bold { font-weight: bold;}

.f10 { font-size: 77% !important;}/* renders 10px */
.f11 { font-size: 85% !important;}/* renders 11px */
.f12 { font-size: 93% !important;}/* renders 12px */
.f13 { font-size: 100% !important;}/* renders 13px */
.f14 { font-size: 108% !important;}/* renders 14px */
.f15 { font-size: 116% !important;}/* renders 15px */
.f16 { font-size: 123.1% !important;}/* renders 16px */
.f17 { font-size: 131% !important;}/* renders 17px */
.f18 { font-size: 138.5% !important;}/* renders 18px */
.f19 { font-size: 146.5% !important;}/* renders 19px */
.f20 { font-size: 153.9% !important;}/* renders 20px */
.f21 { font-size: 161.6% !important;}/* renders 21px */
.f22 { font-size: 167% !important;}/* renders 22px */
.f23 { font-size: 174% !important;}/* renders 23px */
.f24 { font-size: 182% !important;}/* renders 24px */
.f25 { font-size: 189% !important;}/* renders 25px */
.f26 { font-size: 197% !important;}/* renders 26px */

/* 3. float set */
/* ------------------------------------------------------------------------------------ */

.left {
	display: inline;
	float: left;
}

.right {
	display: inline;
	float: right;
}

/* 4. text align */
/* ------------------------------------------------------------------------------------ */

.t-left { text-align: left;}
.t-center { text-align: center;}
.t-right { text-align: right;}

/* 5. space */
/* ------------------------------------------------------------------------------------ */

/* margin-top */
.m-t00 { margin-top: 0 !important;}
.m-t01 { margin-top: 1px !important;}
.m-t02 { margin-top: 2px !important;}
.m-t03 { margin-top: 3px !important;}
.m-t04 { margin-top: 4px !important;}
.m-t05 { margin-top: 5px !important;}
.m-t06 { margin-top: 6px !important;}
.m-t07 { margin-top: 7px !important;}
.m-t08 { margin-top: 8px !important;}
.m-t09 { margin-top: 9px !important;}
.m-t10 { margin-top: 10px !important;}
.m-t11 { margin-top: 11px !important;}
.m-t12 { margin-top: 12px !important;}
.m-t13 { margin-top: 13px !important;}
.m-t14 { margin-top: 14px !important;}
.m-t15 { margin-top: 15px !important;}
.m-t16 { margin-top: 16px !important;}
.m-t17 { margin-top: 17px !important;}
.m-t18 { margin-top: 18px !important;}
.m-t19 { margin-top: 19px !important;}
.m-t20 { margin-top: 20px !important;}
.m-t24 { margin-top: 24px !important;}
.m-t25 { margin-top: 25px !important;}
.m-t28 { margin-top: 28px !important;}
.m-t30 { margin-top: 30px !important;}
.m-t32 { margin-top: 32px !important;}
.m-t35 { margin-top: 35px !important;}
.m-t36 { margin-top: 36px !important;}
.m-t40 { margin-top: 40px !important;}
.m-t44 { margin-top: 44px !important;}
.m-t45 { margin-top: 45px !important;}
.m-t48 { margin-top: 48px !important;}
.m-t50 { margin-top: 50px !important;}
.m-t52 { margin-top: 52px !important;}
.m-t55 { margin-top: 55px !important;}
.m-t56 { margin-top: 56px !important;}
.m-t60 { margin-top: 60px !important;}
.m-t64 { margin-top: 64px !important;}
.m-t65 { margin-top: 65px !important;}
.m-t68 { margin-top: 68px !important;}
.m-t70 { margin-top: 70px !important;}
.m-t72 { margin-top: 72px !important;}
.m-t75 { margin-top: 75px !important;}
.m-t76 { margin-top: 76px !important;}
.m-t80 { margin-top: 80px !important;}
.m-t84 { margin-top: 84px !important;}
.m-t85 { margin-top: 85px !important;}
.m-t88 { margin-top: 88px !important;}
.m-t90 { margin-top: 90px !important;}



/* margin-bottom */
.m-b00 { margin-bottom: 0 !important;}
.m-b01 { margin-bottom: 1px !important;}
.m-b02 { margin-bottom: 2px !important;}
.m-b03 { margin-bottom: 3px !important;}
.m-b04 { margin-bottom: 4px !important;}
.m-b05 { margin-bottom: 5px !important;}
.m-b06 { margin-bottom: 6px !important;}
.m-b07 { margin-bottom: 7px !important;}
.m-b08 { margin-bottom: 8px !important;}
.m-b09 { margin-bottom: 9px !important;}
.m-b10 { margin-bottom: 10px !important;}
.m-b11 { margin-bottom: 11px !important;}
.m-b12 { margin-bottom: 12px !important;}
.m-b13 { margin-bottom: 13px !important;}
.m-b14 { margin-bottom: 14px !important;}
.m-b15 { margin-bottom: 15px !important;}
.m-b16 { margin-bottom: 16px !important;}
.m-b17 { margin-bottom: 17px !important;}
.m-b18 { margin-bottom: 18px !important;}
.m-b19 { margin-bottom: 19px !important;}
.m-b20 { margin-bottom: 20px !important;}
.m-b24 { margin-bottom: 24px !important;}
.m-b25 { margin-bottom: 25px !important;}
.m-b28 { margin-bottom: 28px !important;}
.m-b30 { margin-bottom: 30px !important;}
.m-b32 { margin-bottom: 32px !important;}
.m-b35 { margin-bottom: 35px !important;}
.m-b36 { margin-bottom: 36px !important;}
.m-b40 { margin-bottom: 40px !important;}
.m-b44 { margin-bottom: 44px !important;}
.m-b45 { margin-bottom: 45px !important;}
.m-b48 { margin-bottom: 48px !important;}
.m-b50 { margin-bottom: 50px !important;}
.m-b52 { margin-bottom: 52px !important;}
.m-b55 { margin-bottom: 55px !important;}
.m-b56 { margin-bottom: 56px !important;}
.m-b60 { margin-bottom: 60px !important;}
.m-b64 { margin-bottom: 64px !important;}
.m-b65 { margin-bottom: 65px !important;}
.m-b68 { margin-bottom: 68px !important;}
.m-b70 { margin-bottom: 70px !important;}
.m-b72 { margin-bottom: 72px !important;}
.m-b75 { margin-bottom: 75px !important;}
.m-b76 { margin-bottom: 76px !important;}
.m-b80 { margin-bottom: 80px !important;}
.m-b84 { margin-bottom: 84px !important;}
.m-b85 { margin-bottom: 85px !important;}
.m-b88 { margin-bottom: 88px !important;}
.m-b90 { margin-bottom: 90px !important;}



/* padding-top */
.p-t00 { padding-top: 0 !important;}
.p-t01 { padding-top: 1px !important;}
.p-t02 { padding-top: 2px !important;}
.p-t03 { padding-top: 3px !important;}
.p-t04 { padding-top: 4px !important;}
.p-t05 { padding-top: 5px !important;}
.p-t06 { padding-top: 6px !important;}
.p-t07 { padding-top: 7px !important;}
.p-t08 { padding-top: 8px !important;}
.p-t09 { padding-top: 9px !important;}
.p-t10 { padding-top: 10px !important;}
.p-t11 { padding-top: 11px !important;}
.p-t12 { padding-top: 12px !important;}
.p-t13 { padding-top: 13px !important;}
.p-t14 { padding-top: 14px !important;}
.p-t15 { padding-top: 15px !important;}
.p-t16 { padding-top: 16px !important;}
.p-t17 { padding-top: 17px !important;}
.p-t18 { padding-top: 18px !important;}
.p-t19 { padding-top: 19px !important;}
.p-t20 { padding-top: 20px !important;}
.p-t24 { padding-top: 24px !important;}
.p-t25 { padding-top: 25px !important;}
.p-t28 { padding-top: 28px !important;}
.p-t30 { padding-top: 30px !important;}
.p-t32 { padding-top: 32px !important;}
.p-t35 { padding-top: 35px !important;}
.p-t36 { padding-top: 36px !important;}
.p-t40 { padding-top: 40px !important;}
.p-t44 { padding-top: 44px !important;}
.p-t45 { padding-top: 45px !important;}
.p-t48 { padding-top: 48px !important;}
.p-t50 { padding-top: 50px !important;}
.p-t52 { padding-top: 52px !important;}
.p-t55 { padding-top: 55px !important;}
.p-t56 { padding-top: 56px !important;}
.p-t60 { padding-top: 60px !important;}
.p-t64 { padding-top: 64px !important;}
.p-t65 { padding-top: 65px !important;}
.p-t68 { padding-top: 68px !important;}
.p-t70 { padding-top: 70px !important;}
.p-t72 { padding-top: 72px !important;}
.p-t75 { padding-top: 75px !important;}
.p-t76 { padding-top: 76px !important;}
.p-t80 { padding-top: 80px !important;}
.p-t84 { padding-top: 84px !important;}
.p-t85 { padding-top: 85px !important;}
.p-t88 { padding-top: 88px !important;}
.p-t90 { padding-top: 90px !important;}



/* padding-bottom */
.p-b00 { padding-bottom: 0 !important;}
.p-b01 { padding-bottom: 1px !important;}
.p-b02 { padding-bottom: 2px !important;}
.p-b03 { padding-bottom: 3px !important;}
.p-b04 { padding-bottom: 4px !important;}
.p-b05 { padding-bottom: 5px !important;}
.p-b06 { padding-bottom: 6px !important;}
.p-b07 { padding-bottom: 7px !important;}
.p-b08 { padding-bottom: 8px !important;}
.p-b09 { padding-bottom: 9px !important;}
.p-b10 { padding-bottom: 10px !important;}
.p-b11 { padding-bottom: 11px !important;}
.p-b12 { padding-bottom: 12px !important;}
.p-b13 { padding-bottom: 13px !important;}
.p-b14 { padding-bottom: 14px !important;}
.p-b15 { padding-bottom: 15px !important;}
.p-b16 { padding-bottom: 16px !important;}
.p-b17 { padding-bottom: 17px !important;}
.p-b18 { padding-bottom: 18px !important;}
.p-b19 { padding-bottom: 19px !important;}
.p-b20 { padding-bottom: 20px !important;}
.p-b24 { padding-bottom: 24px !important;}
.p-b25 { padding-bottom: 25px !important;}
.p-b28 { padding-bottom: 28px !important;}
.p-b30 { padding-bottom: 30px !important;}
.p-b32 { padding-bottom: 32px !important;}
.p-b35 { padding-bottom: 35px !important;}
.p-b36 { padding-bottom: 36px !important;}
.p-b40 { padding-bottom: 40px !important;}
.p-b44 { padding-bottom: 44px !important;}
.p-b45 { padding-bottom: 45px !important;}
.p-b48 { padding-bottom: 48px !important;}
.p-b50 { padding-bottom: 50px !important;}
.p-b52 { padding-bottom: 52px !important;}
.p-b55 { padding-bottom: 55px !important;}
.p-b56 { padding-bottom: 56px !important;}
.p-b60 { padding-bottom: 60px !important;}
.p-b64 { padding-bottom: 64px !important;}
.p-b65 { padding-bottom: 65px !important;}
.p-b68 { padding-bottom: 68px !important;}
.p-b70 { padding-bottom: 70px !important;}
.p-b72 { padding-bottom: 72px !important;}
.p-b75 { padding-bottom: 75px !important;}
.p-b76 { padding-bottom: 76px !important;}
.p-b80 { padding-bottom: 80px !important;}
.p-b84 { padding-bottom: 84px !important;}
.p-b85 { padding-bottom: 85px !important;}
.p-b88 { padding-bottom: 88px !important;}
.p-b90 { padding-bottom: 90px !important;}
