@charset "UTF-8";

/***************************************
*
* 1. common style
* 2. headSec style
* 3. gNaviSec style
* 4. layout-two-column-left
* 5. layout-three-column
*
***************************************/
// fontの変数用
@import "../../../anne/scss/sp/templates/layout/font-variable";
// bobのfooter読み込み
@import "../../../bob/scss/pc/footer-pc";
@import "../../../anne/scss/sp/atoms/colors";

/* 1. common style */
/* ------------------------------------------------------------------------------------ */

body {
    background-color: #eef0f2;
    text-align: center;
    margin: 0 auto;
    color: #2f2f2f;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

#whole {
    text-align: left;
}

a:link,
a:visited,
a:active,
a:hover {
    color: $base-link-color;
}

a:hover {
    color: $base-link-color;
    text-decoration: none;
}

/*  window resize  */


/* 2. headSec style */
/* ------------------------------------------------------------------------------------ */

#headSec {
    padding: 24px 0 0 0;
    height: 72px;
    background: #fff;
    position: relative;
    z-index: 1100;
    text-align: left;
    min-width: 1050px;
}

#headWrap {
    min-width: 1020px;
    padding: 0;
}

#headleftSec {
    width: 442px !important;
    overflow: hidden;
    position: absolute;
    top: 22px;
    left: 330px;
    z-index: 2500;
}

#headleft {
    float: left;
    width: 500px;
}

#headSec .hgroup {
    margin: 8px 0 0 0;
    width: 500px;
    float: left;
}

#headLogo {
    margin: 0 15px;
    float: left;
    display: inline;
}

#headLogo a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    width: 311px;
    height: 31px;
    background: url(/louis/pc/img/common/hd-logo.png) no-repeat 0 0;
}

#haedText {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    height: 24px;
    overflow: hidden;
    width: 100%;
    padding: 5px 0 0 0;
    font-size: 12px;
    background-color: #eef0f2;
    color: #333;
    padding-left: 15px;
}

#hkeyword {
    margin: 9px 0 0 15px;
    background: #efefeb;
    display: inline-block;
    padding: 0 15px 0 0;
    height: 19px;
    overflow: hidden;
}

#hkeyword dl dt {
    float: left;
    width: 97px;
    height: 19px;
    text-indent: -9999px;
    overflow: hidden;
    background: url(/louis/pc/img/common/hkeywords-head.png) no-repeat 0 0;
}

#hkeyword dl dd {
    float: left;
    margin: 3px 0 0 10px;
    font-size: 77%;
    white-space: nowrap;
}

#placeNavi {
    float: left;
    min-width: 102px;
    margin: 11px 0 0 15px;
}

#placeNavi.s960 {
    margin: 11px 0 0 15px;
}

#placeNavi li {
    float: left;
    margin: 0 5px 0 -1px;
}

#placeNavi li a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    width: 64px;
    height: 30px;
    background-image: url(/louis/pc/img/common/place-navi03.png) !important;
    background-repeat: no-repeat;
}

#placeNavi li#place01 a {
    background-position: 0 -2px;
}

#placeNavi li#place02 a {
    background-position: 0 -98px;
}

#placeNavi li#place03 a {
    background-position: 0 -194px;
}

#placeNavi li#place04 a {
    background-position: 0 -290px;
}

#placeNavi li#place05 a {
    background-position: 0 -386px;
}

#placeNavi li#place06 a {
    background-position: 0 -482px;
}

#placeNavi li#place01 a:hover {
    background-position: 0 -64px;
}

#placeNavi li#place02 a:hover {
    background-position: 0 -160px;
}

#placeNavi li#place03 a:hover {
    background-position: 0 -256px;
}

#placeNavi li#place04 a:hover {
    background-position: 0 -352px;
}

#placeNavi li#place05 a:hover {
    background-position: 0 -448px;
}

#placeNavi li#place06 a:hover {
    background-position: 0 -544px;
}

#headright {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px 15px 0 0;
    width: 355px;
    z-index: 2000;
}

#hnav {
    margin: 0 15px 35px 0;
    height: 12px;
}

#hnav ul {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 500px;
}

#hnav ul li {
    float: right;
    font-size: 77%;
}

#hnav ul li.listborder {
    background: url(/louis/pc/img/common/hd-line.png) no-repeat left 0;
    padding: 0 0 0 10px;
}

#hnav ul li a {
    text-decoration: none;
}

#hnav ul li a:hover {
    text-decoration: underline;
}

#hnav ul li:nth-child(3) {
    min-width: 151px;
}

#hnav ul li.last {
    background: none;
    padding: 0 6px 0 0;
}

#firstVisit {
    float: right;
    margin: 5px 0 0 0;
}

#firstVisit a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background: url(/louis/pc/img/common/gnav-beginners.png) no-repeat 0 0;
    width: 123px;
    height: 35px;
}

#firstVisit a:hover {
    background-position: 0 bottom;
}

#memberRegist {
    float: right;
    margin: 5px 0 0 0;
}

#memberRegist a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background: url(/louis/pc/img/common/gnav-memberregist-2.png) no-repeat 0 0;
    width: 111px;
    height: 35px;
}

#memberRegist a:hover {
    background-position: 0 bottom;
}


#safe-search-icon {
    position: absolute;
    right: 330px;
    top: 69px;
}

#safe-search-icon a {
    text-indent: -9999px;
    height: 13px;
    width: 90px;
    overflow: hidden;
    display: block;

    background: url(/louis/pc/img/common/btn-logoin-after4.png);
    background-repeat: no-repeat;
    background-position: 0 -16px;
}

#safe-search-icon a:hover,
#safe-search-icon a.active {
    background-position: 0 0;
}

#safe-search-disp {
    top: 89px;
    right: 256px;
    z-index: 9999;
    position: absolute;
    background-color: #efefeb;
    border: 1px solid #efefeb;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -moz-box-shadow: 0px 0px 5px #999;
    box-shadow: 0px 0px 5px #999;
    -webkit-box-shadow: 0px 0px 5px #999;
    -ms-box-shadow: 0px 0px 5px #999;
    display: none;
    margin-right: 17px;
}

#safe-box {
    text-align: center;
    padding: 3px;
}

#safe-search-disp-area {
    display: block;
    overflow: hidden;
    position: absolute;
    height: 40px;
    width: 100px;
    top: -11px;
    left: -9px;
    z-index: -9999;
}

.safe-search-change {
    height: 14px;
    width: 160px;
    padding: 8px 0px 8px;
    margin: 1px;
    display: block;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    font-weight: 900;
    font-size: 90%;
    cursor: pointer;
    clear: both;
    border: 1px solid #efefeb;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

.safe-search-change-active {
    color: #fff;
    background: #a4a397;
}

.safe-search-change-nonactive {
    color: #1d3850;
    background: #efefeb;
}

.safe-search-change:hover {
    text-decoration: underline;
}

.notice {
    visibility: hidden;
    position: absolute;
    z-index: 99999;
    opacity: 1;

    margin: 0 auto;
    padding: 15px;
    margin-bottom: 20px;

    font-weight: bold;
    width: 94%;
    height: 20px;

    top: 9%;

    color: #E40007;
    background-color: rgba(245, 245, 245, 0.9);
    border-color: #E40007;
    border-top: 1px solid;
    border-bottom: 1px solid;

    animation: fadeout 1s linear 2s 1;
    animation-fill-mode: forwards;
    -webkit-animation: fadeout 1s linear 2s 1;
    -webkit-animation-fill-mode: forwards;
}

@keyframes fadeout {
    0% {
        opacity: 1;
        height: 20px;
        margin: 0 auto;
        padding: 15px;
        margin-bottom: 20px;
        width: 94%;
    }
    70% {
        opacity: 0.2;
        height: 20px;
        margin: 0 auto;
        padding: 15px;
        margin-bottom: 20px;
        width: 94%;
    }
    99% {
        opacity: 0;
        height: 20px;
        margin: 0 auto;
        padding: 15px;
        margin-bottom: 20px;
        width: 94%;
    }
    100% {
        opacity: 0;
        height: 20px;
        margin: 0 auto;
        padding: 15px;
        margin-bottom: 20px;
        z-index: -100;
        width: 94%;
    }
}

@-webkit-keyframes fadeout {
    0% {
        opacity: 1;
        height: 20px;
        margin: 0 auto;
        padding: 15px;
        margin-bottom: 20px;
        width: 94%;
    }
    70% {
        opacity: 0.2;
        height: 20px;
        margin: 0 auto;
        padding: 15px;
        margin-bottom: 20px;
        width: 94%;
    }
    99% {
        opacity: 0;
        height: 20px;
        margin: 0 auto;
        padding: 15px;
        margin-bottom: 20px;
        width: 94%;
    }
    100% {
        opacity: 0;
        height: 20px;
        margin: 0 auto;
        padding: 15px;
        margin-bottom: 20px;
        z-index: -100;
        width: 94%;
    }
}

#safe-search-triangle {
    /*	background: #fff;*/
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-bottom: 10px solid #efefeb;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 80px;
    left: -32px;
    z-index: 10000;
    display: none;
}

#loginbox-menu {
    float: right;
    margin: 22px 0 0 0;
}

#loginbox-menu ul {
    float: right;
}

#loginbox-menu ul li {
    float: left;
    display: inline;
    margin: 0 11px 0 0;
}

#loginbox-menu ul li.last {
    margin: 0 0 0 0;
}

#loginbox-menu ul li a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    height: 13px;
    background: url(/louis/pc/img/common/btn-header-icons-1111.png);
    background-repeat: no-repeat;
}

#loginbox-menu ul li#loginmenu-check a {
    background-position: -100px -16px;
    width: 60px;
}

#loginbox-menu ul li#loginmenu-cart a {
    background-position: -165px -16px;
    width: 60px;
}

#loginbox-message ul li#loginmenu-cart a {
    background-position: -256px -16px;
    width: 60px;
}

#loginbox-menu ul li#loginmenu-message a {
    background-position: -230px -16px;
    width: 85px;
}

#loginbox-menu ul li#loginmenu-message a:hover,
#loginbox-menu ul li#loginmenu-message a.active {
    background-position: -230px 0;
}

#loginbox-menu ul li#loginmenu-mypage a {
    background-position: -323px -16px;
    width: 75px;
}

#loginbox-menu ul li#loginmenu-check a:hover,
#loginbox-menu ul li#loginmenu-check a.active {
    background-position: -100px 0;
}

#loginbox-menu ul li#loginmenu-cart a:hover,
#loginbox-menu ul li#loginmenu-cart a.active {
    background-position: -165px 0;
}

#loginbox-menu ul li#loginmenu-mypage a:hover,
#loginbox-menu ul li#loginmenu-mypage a.active {
    background-position: -323px 0;
}

#follow-menu-icon {
    position: absolute;
    right: 335px;
    top: 45px;
}

#follow-menu-icon a {
    text-indent: -9999px;
    height: 13px;
    width: 80px;
    overflow: hidden;
    display: block;

    background: url(/louis/pc/img/common/btn-header-icons-1111.png);
    background-repeat: no-repeat;
    background-position: 0 -16px;
}

#follow-menu-icon a:hover,
#follow-menu-icon a.active {
    background-position: 0 0;
}


.numCircle {
    color: #fff;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    padding: 1px 5px;
    font-size: 77%;
    margin: 1px 0 0 3px;
    background: #e9137a;
    border: 1px solid #e9137a;
}


.mypage-box {
    background: #efefeb;
    padding: 10px;
    border: 1px solid #efefeb;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -moz-box-shadow: 0px 0px 5px #999;
    box-shadow: 0px 0px 5px #999;
    -webkit-box-shadow: 0px 0px 5px #999;
    -ms-box-shadow: 0px 0px 5px #999;
}

#message-box {
    position: absolute;
    z-index: 2000;
    top: 93px;
    left: 0px;
    font-size: 93%;
}

#message-box .redtext a,
#messageNum a {
    color: #e12a2e;
}

#message-box .redtext {
    margin: 0 0 5px 0;
}

.triangle-mypage:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-bottom: 10px solid #efefeb;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 84px;
    left: 230px;
    z-index: 2100;
}

#loginMenu {
    position: absolute;
    top: 70px;
    left: 190px;
    background: #efefeb;
    padding: 10px;
    border: 1px solid #efefeb;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -moz-box-shadow: 0px 0px 5px #999;
    box-shadow: 0px 0px 5px #999;
    -webkit-box-shadow: 0px 0px 5px #999;
    -ms-box-shadow: 0px 0px 5px #999;
}

#loginbox-menu ul#loginMenuUl li {
    float: none;
    display: block;
    margin: 27px 0 8px 0;
}

#loginbox-menu ul#loginMenuUl li.last {
    margin: 0 0 0 0;
}

#loginbox-menu ul#loginMenuUl li a {
    text-indent: 0;
    background: url(/louis/pc/img/common/icon-loginafter.png) no-repeat 0 0;
    height: 15px;
    padding: 0 0 0 21px;
    font-size: 85%;
    text-decoration: none;
    display: inline;
}

#loginbox-menu ul#loginMenuUl li a:hover {
    text-decoration: underline;
}

#loginbox-menu ul#loginMenuUl li#loginMenu01 a {
    background-position: 0 0;
}

#loginbox-menu ul#loginMenuUl li#loginMenu02 a {
    background-position: 0 -24px;
}

#loginbox-menu ul#loginMenuUl li#loginMenu02 {
    position: relative;
}

#loginbox-menu ul#loginMenuUl li#loginMenu02 span {
    position: absolute;
    top: -3px;
    right: 31px;
}

#loginbox-menu ul#loginMenuUl li#loginMenu03 a {
    background-position: 0 -49px;
}

#loginbox-menu ul#loginMenuUl li#loginMenu04 a {
    background-position: 0 -71px;
}

#loginbox-menu ul#loginMenuUl li#loginMenu05 a {
    background-position: 0 -94px;
}

#loginbox-menu ul#loginMenuUl li#loginMenu06 a {
    background-position: 0 -117px;
}

/* 3. gNaviSec style */
/* ------------------------------------------------------------------------------------ */
#gNaviSec {
    min-width: 1050px;
}

#yaesu #gNaviSec {
    background: #5a1818;
    width: 100%;
    position: relative;
    z-index: 1000;
}

#akihabara #gNaviSec {
    background: #ffa91c;
    width: 100%;
    position: relative;
    z-index: 1000;
}

#shinjuku #gNaviSec {
    background: #0094d6;
    width: 100%;
    position: relative;
    z-index: 1000;
}

#ikebukuro #gNaviSec {
    background: #ff8ada;
    background: -moz-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff8ada), color-stop(100%, #ffaed8));
    background: -webkit-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: -o-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: -ms-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: linear-gradient(to bottom, #ff8ada 0%, #ffaed8 100%);
    width: 100%;
    position: relative;
    z-index: 1000;
}

#newuser #gNaviSec {
    background: #7bc8ce;
    width: 100%;
    position: relative;
    z-index: 1000;
}

#yaesu #gNaviWrap {
    min-width: 960px;
    background: #5a1818;
    margin: 0 15px;
}

#akihabara #gNaviWrap {
    min-width: 960px;
    background: #ffa91c;
    margin: 0 15px;
}

#shinjuku #gNaviWrap {
    min-width: 960px;
    background: #0094d6;
    margin: 0 15px;
}

#ikebukuro #gNaviWrap {
    min-width: 960px;
    background: #ff8ada;
    background: -moz-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff8ada), color-stop(100%, #ffaed8));
    background: -webkit-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: -o-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: -ms-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: linear-gradient(to bottom, #ff8ada 0%, #ffaed8 100%);
    margin: 0 15px;
}

#newuser #gNaviWrap {
    min-width: 960px;
    background: #7bc8ce;
    margin: 0 15px;
}

#gNaviUl {
    float: left;
}

#gNaviUl li {
    float: left;
}

#gNaviUl li a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    height: 46px;
    background-repeat: no-repeat;
    background-position: 0 0;
}

#gNaviUl li a:hover {
    background-position: 0 bottom;
}

#gNaviUl li#gnav-cat a {
    background-image: url(/louis/pc/img/common/gnav-cat.png);
    width: 127px;
}

#gNaviUl li#gnav-willdeliv a {
    background-image: url(/louis/pc/img/common/gnav-willdeliv.png);
    width: 77px;
}

#gNaviUl li#gnav-newbook a {
    background-image: url(/louis/pc/img/common/gnav-newbook.png);
    width: 63px;
}

#gNaviUl li#gnav-ranking a {
    background-image: url(/louis/pc/img/common/gnav-ranking.png);
    width: 84px;
}

#gNaviUl li#gnav-literature a {
    background-image: url(/louis/pc/img/common/gnav-literature.png);
    width: 57px;
}

#gNaviUl li#gnav-practical a {
    background-image: url(/louis/pc/img/common/gnav-practical.png);
    width: 109px;
}

#gNaviUl li#gnav-magazine a {
    background-image: url(/louis/pc/img/common/yaesu_zasshi.png);
    width: 63px;
}

#gNaviUl li#gnav-comic a {
    background-image: url(/louis/pc/img/common/gnav-comic.png);
    width: 84px;
}

#gNaviUl li#gnav-comic2 a {
    background-image: url(/louis/pc/img/common/gnav-comic3.png);
    width: 79px;
}

#gNaviUl li#gnav-game a {
    background-image: url(/louis/pc/img/common/gnav-game.png);
    width: 75px;
}

#gNaviUl li#gnav-indies a {
    background-image: url("/louis/pc/img/common/gnav-indies.png");
    width: 111px;
}

#gNaviUl li#gnav-girls a {
    background-image: url(/louis/pc/img/common/gnav-girls.png);
    width: 79px;
}

#gNaviUl li#gnav-lightnovel a {
    background-image: url(/louis/pc/img/common/gnav-lightnovel.png);
    width: 79px;
}

#gNaviUl li#gnav-literature2 a {
    background-image: url(/louis/pc/img/common/gnav-literature2.png);
    width: 75px;
}

#gNaviUl li#gnav-practical2 a {
    background-image: url(/louis/pc/img/common/gnav-practical2.png);
    width: 79px;
}

#gNaviUl li#gnav-adult a {
    background-image: url(/louis/pc/img/common/gnav-adult-akihabara.png);
    width: 79px;
}

#gNaviUl li#gnav-gravure a {
    background-image: url(/louis/pc/img/common/gnav-gravure-shinjuku.png);
    width: 79px;
}

#gNaviUl li#gnav-kannou a {
    background-image: url(/louis/pc/img/common/gnav-kannou-yaesu.png);
    width: 79px;
}

#gNaviUl li#gnav-bl a {
    background-image: url(/louis/pc/img/common/gnav-bl-ikebukuro.png);
    width: 41px;
}

#gNaviUl li#gnav-tl a {
    background-image: url(/louis/pc/img/common/gnav-tl-ikebukuro.png);
    width: 41px;
}


/* subNavi */
#triangle:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 37px;
    left: 70px;
    z-index: 20;
}

#gNaviUl li#gnav-cat .subNaviWrap {
    position: absolute;
    z-index: 300;
    background: #e2e3e7;
    border-left: 7px solid #fff;
    border-bottom: 7px solid #fff;
    -moz-box-shadow: 0px 0px 15px #000;
    box-shadow: 0px 0px 15px #000;
    -webkit-box-shadow: 0px 0px 15px #000;
    -ms-box-shadow: 0px 0px 15px #000;
}

#gNaviUl li#gnav-cat ul#subNaviCat {
    background: #e2e3e7;
    float: left;
    width: 134px;
    position: relative;
    z-index: 10;
}

#gNaviUl li#gnav-cat ul#subNaviCat li {
    float: none;
}

#gNaviUl li#gnav-cat ul#subNaviCat li a {
    background: none;
    text-indent: 0;
}

#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-01 {
    position: relative;
}

#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-01 a,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-02 a,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-03 a,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-04 a,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-05 a,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-06 a {
    text-indent: -9999px;
    overflow: hidden;
    background-repeat: no-repeat;
    display: block;
}

#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-01 a {
    background-position: 0 -43px;
    background-image: url(/louis/pc/img/common/subnavi-01.png);
    width: 134px;
    height: 43px;
}

#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-02 a {
    background-position: 0 -41px;
    background-image: url(/louis/pc/img/common/subnavi-02.png);
    width: 134px;
    height: 41px;
}

#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-03 a {
    background-position: 0 -40px;
    background-image: url(/louis/pc/img/common/subnavi-03.png);
    width: 134px;
    height: 40px;
}

#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-04 a {
    background-position: 0 -41px;
    background-image: url(/louis/pc/img/common/subnavi-04.png);
    width: 134px;
    height: 41px;
}

#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-05 a {
    background-position: 0 -40px;
    background-image: url(/louis/pc/img/common/subnavi-05.png);
    width: 134px;
    height: 40px;
}

#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-06 a {
    background-position: 0 -40px;
    background-image: url(/louis/pc/img/common/subnavi-06.png);
    width: 134px;
    height: 40px;
}

#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-01 a:hover,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-01 a.current,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-02 a:hover,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-02 a.current,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-03 a:hover,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-03 a.current,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-04 a:hover,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-04 a.current,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-05 a:hover,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-05 a.current,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-06 a:hover,
#gNaviUl li#gnav-cat ul#subNaviCat li#subNaviCat-06 a.current {
    background-position: 0 0px;
}

#gNaviUl li#gnav-cat .subnaviCont01 ul,
#gNaviUl li#gnav-cat .subnaviCont02 ul,
#gNaviUl li#gnav-cat .subnaviCont03 ul,
#gNaviUl li#gnav-cat .subnaviCont04 ul,
#gNaviUl li#gnav-cat .subnaviCont05 ul,
#gNaviUl li#gnav-cat .subnaviCont06 ul {
    float: left;
    display: inline;
    margin: 20px;
}

#gNaviUl li#gnav-cat .subnaviCont01 ul li,
#gNaviUl li#gnav-cat .subnaviCont02 ul li,
#gNaviUl li#gnav-cat .subnaviCont03 ul li,
#gNaviUl li#gnav-cat .subnaviCont04 ul li,
#gNaviUl li#gnav-cat .subnaviCont05 ul li,
#gNaviUl li#gnav-cat .subnaviCont06 ul li {
    float: none;
    line-height: 2.0em;
}

#gNaviUl li#gnav-cat .subnaviCont01 ul li a,
#gNaviUl li#gnav-cat .subnaviCont02 ul li a,
#gNaviUl li#gnav-cat .subnaviCont03 ul li a,
#gNaviUl li#gnav-cat .subnaviCont04 ul li a,
#gNaviUl li#gnav-cat .subnaviCont05 ul li a,
#gNaviUl li#gnav-cat .subnaviCont06 ul li a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
    display: inline;
}

#gNaviUl li#gnav-cat div.subnaviCont01,
#gNaviUl li#gnav-cat div.subnaviCont02,
#gNaviUl li#gnav-cat div.subnaviCont03,
#gNaviUl li#gnav-cat div.subnaviCont04,
#gNaviUl li#gnav-cat div.subnaviCont05,
#gNaviUl li#gnav-cat div.subnaviCont06 {
    background: #fff;
    -moz-box-shadow: -2px 0px 0px #e1e1e1;
    box-shadow: -2px 0px 0px #e1e1e1;
    -webkit-box-shadow: -2px 0px 0px #e1e1e1;
    -ms-box-shadow: -2px 0px 0px #e1e1e1;
    float: left;
    margin: 0 0 0 -1px;
    position: relative;
    z-index: 1;
}

#gNaviUl li#gnav-cat #subNavi-writerlist,
#gNaviUl li#gnav-cat #subNavi-genrelist,
#gNaviUl li#gnav-cat #subNavi-taglist,
#gNaviUl li#gnav-cat #subNavi-publisherlist,
#gNaviUl li#gnav-cat #subNavi-serieslist,
#gNaviUl li#gnav-cat #subNavi-labellist {
    float: right;
    display: inline;
    margin: 0 20px 0 0;
}

#gNaviUl li#gnav-cat #subNavi-writerlist a,
#gNaviUl li#gnav-cat #subNavi-genrelist a,
#gNaviUl li#gnav-cat #subNavi-taglist a,
#gNaviUl li#gnav-cat #subNavi-publisherlist a,
#gNaviUl li#gnav-cat #subNavi-serieslist a,
#gNaviUl li#gnav-cat #subNavi-labellist a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
}

/* search */
#searchSec {
    position: relative;
    margin: 8px 0 0 0;
}

#nav-submit-button {
    position: absolute;
    top: 0;
    right: 0;
}

#nav-submit-button input {
    background: url(/louis/pc/img/common/btn-search.png) no-repeat 0 0;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
    overflow: hidden;
    border: none;
    cursor: pointer;
}

#nav-submit-button input:hover {
    background-position: 0 bottom;
}

#area-login {
    float: right;
    width: 111px;
}

#head-login {
    margin: 6px 0 0 0;
}

#head-login a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    width: 111px;
    height: 35px;
    background: url(/louis/pc/img/common/btn-login-long.png) no-repeat 0 0;
}

#head-login a:hover {
    background-position: 0 bottom;
}

/*  ログイン後のナビ */
#afterlogin-nav {
    position: absolute;
    top: 18px;
    right: 20px;
}

#afterlogin-nav ul {
}

#afterlogin-nav ul li {
    float: left;
    margin: 0 15px 0 0;
}

#afterlogin-nav ul li.last {
    margin: 0 0 0 0;
}

#afterlogin-nav ul li a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background-image: url(/louis/pc/img/common/btn-afterlogin-nav.png);
    background-repeat: no-repeat;
    height: 16px;
}

#afterlogin-nav01 a {
    background-position: 0 0;
    width: 62px;
}

#afterlogin-nav02 a {
    background-position: -79px 0;
    width: 49px;
}

#afterlogin-nav03 a {
    background-position: -145px 0;
    width: 72px;
}

/* 4. pageWrap style */
/* ------------------------------------------------------------------------------------ */
#breadCrumb {
    margin: 12px 0;
}

#breadCrumbInner {
    color: #a1a1a1;
    font-size: 77%;
}

#breadCrumbInner li {
    display: inline;
}

#breadCrumbInner li h1 {
    display: inline;
}

#breadCrumbInner li a {
    color: #a1a1a1;
    text-decoration: none;
}

#breadCrumbInner li a:hover {
    text-decoration: underline;
}

#pageWrap {
    padding: 0 15px;
}

#pageWrapInner {
    min-width: 1050px;
    position: relative;
}

.bookTl {
    width: 100%;
    background: #1d3850;
    margin: 0 0 7px 0;
    padding-bottom: 7px;
}

#yaesu .bookTl {
    width: 100%;
    background: #5a1818;
    margin: 0 0 7px 0;
    padding-bottom: 7px;
}

#akihabara .bookTl {
    width: 100%;
    background: #ffa91c;
    margin: 0 0 7px 0;
    padding-bottom: 7px;
}

#shinjuku .bookTl {
    width: 100%;
    background: #0094d6;
    margin: 0 0 7px 0;
    padding-bottom: 7px;
}

#ikebukuro .bookTl {
    width: 100%;
    background: #ff8ada;
    background: -moz-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff8ada), color-stop(100%, #ffaed8));
    background: -webkit-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: -o-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: -ms-linear-gradient(top, #ff8ada 0%, #ffaed8 100%);
    background: linear-gradient(to bottom, #ff8ada 0%, #ffaed8 100%);
    margin: 0 0 7px 0;
    padding-bottom: 7px;
}

#ikebukuro-tl .bookTl {
    width: 100%;
    background: #c793f0;
    background: -moz-linear-gradient(top, #c793f0 0%, #b482d8 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #c793f0), color-stop(100%, #b482d8));
    background: -webkit-linear-gradient(top, #c793f0 0%, #b482d8 100%);
    background: -o-linear-gradient(top, #c793f0 0%, #b482d8 100%);
    background: -ms-linear-gradient(top, #c793f0 0%, #b482d8 100%);
    background: linear-gradient(to bottom, #c793f0 0%, #b482d8 100%);
    margin: 0 0 7px 0;
    padding-bottom: 7px;
}

#newuser .bookTl {
    width: 100%;
    background: #7bc8ce;
    margin: 0 0 7px 0;
    padding-bottom: 7px;
}

#sougou .bookTl {
    width: 100%;
    background: #1d3850;
    margin: 0 0 7px 0;
    padding-bottom: 7px;
}

.bookTl h2 {
    font-weight: bold;
    color: #fff;
    float: left;
    margin: 8px 0 0 12px;
    font-size: 108%;
}

.bookTl h1 {
    font-weight: bold;
    color: #fff;
    float: left;
    margin: 8px 0 0 12px;
    font-size: 108%;
}

a.rankingLink {
    color: #fff;
}

span.newbook-date {
    display: block;
    color: #fff;
    float: left;
    margin: 0 0 0 20px;
    padding: 11px 0 0 0;
    font-size: 85%;
}

.bookTl p {
    float: right;
    display: inline;
    margin: 9px 12px 0 0;
}

.bookTl p a {
    font-size: 85%;
    color: #fff;
    display: block;
    text-decoration: none;
}

p.btn-moreItem {
    margin: 20px auto 30px;
    width: 25%;
    min-width: 400px;

    a {
        position: relative;
        display: block;
        border: solid 1px #cbced1;
        border-radius: 4px;
        background-color: #fff;
        padding: 10px 25px 10px 10px;
        font-weight: bold;
        font-size: 1.4rem;
        text-align: center;
        text-decoration: none;
        color: #1d4a6d;

        &:hover, &:visited {
            opacity: .7;
        }

        span.arrow {
            position: absolute;
            top: 50%;
            right: 20px;
            display: block;
            margin-top: -4px;

            &:after {
                content: '';
                width: 7px;
                height: 7px;
                border-top: solid 1.5px;
                border-right: solid 1.5px;
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
            }
        }
    }
}

p.btn-newItem-more {
    text-align: center;
    padding: 0 2px;
}

p.btn-newItem-more a {
    display: block;
    background: #bbbab0;
    padding: 7px 0;
    -moz-box-shadow: 0px 2px 0px #989891;
    box-shadow: 0px 2px 0px #989891;
    -webkit-box-shadow: 0px 2px 0px #989891;
    -ms-box-shadow: 0px 2px 0px #989891;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

p.btn-newItem-more a:hover {
    -moz-box-shadow: 0px -2px 0px #989891;
    box-shadow: 0px -2px 0px #989891;
    -webkit-box-shadow: 0px -2px 0px #989891;
    -ms-box-shadow: 0px -2px 0px #989891;
}

/* 5. #centerCol style */
/* ------------------------------------------------------------------------------------ */
#centerCol {
    float: left;
}

.bookWidget {
    margin: 0 0 21px 0;
}

#yaesu .bookItem {
    float: left;
    background: #fff;
    border-top: 4px solid #5a1818;
    position: relative;
}

#akihabara .bookItem {
    float: left;
    background: #fff;
    border-top: 4px solid #ffa91c;
    position: relative;
}

#shinjuku .bookItem {
    float: left;
    background: #fff;
    border-top: 4px solid #0094d6;
    position: relative;
}

#ikebukuro .bookItem {
    float: left;
    background: #fff;
    border-top: 4px solid #ff91d9;
    position: relative;
}

#ikebukuro-tl .bookItem {
    float: left;
    background: #fff;
    border-top: 4px solid #b482d8;
    position: relative;
}

#newuser .bookItem {
    float: left;
    background: #fff;
    border-top: 4px solid #7bc8ce;
    position: relative;
}
#sougou .bookItem {
    float: left;
    background: #fff;
    border-top: 4px solid #1d3850;
    position: relative;
}

#yaesu .bookItem.advertising,
#akihabara .bookItem.advertising,
#shinjuku .bookItem.advertising,
#ikebukuro .bookItem.advertising,
#newuser .bookItem.advertising,
#sougou .bookItem.advertising {
    /* background: #e00178;*/
    border-top: none;
}

/*
.bookItem.advertising h3 {
 font-weight: bold;
 font-size: 107%;
 color: #fff;
 margin: 90px 10px 10px;
}
.bookItem.advertising h4 {
 font-weight: bold;
 color: #fff;
 margin: 0 10px;
}
*/
.triangle-adv {
    position: absolute;
    top: 120px;
    z-index: 100;
}

.triangle-adv:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 20px solid #e00178;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.flagOtoku {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 40;
}

.flagPoint {
    left: 0;
    position: absolute;
    top: 93px;
    z-index: 40;
}

.label-new {
    position: absolute;
    top: -8px;
    right: -5px;
    background: url(/louis/pc/img/common/icon-new.png) no-repeat 0 0;
    width: 42px;
    height: 39px;
    text-indent: -9999px;
    overflow: hidden;
    z-index: 60;
}

.label-prebuy {
    position: absolute;
    top: -8px;
    right: -5px;
    background: url(/louis/pc/img/common/icon-prebuy.png) no-repeat 0 0;
    width: 42px;
    height: 39px;
    text-indent: -9999px;
    overflow: hidden;
    z-index: 60;
}

.label-reserved {
    position: absolute;
    top: -8px;
    right: -5px;
    background: url(/louis/pc/img/common/icon-reserved.png) no-repeat 0 0;
    width: 42px;
    height: 39px;
    text-indent: -9999px;
    overflow: hidden;
    z-index: 70;
}

.bookItemHover,
.bookItemHover-oneBox {
    position: absolute;
    top: -5px;
    left: -3px;
    border: 6px solid #98dabd;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    filter: alpha(opacity=90);
    -moz-opacity: 0.9;
    opacity: 0.9;
    width: 96%;
    height: 98%;
    z-index: 50;
    cursor: pointer;
}

.bookItemHover .cat-label,
.bookItemHover-oneBox .cat-label {
    position: absolute;
}

.bookItemHover ul,
.bookItemHover-oneBox ul {
    background: #98dabd;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 50;
}

.bookItemHover ul li,
.bookItemHover-oneBox ul li {
    filter: alpha(opacity=120);
    -moz-opacity: 1.2;
    opacity: 1.2;
    margin: 5px 0 0 -2px;
}

.bookItemHover ul li a,
.bookItemHover-oneBox ul li a,
.bookItemHover ul li.btn-bookitem-Purchased span,
.bookItemHover ul li.btn-bookitem-checked span,
.bookItemHover ul li.btn-bookitem-cart-in span,
.bookItemHover ul li.btn-bookitem-cart-in a,
.bookItemHover-oneBox ul li.btn-bookitem-Purchased span,
.bookItemHover-oneBox ul li.btn-bookitem-prechecked span {
    display: block;
    text-align: center;
    width: 100%;
    padding: 5px 0 8px 0;
}

.bookItemHover ul li a:hover,
.bookItemHover-oneBox ul li a:hover,
.bookItemHover ul li.btn-bookitem-Purchased span,
.bookItemHover ul li.btn-bookitem-cart-in span,
.bookItemHover ul li.btn-bookitem-cart-in a,
.bookItemHover ul li.btn-bookitem-checked span {
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    background: #70b395;
}

.bookItemHover-oneBox ul li.btn-bookitem-Purchased span,
.bookItemHover-oneBox ul li.btn-bookitem-checked span,
.bookItemHover-oneBox ul li.btn-bookitem-preChecked span,
.bookItemHover-oneBox ul li.btn-bookitem-get span,
.bookItemHover-oneBox ul li.btn-bookitem-reserved a {
    display: block;
    text-align: center;
    width: 100%;
    padding: 5px 0 8px 0;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    background: #70b395;
}

.bookItemHover-oneBox ul li.btn-bookitem-Purchased-2 span {
    font-family: $base-font-family;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    display: block;
    text-align: center;
    width: 100%;
    height: 29px;
    padding: 0;
    font-size: 80%;
    position: relative;
}

.bookItemHover-oneBox ul li.btn-bookitem-Purchased-2 a {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    text-decoration: none;
    color: #fff;
}

.bookItemHover-oneBox ul li.btn-bookitem-Purchased-2 a .ico {
    display: inline-block;
    transform: scale(1.1) translateX(-1px) translateY(2px);
    margin-right: 1px;
}

.postclick {
    cursor: default;
}

/* 書影のボックスの幅 */
.row {
    margin: 0 -1% 7px 1px;
    position: relative;
    zoom: 1;
}

.w1i4 {
    width: 19%;
    margin: 0 7px 0 0;
}

.w1i5 {
    width: 15.9%;
    margin: 0 7px 0 0;
}

.w1i6 {
    width: 11.84%;
    margin: 0 7px 0 0;
}

.bookItemInner {
    position: relative;
    z-index: 30;
}

.img-book {
    text-align: center;
    margin: 9px auto 0;
}

.img-book a {
    position: relative;
    z-index: 10;
    display: inline;
    height: 30px;
}

.img-book a img {
    border: 1px solid #bcbcbc;
    position: relative;
    z-index: 1;
}

.stateUl {
    height: 18px;
    margin: 2px 0 0 15px;
}

.stateUl li {
    float: left;
    display: inline;
    margin: 0 2px 0 0;
}

.book-tl {
    margin: 4px 15px 0;
    color: #1d3850;
    font-weight: bold;
    height: 47px;
    word-wrap: break-word;
}

.book-tl span {
    padding: 0 0 0 2px;
}

.book-tl-only {
    margin: 5px 15px 15px;
    color: #1d3850;
    font-weight: bold;
}

.book-tl-only a {
    text-decoration: none;
}

.book-price {
    color: #e40007;
    font-size: 93%;
    font-weight: bold;
    margin: 10px 15px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 1px 0;
}

.book-price .yen {
    font-size: 80%;
}

.book-price .tax {
    margin-left: 2px;
    font-size: 80%;
}

.book-price02 {
    color: #e40007;
    font-size: 93%;
    font-weight: bold;
    margin: 0 15px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.book-series {
    color: #e40007;
    font-size: 93%;
    font-weight: bold;
    margin: 10px 15px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-height: 17px;
}

.book-series span {
    padding: 0 0 0 7px;
}

.preDate {
    color: #0969a5;
    font-weight: bold;
    margin: 10px 15px 0;
    font-size: 89%;
}

.book-name,
.shop-name {
    color: #929292;
    font-size: 71%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.book-name {
    margin: 5px 15px 0;
}

.shop-name {
    margin: 2px 15px 15px;
}

.shop-name.shop-name-history {
    margin: 2px 15px 5px;
}

.btn-bookItem-left {
    position: absolute;
    left: 0;
    width: 26px;
    z-index: 100;
}

.btn-bookItem-right {
    position: absolute;
    width: 25px;
    right: 1.3%;
    z-index: 100;
}

.btn-bookItem-left a,
.btn-bookItem-right a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    width: 25px;
    position: absolute;
    top: 40%;
    z-index: 100;
}

.btn-bookItem-left a {
    background: url(/louis/pc/img/common/btn-bookitem-left.png) no-repeat -1px 47%;
    border-left: 4px solid #d4d4d4;
}

.btn-bookItem-right a {
    background: url(/louis/pc/img/common/btn-bookitem-right.png) no-repeat 1px 47%;
    border-right: 4px solid #d4d4d4;
}

.btn-bookItem-left a:hover {
    background: url(/louis/pc/img/common/btn-bookitem-left.png) no-repeat -27px 47%;
    border-left: 4px solid #404040;
}

.btn-bookItem-right a:hover {
    background: url(/louis/pc/img/common/btn-bookitem-right.png) no-repeat -26px 47%;
    border-right: 4px solid #404040;
}

.staffrecWrap {
    background: #fff;
    width: 49.5%;
    float: left;
}

.staffrecWrap.right {
    float: right;
}

.staffrecWrap h3 {
    padding: 18px 16px 12px;
    font-size: 131%;
    font-weight: bold;
}

.staffrec-box {
    width: 100%;
    padding: 0 0 5px 0;
}

.staffrec-img {
    float: left;
    display: inline;
    margin: 0 10px 10px 18px;
}

.staffrec-img img {
    border: 1px solid #bbbbbb;
}

.staffrec-textWrap01, .staffrec-textWrap02, .staffrec-textWrap03, .staffrec-textWrap04, .staffrec-textWrap05, .staffrec-textWrap06 {
    display: block;
    margin: 0 18px 0 18px;
    min-width: 180px;
    line-height: 1.6em;
}

.text-more01,
.text-more02,
.text-more03,
.text-more04,
.text-more05,
.text-more06,
.text-collapse01,
.text-collapse02,
.text-collapse03,
.text-collapse04,
.text-collapse05,
.text-collapse06 {
    text-align: right;
    margin: 5px 0 0 0;
}

.staff-name {
    text-align: right;
    color: #929292;
    margin: 10px 0 0 0;
}

.staffrec-bottom {
    margin: 25px 0 0 0;
}

.staffrec-bottom-left {
    float: left;
}

.staffrec-bottom-left h4 a {
    font-size: 116%;
    font-weight: bold;
    color: #1a466e;
    text-decoration: none;
}

.staffrec-writer,
.staffrec-company {
    color: #929292;
    font-size: 85%;
}

.staffrec-bottom-left {
    float: left;
    width: 62%;
}

.staffrec-bottom-right {
    float: right;
}

.staffrec-bottom-right .btn-ranking-check,
.staffrec-bottom-right .btn-ranking-checked,
.staffrec-bottom-right .btn-ranking-precheck,
.staffrec-bottom-right .btn-ranking-prechecked {
    margin: 0 4px 0 0;
}

.staffrec-bottom-right a.bw_btn {
    box-shadow: 0 -4px 0 0 rgba(0, 0, 0, 0.4) inset;
    border-radius: 4px;
}

.staffrec-bottom-right a.bw_btn:hover {
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.4) inset;
}

/* 6. aside style */
/* ------------------------------------------------------------------------------------ */
div #aside {
    width: 226px;
    float: right;
    display: inline;
    margin: 0;
}

aside, #aside {
    width: 226px;
    float: right;
    display: inline;
    margin: 0;
}

#firstComeWrap {
    background: #fff;
    margin: 0 0 14px 0;

    .btn-memberWrap {
        padding: 10px 12px 10px;

        .btn-member-registration {
            width: 202px;
            height: 42px;
            line-height: 38px;
            text-align: center;
            background: #518e48;
            color: #fff;
            font-size: 1.4rem;
            margin: 0 0 5px;

            .ico-member {
                font-size: 1.5rem;
                top: 0;
                padding: 0 10px 0 0;
            }

            .ico-txt {
                font-weight: bold;
            }

            span {
                vertical-align: middle;
            }
        }

        .btn-beginner {
            width: 202px;
            height: 42px;
            line-height: 38px;
            text-align: center;
            border: 1px solid #75af3a;
            color: #5a8d28;
            font-size: 1.4rem;
            box-sizing: border-box;
            box-shadow: none;

            .ico-beginner {
                font-size: 1.7rem;
                top: 0;
                padding: 0 5px 0 0;
            }

            .ico-txt {
                font-weight: bold;
            }

            span {
                vertical-align: middle;
            }

            &:hover {
                padding: 0;
                opacity: 0.7;
            }
        }
    }
}

.sns-member-registrationWrap {
    padding: 0 0 12px;

    .sns-member-list {
        padding: 10px 12px 12px;

        li:not(:last-child) {
            margin: 0 0 10px;
        }

        .sns_btn {
            width: 202px;
            height: 42px;
            border-radius: 4px;
            text-align: center;
            font-size: 1.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-line {
            background-image: url("/louis/pc/img/common/btn_icon_line.png");
            background-repeat: no-repeat;
            background-size: 42px 42px;
            background-color: #00C300;
            color: #fff;

            span {
                margin: 0 0 0 42px;
                border-left: 1px solid #00b300;
                font-weight: bold;
                display: block;
            }

            &:hover {
                background-color: #00e000;

                span {
                    border-left: 1px solid #00c900;
                }
            }
        }

        .btn-x {
            background-image: url("/ex/scc/common/img/btn_icon_x.svg");
            background-repeat: no-repeat;
            background-size: 42px 42px;
            background-color: $x-color;
            color: #fff;

            span {
                margin: 0 0 0 42px;
                border-left: 1px solid $x-color;
                font-weight: bold;
                display: block;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .btn-facebook {
            background-image: url("/louis/pc/img/common/btn_icon_facebook.png");
            background-repeat: no-repeat;
            background-size: 42px 42px;
            background-color: #4267b2;
            color: #fff;

            span {
                margin: 0 0 0 42px;
                border-left: 1px solid #33569d;
                font-weight: bold;
                display: block;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .btn-nico {
            background-image: url("/ex/scc/common/img/btn_icon_nico.png");
            background-repeat: no-repeat;
            background-size: 42px 42px;
            background-color: #252525;
            color: #fff;
            border: 1px solid #252525;
            box-sizing: border-box;

            span {
                margin: 0 0 0 42px;
                font-weight: bold;
                display: block;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .btn-google {
            background-image: url("/ex/scc/common/img/btn_icon_google.png");
            background-repeat: no-repeat;
            background-size: 42px 42px;
            background-color: #ea4234;
            color: #fff;
            border: 1px solid #ea4234;
            box-sizing: border-box;

            span {
                margin: 0 0 0 42px;
                font-weight: bold;
                display: block;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        a:active, a:hover, a:focus {
            text-decoration: none;
        }
    }
}

.btn-sns-more {
    font-size: 1.2rem;
    text-align: right;
    padding: 0 23px 10px 0;

    a:after {
        display: block;
        content: "";
        position: absolute;
        right: 15px;
        width: 0;
        height: 0;
        margin: -11px 0 0 0;
        border-top: 8px solid #1b4558;
        border-left: 8px solid transparent;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.btn-sns-noteWrap {
    margin: 0 12px;
    padding: 6px 8px;
    border: 1px solid #eef0f2;

    .btn-sns-note {
        margin: 0 0 0 1em;
        font-size: 1.2rem;
        line-height: 1.4;

        li:before {
            content: '※';
            margin: 0 0 0 -1em;
        }
    }

}

#btn-member-registration {
    margin: 0 0 8px 0;
}

#btn-member-registration a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background: url(/louis/pc/img/common/btn-member-registration-2.png) no-repeat 0 0;
    width: 202px;
    height: 35px;
}

#btn-member-registration a:hover {
    background: url(/louis/pc/img/common/btn-member-registration-2.png) no-repeat 0 bottom;
}

#btn-trade-point {
    margin: 14px 0 0px 0;
}

#btn-trade-point a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background: url(/louis/pc/img/common/btn-point-more.png) no-repeat 0 0;
    width: 207px;
    height: 26px;
}

#btn-trade-point a:hover {
    background: url(/louis/pc/img/common/btn-point-more.png) no-repeat 0 bottom;
}

#btn-present-exchange {
    margin: 14px 0 0px 0;
}

#btn-present-exchange a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background: url(/louis/pc/img/common/btn-present-exchange.png) no-repeat 0 0;
    width: 207px;
    height: 26px;
}

#btn-present-exchange a:hover {
    background: url(/louis/pc/img/common/btn-present-exchange.png) no-repeat 0 bottom;
}


#btn-ct101 {
    margin: 14px 0 0px 0;
}

#btn-ct101 a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background: url(/louis/pc/img/common/btn-ct101.png) no-repeat 0 0;
    width: 207px;
    height: 26px;
}

#btn-ct101 a:hover {
    background: url(/louis/pc/img/common/btn-ct101.png) no-repeat 0 bottom;
}

.useUl li {
    margin: 0 0 6px 5px;
}

.useUl li.last {
    margin: 0 0 0 5px;
}

#newslistWrap {
    background: #fff;
    margin: 0 0 14px 0;
}

.side-tl-wrap {
    border-bottom: 2px solid #cbced1;
    padding: 0 0 5px 0;
}

.sideCheckWrap {
    background: #fff;
    padding: 0 0 2px 0;
    margin: 0 0 14px 0;
}

#sideCheckWrap {
    background: #fff;
    padding: 0 0 2px 0;
    margin: 0 0 14px 0;
}

#sideCheckWrap .rankingTextWrap ul li.btn-ranking-cart,
.sideCheckWrap .rankingTextWrap ul li.btn-ranking-cart,
#asideInner ul li.btn-ranking-bought,
#asideInner ul li.btn-ranking-get {
    margin: 0 0 0 90px;
}

#sidelookedkWrap {
    background: #fff;
    margin: 0 0 14px 0;
    padding: 0 0 2px 0;
}

.side-tl-wrap h3,
.side-tl h3,
.side-tl-wrap em {
    float: left;
    display: inline;
    margin: 11px 0 0 10px;
    font-weight: bold;
}

#sideRecWrap .side-tl-wrap p,
#newslistWrap .side-tl-wrap p,
#sideCheckWrap .side-tl-wrap p,
#sidelookedkWrap .side-tl-wrap p {
    float: right;
    display: inline;
    margin: 5px 5px 0 0;
}

#sideRecWrap .side-tl-wrap p a,
#newslistWrap .side-tl-wrap p a,
#sideCheckWrap .side-tl-wrap p a,
#sidelookedkWrap .side-tl-wrap p a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background: url(/louis/pc/img/common/btn-newslist.png) no-repeat 0 0;
    width: 42px;
    height: 23px;
}

#newslistWrap .side-tl-wrap p a:hover,
#sideCheckWrap .side-tl-wrap p a:hover,
#sidelookedkWrap .side-tl-wrap p a:hover {
    background: url(/louis/pc/img/common/btn-newslist.png) no-repeat 0 bottom;
}

#newslistWrap dl {
    padding: 12px;
    font-size: 85%;
}

#newslistWrap dl dt {
    float: left;
    color: #1d3850;
}

#newslistWrap dl dd {
    margin: 0 0 13px 70px;
}

#newslistWrap dl dd.last {
    margin: 0 0 0 70px;
}

#newslistWrap dl dd a {
    color: #1d3850;
    text-decoration: none;
}

#newslistWrap dl dd a:hover {
    text-decoration: underline;
}

#sideRecWrap {
    background: #fff;
    margin: 0 0 14px 0;
    padding: 0 0 5px 0;
}

.sideRecBox,
.sideCamBox {
    margin: 12px;
}

.sideCamBox {
    border-top: 1px solid #CBCED1;
    padding-top: 12px;
}

.side-rec-img {
    float: left;
    width: 62px;
    display: inline;
    margin: 0 10px 0 0;
}

.side-rec-img img {
    width: 62px;
    height: 62px;
}

.sideRecBox dl {
    float: left;
    width: 129px;
}

.sideCamBox dl {
    padding: 0 6px;
}

.sideRecBox dl dt,
.sideCamBox dl dt {
    margin: 0 0 7px 0;
    font-size: 93%;
}

.sideRecBox dl dt a,
.sideCamBox dl dt a,
.sideRecBox ul li a {
    color: #1d3850;
    font-weight: bold;
}

.sideRecBox ul li {
    margin-bottom: 8px;
    font-size: 93%;
}

.sideRecBox ul li.last {
    margin-bottom: 0px;
}

.sideRecBox dl dt a:hover {
    text-decoration: underline;
}

.sideRecBox dl dd,
.sideCamBox dl dd {
    font-size: 85%;
}

#sideRecWrap .all-series-link {
    border-top: 2px solid #cbced1;
    padding: 8px 0.5em 0 0;
    text-align: right;
}

#sideRecWrap .all-series-link a {
    text-decoration: none;
    color: #0969a5;
    font-weight: bold;
}

#sideOtherWrap, .sideInfoArea {
    background: #fff;
    margin: 0 0 14px 0;
    padding: 0 0 5px 0;
}


#sideTradePointWrap {
    background: #fff;
    margin: 0 0 14px 0;
    padding: 0 0 5px 0;
}

#ct101Wrap {
    background: #fff;
    margin: 0 0 14px 0;
    padding: 0 0 5px 0;
}

#sideRankingWrap {
    background: #fff;
    margin: 0 0 14px 0;
    padding: 0 0 5px 0;
}

#sideRankingNav {
    margin: 9px 0 0 7px;
}

#sideRankingNav p {
    float: left;
    text-align: center;
}

#yaesu #sideRankingNav p a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background-image: url(/louis/pc/img/common/ranking-tab-yaseu.png);
    background-repeat: no-repeat;
    height: 32px;
}

#akihabara #sideRankingNav p a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background-image: url(/louis/pc/img/common/ranking-tab-akihabara.png);
    background-repeat: no-repeat;
    height: 32px;
}

#shinjuku #sideRankingNav p a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background-image: url(/louis/pc/img/common/ranking-tab-shinjuku.png);
    background-repeat: no-repeat;
    height: 32px;
}

#ikebukuro #sideRankingNav p a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background-image: url(/louis/pc/img/common/ranking-tab-ikebukuro.png);
    background-repeat: no-repeat;
    height: 32px;
}

#newuser #sideRankingNav p a {
    text-indent: -9999px;
    background-image: url(/louis/pc/img/common/ranking-tab-newuser.png);
    background-repeat: no-repeat;
    height: 32px;
    overflow: hidden;
    display: block;
}

#otona #sideRankingNav p a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background-image: url(/louis/pc/img/common/ranking-tab-otona.png);
    background-repeat: no-repeat;
    height: 32px;
}

#ranking-tabDay a {
    background-position: 0 -32px;
    width: 70px;
    margin: 0 1px 0 0;
}

#ranking-tabWeek a {
    background-position: -70px -32px;
    width: 70px;
    margin: 0 1px 0 0;
}

#ranking-tabMonth a {
    background-position: -140px -32px;
    width: 70px;
}

#ranking-tabDay a:hover,
#ranking-tabDay a.active {
    background-position: 0 0;
}

#ranking-tabWeek a:hover,
#ranking-tabWeek a.active {
    background-position: -70px 0;
}

#ranking-tabMonth a:hover,
#ranking-tabMonth a.active {
    background-position: -140px 0;
}

.sideRankingUl {
    margin: 8px 6px;
}

.sideRankingUl li {
    margin: 0 0 7px 0;
    position: relative;
}

.rankingBookImg {
    float: left;
    width: 87px;
    position: relative;
}

span.ranking-new {
    position: absolute;
    top: -3px;
    right: 3px;
    z-index: 30;
}

.rankingBookImg a img {
    border: 1px solid #bbbbbb;
}

.rankingTextWrap {
    width: 126px;
    float: left;
}

.rankingTextWrap h4 {
    margin: 0 0 4px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis; /* Safari */
    -o-text-overflow: ellipsis;
}

#tabWeek .rankingTextWrap h4 a {
    color: #1d3850;
    font-size: 93%;
    font-weight: bold;
    text-decoration: none;
    float: left;
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#tabWeek .rankingTextWrap h4 {
    margin: 0 0 4px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis; /* Safari */
    -o-text-overflow: ellipsis;
}

.rankingTextWrap h4 a {
    color: #1d3850;
    font-size: 93%;
    font-weight: bold;
    float: left;
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.rankingTextWrap h4 span {
    float: left;
    display: block;
    margin: -2px 0 0 0;
}

.rankingTextWrap ul {
    color: #929292;
    font-size: 77%;
}

.rankingTextWrap ul li {
    margin: 0 0 2px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.rankingTextWrap ul li.rankingPrice {
    color: #e40007;
    margin: 6px 0 0 0;
}

.btn-rankingWrap {
    margin: 13px 0 0 0;
}

.btn-ranking-check,
.btn-ranking-checked,
.btn-ranking-precheck,
.btn-ranking-prechecked,
.btn-ranking-cart {
    float: left;
}

.rankingTextWrap ul li.btn-ranking-check,
.rankingTextWrap ul li.btn-ranking-checked {
    margin: 0 3px 0 60px;
}

.rankingTextWrap ul li.btn-ranking-gift,
.rankingTextWrap ul li.btn-ranking-point,
.rankingTextWrap ul li.btn-ranking-free,
.rankingTextWrap ul li.btn-ranking-precheck,
.rankingTextWrap ul li.btn-ranking-prechecked {
    margin: 0 3px 0 90px;
}

.btn-ranking-check a,
.btn-ranking-cart a,
.btn-ranking-free a,
.btn-ranking-get span,
.btn-ranking-bought span,
.btn-ranking-gift a,
.btn-ranking-point a,
.btn-ranking-precheck a,
.btn-ranking-prechecked span,
.btn-ranking-checked span {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    width: 29px;
    height: 28px;
}

.btn-ranking-check a {
    background-image: url(/louis/pc/img/common/btn-rect-check.png);
    background-repeat: no-repeat;
    background-position: 0 0;
}

.btn-ranking-checked span {
    background-image: url(/louis/pc/img/common/btn-rect-check.png);
    background-repeat: no-repeat;
    background-position: 0 -56px;
}

.btn-ranking-prechecked span {
    background-image: url(/louis/pc/img/common/btn-rect-check.png);
    background-repeat: no-repeat;
    background-position: 0 -56px;
}

.btn-ranking-cart a {
    background-image: url(/louis/pc/img/common/btn-rect-cart.png);
    background-repeat: no-repeat;
    background-position: 0 0;
}

.btn-ranking-free a {
    background-image: url(/louis/pc/img/common/btn-rect-free.png);
    background-repeat: no-repeat;
    background-position: 0 0;
}

.btn-ranking-get span {
    background-image: url(/louis/pc/img/common/btn-rect-get.png);
    background-repeat: no-repeat;
    background-position: 0 0;
}

.btn-ranking-bought span {
    background-image: url(/louis/pc/img/common/btn-rect-bought.png);
    background-repeat: no-repeat;
    background-position: 0 0;
}

.btn-ranking-gift a {
    background-image: url(/louis/pc/img/common/btn-rect-gift.png);
    background-repeat: no-repeat;
    background-position: 0 0;
}

.btn-ranking-point a {
    background-image: url(/louis/pc/img/common/btn-rect-point.png);
    background-repeat: no-repeat;
    background-position: 0 0;
}

.btn-ranking-precheck a {
    background-image: url(/louis/pc/img/common/btn-rect-check.png);
    background-repeat: no-repeat;
    background-position: 0 0;
}

.btn-ranking-check a:hover,
.btn-ranking-precheck a:hover,
.btn-ranking-cart a:hover,
.btn-ranking-free a:hover,
.btn-ranking-gift a:hover,
.btn-ranking-point a:hover {
    background-position: 0 -28px;
}

.btn-ranking-cart .btnDisabled {
    background-image: url(/louis/pc/img/common/btn-rect-cartin.png);
    background-position: 0 0;
    background-repeat: no-repeat;
}

.btn-ranking-cart .btnDisabled:hover {
    background-image: url(/louis/pc/img/common/btn-rect-cartin.png);
    background-position: 0 0;
    background-repeat: no-repeat;
}

.ranking-num {
    display: block;
    width: 33px;
    height: 33px;
    background-image: url(/louis/pc/img/common/side-ranking-flag.png);
    background-repeat: no-repeat;
    position: absolute;
    z-index: 10;
}

.ranking01 .ranking-num {
    background-position: 0 0;
}

.ranking02 .ranking-num {
    background-position: 0 -33px;
}

.ranking03 .ranking-num {
    background-position: 0 -66px;
}

.ranking04 .ranking-num {
    background-position: 0 -99px;
}

.ranking05 .ranking-num {
    background-position: 0 -132px;
}

.ranking06 .ranking-num {
    background-position: 0 -165px;
}

.ranking07 .ranking-num {
    background-position: 0 -198px;
}

.ranking08 .ranking-num {
    background-position: 0 -231px;
}

.ranking09 .ranking-num {
    background-position: 0 -264px;
}

.ranking10 .ranking-num {
    background-position: 0 -297px;
}

.btn-ranking-more {
    text-align: right;
    padding: 5px 25px 0 0;

    a {
        text-decoration: none;
        background-color: transparent;

        &:after {
            display: block;
            content: "";
            position: absolute;
            right: 15px;
            width: 0;
            height: 0;
            margin: -13px 0 0 0;
            border-top: 8px solid #1b4558;
            border-left: 8px solid transparent;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

#twitterWrap {
    margin: 0 0 14px 0;
}

#twitterWrap h3 {
    text-indent: -9999px;
    background: url(/louis/pc/img/common/side-tl-tweet.png) no-repeat 0 0;
    width: 226px;
    height: 31px;
    margin: 0 0 0 0;
}

#sideBnrWrap {
    margin: 14px 0 0 0;
}

#sideBnrWrap ul li {
    margin: 0 0 7px 0;
}

#btn-pagetop {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
}

#btn-pagetop a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background: url(/louis/pc/img/common/btn-pagetop.png) no-repeat 0 0;
    width: 49px;
    height: 49px;
}

#btn-pagetop a:hover {
    background-position: 0 bottom;
}

/* palace Navi */
/*#akihabara #place-navileft {
 position: fixed;
 top: 50%;
 left: -261px;
 z-index: 100;
 background: url(/louis/pc/img/common/place03-left.png) no-repeat 0 0;
 width: 308px;
 height: 101px;
}
#akihabara #place-naviright {
 position: fixed;
 top: 50%;
 right: -261px;
 z-index: 100;
 background: url(/louis/pc/img/common/place02-right.png) no-repeat 0 0;
 width: 308px;
 height: 101px;
}

#shinjuku #place-navileft {
 position: fixed;
 top: 50%;
 left: -261px;
 z-index: 100;
 background: url(/louis/pc/img/common/place01-left.png) no-repeat 0 0;
 width: 308px;
 height: 101px;
}
#shinjuku #place-naviright {
 position: fixed;
 top: 50%;
 right: -261px;
 z-index: 100;
 background: url(/louis/pc/img/common/place03-right.png) no-repeat 0 0;
 width: 308px;
 height: 101px;
}

#yaesu #place-navileft {
 position: fixed;
 top: 50%;
 left: -261px;
 z-index: 100;
 background: url(/louis/pc/img/common/place02-left.png) no-repeat 0 0;
 width: 308px;
 height: 101px;
}
#yaesu #place-naviright {
 position: fixed;
 top: 50%;
 right: -261px;
 z-index: 100;
 background: url(/louis/pc/img/common/place01-right.png) no-repeat 0 0;
 width: 308px;
 height: 101px;
}
#ikebukuro #place-navileft {
 position: fixed;
 top: 50%;
 left: -261px;
 z-index: 100;
 background: url(/louis/pc/img/common/place01-left.png) no-repeat 0 0;
 width: 308px;
 height: 101px;
}
#ikebukuro #place-naviright {
 position: fixed;
 top: 50%;
 right: -261px;
 z-index: 100;
 background: url(/louis/pc/img/common/place02-right.png) no-repeat 0 0;
 width: 308px;
 height: 101px;
}
#place-navileft .placeUl {
 position: absolute;
 top: 6px;
 left: 118px;
 width: 150px;
}
#place-navileft .placeUl li {
 display: inline;
}
#place-navileft a {
 position: absolute;
 top: 39px;
 right: 20px;
}
#place-naviright .placeUl {
 position: absolute;
 top: 6px;
 right: 100px;
 width: 150px;
}
#place-naviright .placeUl li {
 display: inline;
}
#place-naviright a {
 position: absolute;
 top: 39px;
 left: 20px;
}*/
.loadingWrap {
    text-align: center;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 21px;
    margin-left: 0;
}

/* 書影が複数ある時 */
.books {
    background: url(/louis/pc/img/detail/bg-series.png) repeat-x 0 bottom;
    padding: 0 0 4px 0;
}

.books img {
    border: 1px solid #b8b8b8;
}


aside {
    .aside-title h2 {
        background: #fff;
        font-size: 110%;
        font-weight: bold;
        padding: 11px 5px 6px 10px;
        border-bottom: 2px solid #cbced1;
    }

    .sideFreemangaBannerWrap {
        margin: 0 0 10px 2px;
    }
}

.sideInfoArea {
    ul {
        margin-bottom: 7px;

        .indent-1 {
            text-indent: 1em;
        }

        .indent-2 {
            text-indent: 2em;
        }
    }

    h4 {
        margin-left: 7px;
    }
}

/* 7. footer style */
/* ------------------------------------------------------------------------------------ */
#footSec {
    background: #fff;
    //min-width: 1080px;
}

#footInner {
    //min-width: 1050px;
    //padding: 20px;
    box-sizing: border-box;
}

#footAlpha {
    width: 75%;
    float: left;
    margin: 0 0 0 0;
    font-size: 84%;
}

#footBeta {
    min-width: 169px;
    width: 21%;
    float: right;
    font-size: 84%;
}

#footAlpha h5,
#footBeta h5 {
    border-bottom: 2px solid #cbced1;
    padding: 0 0 6px 1px;
    margin: 0 0 16px 0;
    font-weight: bold;
    font-size: 108%;
}

#footAlpha h6 {
    font-weight: bold;
    margin: 0 0 15px 2px;
}

#footAlpha h6 a {
    text-decoration: none;
    color: #888888;
}

#footAlpha ul,
#footBeta ul {
    margin: 0 0 0 3px;
}

#footAlpha ul li,
#footBeta ul li {
    margin-bottom: 4px;
    font-size: 85%;
}

#footAlpha ul li a,
#footBeta ul li a {
    text-decoration: none;
    color: #888888;
}

#footAlpha ul li a:hover,
#footBeta ul li a:hover {
    text-decoration: underline;
}

.footAlpha-column01,
.footAlpha-column02,
.footAlpha-column03 {
    float: left;
}

.footAlpha-column01 {
    width: 48%;
    margin: 0 1% 0 0;
}

.footAlpha-column02,
.footAlpha-column03 {
    width: 23%;
    margin: 0 1% 0 0;
}

.footAlpha-column03 {
    margin: 0 0 0 0;
}

.footAlphaUl01,
.footAlphaUl02 {
    float: left;
    width: 48%;
    margin: 0 1% 0 0;
}

.footAlphaUl02 {
    margin: 0 0 0 0;
}
/*
#foot-bottom {
    padding: 5px 20px 20px;
    box-sizing: border-box;
    min-width: 1050px;
}
*/

/* ====================================================================================
	footer-ABJ　（ABJマーク）
======================================================================================= */
.footer-ABJ {
    display: flex;
    align-items: center;
    width: 80%;
    min-height: 55px;
    padding: 20px 20px 0 10px;

    .footer-ABJ__logo {
        display: inline-block;
        width: 66px;
        height: 55px;
        background: url(/louis/pc/img/common/logo-ABJ.svg) no-repeat;
        background-size: 66px 55px;
        margin-right: 4px;
    }

    .footer-ABJ__message {
        width: 70%;
        font-size: 10px;
        line-height: 1.5;
    }
}

.footInner {
    //min-width: 780px;
    color: #888888;
    padding: 20px;
}

/*
#foot-logo {
    float: left;
    width: 177px;
    margin: 0 18px 0 0;
}

#foot-bottom-right {
    float: left;
    margin: -5px 0 0 0;
    width: 795px;
}

#foot-bottom-right ul li {
    display: inline;
    padding: 0 5px 0 0;
    font-size: 77%;
    white-space: nowrap;
}

#foot-bottom-right ul li a {
    text-decoration: none;
}

#foot-bottom-right ul li a:hover {
    text-decoration: underline;
}

#foot-bottom-right p {
    line-height: 0;
    margin: 2px 0 0 0;
}
 */

.bnr-guerrilla-tagtop {
    margin: 0 0 14px 0;
}

.bnr-guerrilla-tagtop img {
    width: 100%;
}

/* ---yaesu----------------------- */
/* header style */
#yaesu #placeNavi li#place03 a {
    background-position: 0 -225px;
}

/* ---akihabara----------------------- */
/* header style */
#akihabara #placeNavi li#place01 a {
    background-position: 0 -33px;
}

/* gNavi style */
#akihabara #gNaviUl li#gnav-cat a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-cat-akihabara.png);
    width: 127px;
}

#akihabara #gNaviUl li#gnav-willdeliv a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-willdeliv-akihabara.png);
    width: 77px;
}

#akihabara #gNaviUl li#gnav-ranking a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-ranking-akihabara.png);
    width: 84px;
}

#akihabara #gNaviUl li#gnav-newbook a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-newbook-akihabara.png);
    width: 63px;
}

#akihabara #gNaviUl li#gnav-lightnovel a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-lightnovel-akihabara.png);
    width: 99px;
}

#akihabara #gNaviUl li#gnav-magazine a {
    background-image: url(/louis/pc/img/common/akihabara/zassi.png);
    width: 63px;
}

#akihabara #gNaviUl li#gnav-comic a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-comic-akihabara.png);
    width: 75px;
}

#akihabara #gNaviUl li#gnav-game a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-game-akihabara.png);
    width: 75px;
}

#akihabara #gNaviUl li#gnav-comic2 a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-comic3.png);
    width: 79px;
}

#akihabara #gNaviUl li#gnav-game2 a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-game2.png);
    width: 79px;
}

#akihabara #gNaviUl li#gnav-girls a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-girls.png);
    width: 79px;
}

#akihabara #gNaviUl li#gnav-lightnovel2 a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-lightnovel2.png);
    width: 79px;
}

#akihabara #gNaviUl li#gnav-literature a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-literature.png);
    width: 80px;
}

#akihabara #gNaviUl li#gnav-practical a {
    background-image: url(/louis/pc/img/common/akihabara/gnav-practical.png);
    width: 79px;
}

/* suvNavi style */
#akihabara #gNaviUl li#gnav-cat .subnaviCont01 ul li a,
#akihabara #gNaviUl li#gnav-cat .subnaviCont02 ul li a,
#akihabara #gNaviUl li#gnav-cat .subnaviCont03 ul li a,
#akihabara #gNaviUl li#gnav-cat .subnaviCont04 ul li a,
#akihabara #gNaviUl li#gnav-cat .subnaviCont05 ul li a,
#akihabara #gNaviUl li#gnav-cat .subnaviCont06 ul li a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
    display: inline;
}

#akihabara #gNaviUl li#gnav-cat #subNavi-writerlist a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
}

#akihabara #gNaviUl li#gnav-cat #subNavi-writerlist,
#akihabara #gNaviUl li#gnav-cat #subNavi-genrelist,
#akihabara #gNaviUl li#gnav-cat #subNavi-taglist,
#akihabara #gNaviUl li#gnav-cat #subNavi-publisherlist,
#akihabara #gNaviUl li#gnav-cat #subNavi-serieslist,
#akihabara #gNaviUl li#gnav-cat #subNavi-labellist {
    float: right;
    display: inline;
    margin: 0 20px 0 0;
}

#akihabara #gNaviUl li#gnav-cat #subNavi-writerlist a,
#akihabara #gNaviUl li#gnav-cat #subNavi-genrelist a,
#akihabara #gNaviUl li#gnav-cat #subNavi-taglist a,
#akihabara #gNaviUl li#gnav-cat #subNavi-publisherlist a,
#akihabara #gNaviUl li#gnav-cat #subNavi-serieslist a,
#akihabara #gNaviUl li#gnav-cat #subNavi-labellist a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
}

/* ---shinjuku----------------------- */
/* header style */
#shinjuku #placeNavi li#place02 a {
    background-position: 0 -129px;
}

/* gNavi style */
#shinjuku #gNaviUl li#gnav-cat a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-cat-shinjuku.png);
    width: 127px;
}

#shinjuku #gNaviUl li#gnav-willdeliv a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-willdeliv-shinjuku.png);
    width: 77px;
}

#shinjuku #gNaviUl li#gnav-newbook a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-newbook-shinjuku.png);
    width: 63px;
}

#shinjuku #gNaviUl li#gnav-ranking a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-ranking-shinjuku.png);
    width: 84px;
}

#shinjuku #gNaviUl li#gnav-literature a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-literature-shinjuku.png);
    width: 58px;
}

#shinjuku #gNaviUl li#gnav-comic a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-comic-shinjuku.png);
    width: 75px;
}

#shinjuku #gNaviUl li#gnav-girls a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-girls-shinjuku.png);
    width: 79px;
}

#shinjuku #gNaviUl li#gnav-photographs a {
    background-image: url(/louis/pc/img/common/shinjuku/shashinnsyuu.png);
    width: 63px;
}

#shinjuku #gNaviUl li#gnav-area a {
    background-image: url(/louis/pc/img/common/shinjuku/tiikijoouhou.png);
    width: 77px;
}

#shinjuku #gNaviUl li#gnav-comic2 a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-comic3.png);
    width: 79px;
}

#shinjuku #gNaviUl li#gnav-game a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-game.png);
    width: 75px;
}

#shinjuku #gNaviUl li#gnav-girls2 a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-girls2.png);
    width: 79px;
}

#shinjuku #gNaviUl li#gnav-lightnovel a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-lightnovel.png);
    width: 79px;
}

#shinjuku #gNaviUl li#gnav-literature2 a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-literature2.png);
    width: 75px;
}

#shinjuku #gNaviUl li#gnav-practical a {
    background-image: url(/louis/pc/img/common/shinjuku/gnav-practical.png);
    width: 79px;
}


/* suvNavi style */
#shinjuku #gNaviUl li#gnav-cat .subnaviCont01 ul li a,
#shinjuku #gNaviUl li#gnav-cat .subnaviCont02 ul li a,
#shinjuku #gNaviUl li#gnav-cat .subnaviCont03 ul li a,
#shinjuku #gNaviUl li#gnav-cat .subnaviCont04 ul li a,
#shinjuku #gNaviUl li#gnav-cat .subnaviCont05 ul li a,
#shinjuku #gNaviUl li#gnav-cat .subnaviCont06 ul li a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
    display: inline;
}

#shinjuku #gNaviUl li#gnav-cat #subNavi-writerlist,
#shinjuku #gNaviUl li#gnav-cat #subNavi-genrelist,
#shinjuku #gNaviUl li#gnav-cat #subNavi-taglist,
#shinjuku #gNaviUl li#gnav-cat #subNavi-publisherlist,
#shinjuku #gNaviUl li#gnav-cat #subNavi-serieslist,
#shinjuku #gNaviUl li#gnav-cat #subNavi-labellist {
    float: right;
    display: inline;
    margin: 0 20px 0 0;
}

#shinjuku #gNaviUl li#gnav-cat #subNavi-writerlist a,
#shinjuku #gNaviUl li#gnav-cat #subNavi-genrelist a,
#shinjuku #gNaviUl li#gnav-cat #subNavi-taglist a,
#shinjuku #gNaviUl li#gnav-cat #subNavi-publisherlist a,
#shinjuku #gNaviUl li#gnav-cat #subNavi-serieslist a,
#shinjuku #gNaviUl li#gnav-cat #subNavi-labellist a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
}

/* --- ikebukuro ----------------------- */
/* header style */
#ikebukuro #placeNavi li#place04 a {
    background-position: 0 -321px;
}

/* gNavi style */
#ikebukuro #gNaviSec {
    background: url(/louis/pc/img/common/ikebukuro/bg-gnav.png) repeat-x 0 0;
}

#ikebukuro #gNaviUl li#gnav-cat a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-cat-ikebukuro.png);
    width: 127px;
}

#ikebukuro #gNaviUl li#gnav-willdeliv a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-willdeliv-ikebukuro.png);
    width: 77px;
}

#ikebukuro #gNaviUl li#gnav-newbook a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-newbook-ikebukuro.png);
    width: 63px;
}

#ikebukuro #gNaviUl li#gnav-ranking a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-ranking-ikebukuro.png);
    width: 84px;
}

#ikebukuro #gNaviUl li#gnav-literature a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-literature-ikebukuro.png);
    width: 58px;
}

#ikebukuro #gNaviUl li#gnav-comic a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-comic-ikebukuro.png);
    width: 75px;
}

#ikebukuro #gNaviUl li#gnav-girls a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-girls-ikebukuro.png);
    width: 79px;
}

#ikebukuro #gNaviUl li#gnav-comic2 a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-comic3-ikebukuro.png);
    width: 79px;
}

#ikebukuro #gNaviUl li#gnav-game a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-game-ikebukuro.png);
    width: 75px;
}

#ikebukuro #gNaviUl li#gnav-girls2 a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-girls2-ikebukuro.png);
    width: 79px;
}

#ikebukuro #gNaviUl li#gnav-lightnovel a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-lightnovel-ikebukuro.png);
    width: 79px;
}

#ikebukuro #gNaviUl li#gnav-literature2 a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-literature2-ikebukuro.png);
    width: 75px;
}

#ikebukuro #gNaviUl li#gnav-practical a {
    background-image: url(/louis/pc/img/common/ikebukuro/gnav-practical-ikebukuro.png);
    width: 79px;
}


/* suvNavi style */
#ikebukuro #gNaviUl li#gnav-cat .subnaviCont01 ul li a,
#ikebukuro #gNaviUl li#gnav-cat .subnaviCont02 ul li a,
#ikebukuro #gNaviUl li#gnav-cat .subnaviCont03 ul li a,
#ikebukuro #gNaviUl li#gnav-cat .subnaviCont04 ul li a,
#ikebukuro #gNaviUl li#gnav-cat .subnaviCont05 ul li a,
#ikebukuro #gNaviUl li#gnav-cat .subnaviCont06 ul li a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
    display: inline;
}

#ikebukuro #gNaviUl li#gnav-cat #subNavi-writerlist a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
}

#ikebukuro #gNaviUl li#gnav-cat #subNavi-writerlist,
#ikebukuro #gNaviUl li#gnav-cat #subNavi-genrelist,
#ikebukuro #gNaviUl li#gnav-cat #subNavi-taglist,
#ikebukuro #gNaviUl li#gnav-cat #subNavi-publisherlist,
#ikebukuro #gNaviUl li#gnav-cat #subNavi-serieslist,
#ikebukuro #gNaviUl li#gnav-cat #subNavi-labellist {
    float: right;
    display: inline;
    margin: 0 20px 0 0;
}

#ikebukuro #gNaviUl li#gnav-cat #subNavi-writerlist a,
#ikebukuro #gNaviUl li#gnav-cat #subNavi-genrelist a,
#ikebukuro #gNaviUl li#gnav-cat #subNavi-taglist a,
#ikebukuro #gNaviUl li#gnav-cat #subNavi-publisherlist a,
#ikebukuro #gNaviUl li#gnav-cat #subNavi-serieslist a,
#ikebukuro #gNaviUl li#gnav-cat #subNavi-labellist a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
}

/* --- ec ----------------------- */
#ec #placeNavi li#place05 a {
    background-position: 0 -417px;
}

/*--- newuser -----------------------*/
/* gNavi style */
#newuser #gNaviUl li#gnav-cat a {
    background-image: url(/louis/pc/img/common/newuser/gnav-cat-newuser.png);
    width: 127px;
}

#newuser #gNaviUl li#gnav-willdeliv a {
    background-image: url(/louis/pc/img/common/newuser/gnav-willdeliv-newuser.png);
    width: 71px;
}

#newuser #gNaviUl li#gnav-newbook a {
    background-image: url(/louis/pc/img/common/newuser/gnav-newbook-newuser.png);
    width: 50px;
}

#newuser #gNaviUl li#gnav-ranking a {
    background-image: url(/louis/pc/img/common/newuser/gnav-ranking-newuser.png);
    width: 78px;
}

#newuser #gNaviUl li#gnav-literature a {
    background-image: url(/louis/pc/img/common/newuser/gnav-literature-newuser.png);
    width: 50px;
}

#newuser #gNaviUl li#gnav-comic a {
    background-image: url(/louis/pc/img/common/newuser/gnav-comic-newuser.png);
    width: 63px;
}

#newuser #gNaviUl li#gnav-jitsuyou a {
    background-image: url(/louis/pc/img/common/newuser/gnav-jitsuyou-newuser.png);
    width: 47px;
}

#newuser #gNaviUl li#gnav-lightnovel a {
    background-image: url(/louis/pc/img/common/newuser/gnav-light-newuser.png);
    width: 77px;
}

#newuser #gNaviUl li#gnav-comic2 a {
    background-image: url(/louis/pc/img/common/newuser/gnav-comic3.png);
    width: 79px;
}

#newuser #gNaviUl li#gnav-game a {
    background-image: url(/louis/pc/img/common/newuser/gnav-game.png);
    width: 75px;
}

#newuser #gNaviUl li#gnav-girls a {
    background-image: url(/louis/pc/img/common/newuser/gnav-gilrs.png);
    width: 79px;
}

#newuser #gNaviUl li#gnav-light2 a {
    background-image: url(/louis/pc/img/common/newuser/gnav-light2.png);
    width: 79px;
}

#newuser #gNaviUl li#gnav-literature2 a {
    background-image: url(/louis/pc/img/common/newuser/gnav-literature2.png);
    width: 75px;
}

#newuser #gNaviUl li#gnav-practical a {
    background-image: url(/louis/pc/img/common/newuser/gnav-practical.png);
    width: 79px;
}

/* suvNavi style */
#newuser #gNaviUl li#gnav-cat .subnaviCont01 ul li a,
#newuser #gNaviUl li#gnav-cat .subnaviCont02 ul li a,
#newuser #gNaviUl li#gnav-cat .subnaviCont03 ul li a,
#newuser #gNaviUl li#gnav-cat .subnaviCont04 ul li a,
#newuser #gNaviUl li#gnav-cat .subnaviCont05 ul li a,
#newuser #gNaviUl li#gnav-cat .subnaviCont06 ul li a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
    display: inline;
}

#newuser #gNaviUl li#gnav-cat #subNavi-writerlist,
#newuser #gNaviUl li#gnav-cat #subNavi-genrelist,
#newuser #gNaviUl li#gnav-cat #subNavi-taglist,
#newuser #gNaviUl li#gnav-cat #subNavi-publisherlist,
#newuser #gNaviUl li#gnav-cat #subNavi-serieslist,
#newuser #gNaviUl li#gnav-cat #subNavi-labellist {
    float: right;
    display: inline;
    margin: 0 20px 0 0;
}

#newuser #gNaviUl li#gnav-cat #subNavi-writerlist a,
#newuser #gNaviUl li#gnav-cat #subNavi-genrelist a,
#newuser #gNaviUl li#gnav-cat #subNavi-taglist a,
#newuser #gNaviUl li#gnav-cat #subNavi-publisherlist a,
#newuser #gNaviUl li#gnav-cat #subNavi-serieslist a,
#newuser #gNaviUl li#gnav-cat #subNavi-labellist a {
    background: none;
    text-indent: 0;
    width: auto;
    height: auto;
}

/*-------------------------------------------------------------------------------------------*/
/*
スマートフォンへのリンク
*/
#spLink-wrap {
    background: #EFEFEB;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -moz-box-shadow: 0px 0px 5px #999;
    box-shadow: 0px 0px 5px #999;
    -webkit-box-shadow: 0px 0px 5px #999;
    -ms-box-shadow: 0px 0px 5px #999;
    position: relative;
    z-index: 2000;
    height: 40px;
    text-align: center;
    margin: 0 auto;
    padding: 0 0 0 0;
}

#spLinkTxt01 {
    font-weight: bold;
    font-size: 220%;
    margin: 0 0 8px 0;
}

#spLinkTxt02 {
    font-size: 200%;
}

#splink-delete {
    position: absolute;
    top: 4px;
    right: 10px;
}

#splink-delete a {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    background: url(/louis/pc/img/common/btn-delete-sp-link.png) no-repeat 0 0;
    background-size: contain;
    width: 35px;
    height: 35px;
}


#pageWrapInner #box-wrapper #centerCol {
    width: 100%;
}

#pageWrapInner #box-wrapper #itemSec {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -o-box;
    display: box;
    padding-bottom: 10px;
}

#pageWrapInner #box-wrapper #itemSec #aside {
    display: block;

    float: none;
    width: 226px;
}

#pageWrapInner #box-wrapper #itemSec #bside {
    display: block;

    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;

    float: none;
    width: auto;
}

#staffrec-box-wrapper {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-bottom: 10px;
}

#staffrec-box-wrapper div.staffrecWrap,
#staffrec-box-wrapper div.staffrecWrap.right {
    float: none;
}

#staffrec-box-wrapper div.staffrecWrap {
    margin-right: 5px;
}

#staffrec-box-wrapper div.staffrecWrap:nth-of-type(2) {
    margin-right: 0;
    margin-left: 5px;
}

#topCoinNoticeHoverTips {
    color: #2f2f2f;
    display: none;
    border: 1px solid #bababa;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    padding: 7px;
    position: absolute;
    right: 1px;
    top: 18px;
    background: #eee;
    width: 212px;
    font-size: 100%;
    white-space: normal;
    z-index: 9999;
    text-align: left;
}


div.hoverWrapperItem {
    display: none;
}

#centerCol {
    width: 810px;
}

body#akihabara div.bookItem.cartinchecked,
body#newuser div.bookItem.cartinchecked,
body#shinjuku div.bookItem.cartinchecked,
body#ikebukuro div.bookItem.cartinchecked,
body#yaesu div.bookItem.cartinchecked,
body#sougou div.bookItem.cartinchecked
{
    border-top: 4px solid #999;
}

body#akihabara div.bookItem.bought,
body#newuser div.bookItem.bought,
body#shinjuku div.bookItem.bought,
body#ikebukuro div.bookItem.bought,
body#yaesu div.bookItem.bought,
body#sougou div.bookItem.bought {
    background: #ccc;
}

body#akihabara div.bookItem.reserved,
body#newuser div.bookItem.reserved,
body#shinjuku div.bookItem.reserved,
body#ikebukuro div.bookItem.reserved,
body#yaesu div.bookItem.reserved,
body#sougou div.bookItem.reserved {
    background: #d8dbfb;;
}

body#akihabara div.bookItem.subscription,
body#newuser div.bookItem.subscription,
body#shinjuku div.bookItem.subscription,
body#ikebukuro-tl div.bookItem.subscription,
body#yaesu div.bookItem.subscription,
body#sougou div.bookItem.subscription {
    background: #ccc;
}

#loginMenuWrap {
    display: none;
}

ul li.btn-bookitem-check.trial a,
ul li.btn-bookitem-checked.trial a,
ul li.btn-bookitem-check.trial span,
ul li.btn-bookitem-checked.trial span {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    width: 50%;
    display: inline-block;
    overflow: visible;
}


.fw-tab_1 {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.fw-tab_1 li {
    float: left;
}

.fw-tab_1 li a {
    height: 33px;
    display: block;
    text-indent: -99999px;
    text-decoration: none;
    text-outline: none;
    background-image: url(/louis/pc/img/ec-list/fw-tab-list_1.png);
    background-repeat: no-repeat;
}

.nav01-1 a {
    width: 148px;
    background-position: 0 -33px;
}

.nav01-1 a:hover {
    background-position: 0 -33px;
}

.nav02-1 a {
    width: 148px;
    background-position: -148px 0;
}

.nav02-1 a:hover {
    background-position: -148px -33px;
}

.nav03-1 a {
    width: 148px;
    background-position: -300px 0;
}

.nav03-1 a:hover {
    background-position: -300px -33px;
}

.nav04-1 a {
    width: 148px;
    background-position: -452px 0;
}

.nav04-1 a:hover {
    background-position: -452px -34px;
}

.fw-tab_2 {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.fw-tab_2 li {
    float: left;
}

.fw-tab_2 li a {
    height: 33px;
    display: block;
    text-indent: -99999px;
    text-decoration: none;
    text-outline: none;
    background-image: url(/louis/pc/img/ec-list/fw-tab-list_2.png);
    background-repeat: no-repeat;
}

.nav01-2 a {
    width: 148px;
    background-position: 0 -33px;
}

.nav01-2 a:hover {
    background-position: 0 -33px;
}

.nav02-2 a {
    width: 148px;
    background-position: -148px 0;
}

.nav02-2 a:hover {
    background-position: -148px -33px;
}

.nav03-2 a {
    width: 148px;
    background-position: -300px 0;
}

.nav03-2 a:hover {
    background-position: -300px -33px;
}

.fw-tab_3 {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.fw-tab_3 li {
    float: left;
}

.fw-tab_3 li a {
    height: 33px;
    display: block;
    text-indent: -99999px;
    text-decoration: none;
    text-outline: none;
    background-image: url(/louis/pc/img/ec-list/fw-tab-list_3.png);
    background-repeat: no-repeat;
}

.nav01-3 a {
    width: 148px;
    background-position: 0 -33px;
}

.nav01-3 a:hover {
    background-position: 0 -33px;
}

.nav02-3 a {
    width: 148px;
    background-position: -148px 0;
}

.nav02-3 a:hover {
    background-position: -148px -33px;
}

.nav03-3 a {
    width: 148px;
    background-position: -300px 0;
}

.nav03-3 a:hover {
    background-position: -300px -33px;
}

.fw-tab_4 {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.fw-tab_4 li {
    float: left;
}

.fw-tab_4 li a {
    height: 33px;
    display: block;
    text-indent: -99999px;
    text-decoration: none;
    text-outline: none;
    background-image: url(/louis/pc/img/ec-list/fw-tab-list_4.png);
    background-repeat: no-repeat;
}

.nav01-4 a {
    width: 148px;
    background-position: 0 -33px;
}

.nav01-4 a:hover {
    background-position: 0 -33px;
}

.nav02-4 a {
    width: 148px;
    background-position: -148px 0;
}

.nav02-4 a:hover {
    background-position: -148px -33px;
}

.nav03-4 a {
    width: 148px;
    background-position: -300px 0;
}

.nav03-4 a:hover {
    background-position: -300px -33px;
}

.fw-tab_0 {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.fw-tab_0 li {
    float: left;
}

.fw-tab_0 li a {
    height: 33px;
    display: block;
    text-indent: -99999px;
    text-decoration: none;
    text-outline: none;
    background-image: url(/louis/pc/img/ec-list/fw-tab-list_5.png);
    background-repeat: no-repeat;
}

.nav01-0 a {
    width: 148px;
    background-position: 0 -33px;
}

.nav01-0 a:hover {
    background-position: 0 -33px;
}

.nav02-0 a {
    width: 148px;
    background-position: -148px 0;
}

.nav02-0 a:hover {
    background-position: -148px -33px;
}

.nav03-0 a {
    width: 148px;
    background-position: -300px 0;
}

.nav03-0 a:hover {
    background-position: -300px -33px;
}

/*list*/

/* noren */
.ageCheckwrapper {
    width: 100%;
    min-width: 700px;
    margin: 10px auto 20px auto;
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;

}

.ageChecktitle {
    margin: 0 auto 10px auto;
    background-color: #f36381;
    text-align: center;
    font-weight: bold;
    color: #fff;
    padding: 9px 10px;
    font-size: 118%;
    letter-spacing: 1px;
}

.ageChecktxtarea {
    line-height: 160%;
    text-align: center;
    padding: 10px;
}

.ageChecktxtarea a {
    text-decoration: none;
}

.ageChecktxtarea a:hover {
    text-decoration: underline;
}

.ageCheckimg {
    text-align: center;
    margin: 0 auto 10px auto;
    width: 100px;
}

.ageCheckbtnarea {
    text-align: center;
    margin: 10px auto 0 auto;
    padding: 10px;
    max-width: 600px;
}

a.ageChecktxtbtnleft {
    width: 45%;
    float: left;
    margin: 0 10% 0 0;
    padding: 10px 4px 10px 6px;
    background-color: #f36381;
    -webkit-border-radius: 4px; /* Safari,Google Chrome */
    -moz-border-radius: 4px; /* Firefox12まで */
    border-radius: 4px; /* Firefox13以降 */
    text-align: center;
    font-size: 165%;
    box-sizing: border-box;
    display: inline-block;
    color: #fff;
    box-shadow: 0 3px 0 #923d4e;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 3px;
}

a.ageChecktxtbtnleft:hover {
    box-shadow: 0 -3px 0 #923d4e;
    margin: 3px 10% -3px 0;
    text-decoration: none;
    color: #fff;
}

a.ageChecktxtbtnright {
    margin: 0;
    width: 45%;
    float: left;
    padding: 9px 3px;
    border: solid 1px #8c8b8c;
    -webkit-border-radius: 4px; /* Safari,Google Chrome */
    -moz-border-radius: 4px; /* Firefox12まで */
    border-radius: 4px; /* Firefox13以降 */
    text-align: center;
    font-size: 110%;
    box-sizing: border-box;
    display: inline-block;
    box-shadow: 0 3px 0 #8c8b8c;
    text-decoration: none;
    cursor: pointer;
}

a.ageChecktxtbtnright:hover {
    box-shadow: 0 -3px 0 #8c8b8c;
    margin: 3px 0 -3px 0;
    text-decoration: none;
}

.ageChecktxtsafe {
    color: #e40007;
}

.ageChecktxtsafe a {
    color: #e40007;
    text-decoration: underline;
}

.ageChecktxtsafe a:hover {
    color: #e40007;
    text-decoration: none;
}

.ageChecktxtsafedetail {
    margin-top: 20px;
}

.ageChecktxtsafedetailInner {
    position: relative;
    display: inline-block;
    padding: 15px 20px;
    width: 100%;
    text-align: left;
    background: #efefeb;
    z-index: 0;
    box-sizing: border-box;
}

.ageChecktxtsafedetailInner:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 21%;
    margin-left: -10px;
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 17px 10px 17px;
    border-color: transparent transparent #efefeb transparent;
}

.ageChecktxtsafedetailInner a {
    text-decoration: underline;
}

.ageChecktxtsafedetailInner a:hover {
    text-decoration: none;
}

body.newuser .titleWrap h1, #newuser .titleWrap h1 {
    background-color: #1d3850;
}

body#newuser #sortable-rich .ui-sortable-placeholder {
    background-color: #7bc8ce;
}

a.btn-schedule-reserved {
    background: url(/louis/pc/img/common/btn-rect-reservation.png) no-repeat 0 -56px;
    width: 29px;
    height: 28px;
    display: block;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

a.btn-schedule-reservation {
    background: url(/louis/pc/img/common/btn-rect-reservation.png) no-repeat 0 0;
    width: 29px;
    height: 28px;
    display: block;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

a.btn-schedule-reservation:hover {
    background-position: 0 -28px;
}

.label-set {
    position: absolute;
    top: -8px;
    right: -5px;
    background: url(/louis/pc/img/common/icon-set.png) no-repeat 0 0;
    width: 42px;
    height: 39px;
    text-indent: -9999px;
    overflow: hidden;
    z-index: 70;
}

/* ========================
 * セット売り
 * ===================== */
.book-set {
    position: absolute;
    top: -6px;
    right: -8px;
    background: url(/louis/pc/img/common/icon-detail-set.png) no-repeat 0 0;
    width: 62px;
    height: 56px;
    text-indent: -9999px;
    overflow: hidden;
    z-index: 70;
}

.bookImgWrap .book-set {
    right: -7px;
}

@import '../../../bob/scss/pc/module/common/shelf-icon';

// コインアップアイコンの位置調整
.flagPoint.coinup {
    &-level1 {
        top: 103px;
    }

    &-level2 {
        top: 93px;
    }

    &-level3 {
        top: 83px;
    }
}

.bookItem .bookSubscription {
    position: absolute;
    z-index: 99;
    color: #FFF;
    text-align: center;
    background-image: url(/anne/img/common/icon-sbsc.svg);
    // 左上に使うアイコンなので、とりあえず左上寄せに
    background-size: 48px 20px;
    width: 48px;
    height: 20px;
}
