@charset "UTF-8";

/* ========================
 * 棚用コインアップアイコン（段階表示対応）
 * ===================== */
.book-item .coinup, // 未購入リスト
.sequels-slider-inner .slider-item .coinup,// 未購入リスト
.bi_checklist-content_unit .coinup,// checkリスト
.bookItem .coinup, // 検索結果
.next-book-thumbnail .img-book .icon-coin .coinup, // viewer最終ページ
.product-img .bw_icon-coin .coinup, // シリーズ詳細ページ
.product-rotate-item .bw_icon-coin .coinup, // シリーズ詳細ページ
.detail-section-contents .series-all .coinup,
.cmnShelf-item .coinup // bobの汎用棚アイテム
{
  position: absolute;
  right: -5px;
  overflow: hidden;
  z-index: 40;
  font-size: 0;
  &-level1 {
    background: url(/bob/pc/img/flag-coinup-level1.png) no-repeat 0 0;
    width: 20px;
    height: 58px;
  }
  &-level2 {
    background: url(/bob/pc/img/flag-coinup-level2.png) no-repeat 0 0;
    width: 20px;
    height: 68px;
  }
  &-level3 {
    background: url(/bob/pc/img/flag-coinup-level3.png) no-repeat 0 0;
    width: 20px;
    height: 78px;
  }
}
