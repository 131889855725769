// footer 付近に配置するクーポン
@import "../../../../../anne/scss/sp/atoms/z-index";

@keyframes couponOpen {
    from {
        opacity: 0;
        transform: scale(0, 0);
    }
    to {
        opacity: 1;
        transform: scale(1, 1);
    }
}

.footer-coupon {
    position: fixed;
    bottom: 100px;
    right: 0;
    z-index: $footer-floating-box-z-index;
    transition: all 0.3s;
    opacity: 0;
    display: none;

    &.on {
        display: block;
        opacity: 1;
    }

    .coupon-ico {
        background: #3f2b2a;
        color: #FFF;
        border-radius: 50%;

        .ico {
            display: block;
            text-align: center;
        }
    }

    .coupon-closed {
        position: relative;
        transition: all 0.5s;
        opacity: 0;
        display: none;
        background-color: #fff;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        .coupon-btn {
            background-color: #ffe746;
            width: 46px;
            height: 150px;
            padding: 10px;
            display: block;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            box-shadow: 1px 1px 8px rgba(64, 64, 64, 0.25);
            box-sizing: border-box;
        }

        &.active {
            display: block;
            opacity: 1;
        }

        .coupon-ico {
            height: 26px;
            width: 26px;

            .ico {
                padding-top: 5px;
                font-size: 16px;
            }
        }

        .coupon-txt {
            color: #ff4479;
            font-size: 18px;
            font-weight: bold;
            padding: 5px 0 0 4px;
            line-height: 1.1;
            display: table-cell;
            width: 1rem;
        }

        a:hover {
            opacity: 0.6;
            text-decoration: none;
        }
    }

    .coupon-opening {
        position: relative;
        margin: 0 20px 0 0;
        background-color: #fff;
        border-radius: 10px;
        display: none;
        box-shadow: 2px 2px 8px rgba(64, 64, 64, 0.25);
        transform-origin: right bottom;

        &.active {
            display: block;
            animation: couponOpen 0.5s ease-in-out 0s 1;
        }

        &.active.open {
            animation: couponOpen 0.2s ease-in-out 0s 1;
        }

        .coupon-btn {
            font-size: 22px;
            position: absolute;
            right: -15px;
            top: -15px;
            width: 40px;
            height: 40px;
            z-index: $footer-coupon-button-z-index;

            a:hover {
                opacity: 0.6;
                text-decoration: none;
            }
        }

        .coupon-area {
            a {
                display: block;
            }

            a:hover {
                opacity: 0.6;
                text-decoration: none;
            }
        }

        .coupon-ico {
            display: block;
            width: 30px;
            height: 30px;
            margin: 5px auto 0;
            text-align: center;

            .ico {
                padding-top: 5px;
                font-size: 22px;
            }
        }

        img {
            width: 200px;
            border-radius: 10px;
            vertical-align: bottom;
        }
    }
}

// PC用　画面横に設置されるお得情報
.side-deals {
    display: block;
    position: fixed;
    bottom: 10px;
    left: 0;
    opacity: 1;
    z-index: $footer-floating-box-z-index;

    a:link, a:hover {
        text-decoration: none;
    }

    .deals-ico {
        background: #3f2b2a;
        color: #FFF;
        border-radius: 50%;

        .ico {
            display: block;
            text-align: center;
        }
    }

    .side-deals-wrap {
        position: relative;
        margin-left: -140px;

        &.active {
            transition: 0.5s;
            transform: translateX(140px);

            .deals-ico {
                .ico {
                    transform: rotateZ(180deg);
                    padding: 0 0 5px 2px;
                }
            }
        }

        .deals-btn {
            position: absolute;
            bottom: 0;
            right: -48px;
            background-color: #ffe746;
            width: 48px;
            height: 54px;
            padding: 8px 0 0 5px;
            display: block;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-left: 1px solid #fff4a9;
            box-sizing: border-box;
        }

        .deals-ico {
            height: 26px;
            width: 26px;
            margin: 0 0 3px 4px;

            .ico {
                padding: 5px 0 0 3px;
                font-size: 16px;
            }
        }

        .deals-txt {
            color: #ff4479;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.1;
        }

        .deals-box {
            background: #ffce38;
            padding: 15px;
            width: 140px;
            box-sizing: border-box;

            .deals-list {
                width: 100%;
                margin-bottom: 10px;
                background: #fff;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    display: block;

                    &:hover {
                        opacity: 0.6;
                    }
                }

                img {
                    vertical-align: bottom;
                    width: 100%;
                }

                .deals-bdr {
                    border: 2px solid #fff;
                    box-sizing: border-box;
                }
            }
        }
    }
}
