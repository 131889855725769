@charset "UTF-8";
@import "../foundation/variable";
@import "../../../anne/scss/sp/templates/layout/_font-variable.scss";

.header-inner--top:after, .header-link:after, .gNav-sub-link:after {
    display: block;
    content: '';
    clear: both;
}

/* ========================
 * 共通ヘッダー
 * ===================== */
.header {
    font-family: $base-font-family;
    min-width: 1080px;
}

.header-inner {
    padding: 0 11px;
    max-width: 1500px;
    margin: 0 auto;
    box-sizing: border-box;
}

.header-inner--middle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.header-inner--bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 5px;
}

.header-top {
    background-color: #eef0f2;
    padding: 6px 0;
}

.header-shoulder {
    float: left;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 1px;
    font-weight: normal;
    margin: 0;
}

.header-link {
    float: right;
    font-size: 11px;
    font-size: 1.1rem;
    padding-top: 1px;
    margin: 0;
}

.header-link-item {
    float: left;
    margin-left: 5px;
    border-left: 1px solid #1d3850;
    padding-left: 6px;
    letter-spacing: 1px;
    line-height: 1;
}

.header-link-item:first-child {
    margin-left: 0;
    border-left: 0;
}

// 文言変更した際に長くなったので一部だけでも小さくする
.header-link-item .small {
    font-size: 9px;
}

.header-middle {
    background-color: #fff;
    padding: 6px 0 10px;
}

.header-logo {
    margin-right: 20px;

    a {
        display: block;
        width: 244px;
        height: 48px;
        font-size: 0;
        background: #fff url(/anne/img/pc/logo_bookwalker_large.svg) no-repeat;
        background-size: contain;
    }
}

.header-search {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 10px;
}

.header-search-content {
    position: relative;
}

.header-search input {
    box-sizing: border-box;
    border-radius: 5px;
    padding: 6px 62px 5px 103px;
    height: 34px;
    width: 100%;
    font-size: 14px;
    font-size: 1.4rem;
    border: 1px solid #aeaeae;
}

.header-search-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 66px;
    height: 34px;
    border-radius: 0 5px 5px 0;
    background: #1d3850;
    border: 0;
    outline: 0;
    font-size: 11px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #fff;
    padding: 5px 5px 5px 23px;
}

.header-search-btn:hover {
    opacity: .7;
}

.header-search-btn .ico-search {
    color: #fff;
    position: absolute;
    left: 8px;
    top: 10px;
    font-size: 15px;
    font-size: 1.5rem;
}

.selectStyle-select {
    position: absolute;
    left: 1px;
    top: 1px;
    box-sizing: border-box;
    padding: 9px 10px 8px 10px;
    width: 94px;
    height: 32px;
    font-size: 11px;
    font-size: 1.1rem;
    opacity: 0;
    z-index: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.selectStyle-select:hover {
    opacity: 0;
}

.selectStyle-select:hover + .selectStyle-btn {
    opacity: .7;
}

.selectStyle-select option {
    font-size: 12px;
    padding: 5px 3px;
    background-color: #eef0f2;
    color: #2f2f2f;
}

.selectStyle-btn {
    position: absolute;
    left: 1px;
    top: 1px;
    box-sizing: border-box;
    border-right: 1px solid #aeaeae;
    border-radius: 5px 0 0 5px;
    padding: 9px 10px 8px 10px;
    width: 94px;
    height: 32px;
    font-size: 11px;
    font-size: 1.1rem;
    font-weight: bold;
}

.otona-search .selectStyle-btn {
    padding: 10px 10px 8px 7px;
    font-size: 10px;
    font-size: 1rem;
}

.selectStyle-btn:hover {
    opacity: .7;
}

.selectStyle-btn::after {
    position: absolute;
    right: 7px;
    top: 50%;
    content: '';
    margin-top: -2px;
    border-width: 6px 3px;
    border-style: solid;
    border-color: #333131 transparent transparent;
}

.header-menu {
    margin: 0;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

%menu-item-inline-block {
    display: inline-block;
    height: 34px;
    font-size: 10px;
    font-size: 1rem;
    line-height: 1;
    padding-top: 24px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    position: relative;
    color: #495c73;
    font-weight: bold;
    white-space: nowrap;
    overflow: visible;
}

.header-menu-rank--link {
    &:hover {
        opacity: 0.7;
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }
}

.header-menu--left{
    display: flex;
    align-items: flex-start;
    margin-right: 10px;

    p{
         margin: 0;
    }
    .iconRank{
        display: block;
        margin-right: 4px;
        text-align: center;

        &:before {
            width: 30px;
            height: 32px;
            background: url(/louis/sp/img/reading-note/common/sprite-rank-sp.png) no-repeat 0 0;
            background-size: 40px auto;
            content: '';
            display: inline-block;
        }

        &.noRank:before {
            content: "";
            background: url(/anne/img/common/header/icon_norank.svg) no-repeat center;
            margin: 1.5px 0;
        }

        &.joker:before {
            background-position: 50% -317px;
        }

        &.ace:before {
            background-position: 50% -284px;
        }

        &.king:before {
            background-position: 50% -248px;
        }

        &.queen:before {
            background-position: 50% -210px;
        }

        &.jack:before {
            background-position: 50% -176px;
        }

        &.regular:before {
            background-position: 50% -145px;
        }

        &.light:before {
            background-position: 50% -114px;
        }
    }

    .header-menu-balance {
        align-self: center;
        text-align: right;
        .balanceCoin {
            color: #2f2f2f;
            font-size: 10px;
            line-height: 1;
        }

        .balanceCoin-digit {
            display: block;
            font-size: 16px;
            font-weight: bold;
            line-height: 1;
        }
    }

    .header-menu-expire {
        position: relative;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        margin-left: 14px;
        padding: 3px 1px 2px;
        text-align: center;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -7px;
            width: 7px;
            height: 11px;
            background: url(/anne/img/common/icon_header_balloon.svg) no-repeat center;
            background-size: contain;
        }
        .expireCoin {
            color: #e40007;
            font-size: 10px;
            font-weight: bold;
            line-height: 1.3;
        }
        .expireCoin-month {
            color: #2f2f2f;
            font-size: 10px;
            font-weight: 400;
            line-height: 1.1;
            transform: scale(0.8);
        }
        span{
            display: block;
        }
    }
}

.header-menu--logined .header-menu-item > a {
    @extend %menu-item-inline-block
}

.header-menu--logined .header-menu-item > a:hover {
    opacity: .7;
}

.header-menu-item-history {
    margin-right: 15px;

    .ico-history {
        font-size: 22px;
        font-size: 2.2rem;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -11px;
        color: #495c73;
    }

    > a {
        @extend %menu-item-inline-block;
    }
}

.header-menu--unlogin {
    .bw_btn {
        &-login {
            width: 145px;
            height: 34px;
            line-height: 32px;
            text-align: center;
            font-size: 110%;

            .ico-loginout {
                font-size: 1.8rem;
            }

            &:hover {
                padding: 0;
            }
        }

        &-primary {
            width: 145px;
            height: 34px;
            line-height: 32px;
            text-align: center;

            .ico-member {
                top: 0;
                font-size: 1.5rem;
            }
        }

        span {
            vertical-align: middle;
        }

        .ico {
            padding-right: 10px;

            &-txt {
                font-weight: bold;
            }
        }
    }
    .header-menu--unlogin--login {
        margin-right: 10px;
    }
}

@mixin header-menu-item-ico {
    font-size: 22px;
    font-size: 2.2rem;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -11px;
    color: #495c73;
}

.header-menu-item {
    height: 34px;
    &--cart {
        margin-left: 30px;
    }

    &--message {
        margin-left: 25px;
        position: relative;

        .header-menu-number {
            right: -7px;
        }
    }

    &--readingNote {
        margin-left: 20px;
    }

    &--mypage {
        margin-left: 10px;
    }

    .ico-check,
    .ico-cart,
    .ico-cart-in,
    .ico-mail,
    .ico-readingbook,
    .ico-cogs,
    .ico-sp-menu {
        @include header-menu-item-ico;
    }
}

// 未ログインのカート
.header-menu-item-nologin-cart {
    margin-right: 25px;
    > a {
        @extend %menu-item-inline-block;
    }

    .ico-cart {
        @include header-menu-item-ico;
    }
}

.header-menu-item--menu-icon {
    margin-left: 10px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 0 0 #ccc;
    border-radius: 4px;
    padding: 3px 6px;
    margin-top: -7px;
    position: relative;
    background: #fff;
    z-index: $hover-menu-z-index;
    cursor: pointer;

    > span {
        @extend %menu-item-inline-block;

        .ico-sp-menu {
            font-size: 22px;
            font-size: 2.2rem;

            font-size: 22px;
            font-size: 2.2rem;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -11px;
            color: #495c73;
        }
    }
}


.header-menu-number {
    position: absolute;
    right: -15px;
    top: -7px;
    border-radius: 9px;
    height: 18px;
    font-size: 10px;
    font-size: 1rem;
    line-height: 18px;
    text-align: center;
    color: #fff;
    padding: 0 6px;
    background-color: #0797d7;
}

.header-menu-message {
    position: absolute;
    top: 0;
    right: 0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    width: 310px;
    background: #efefeb;
    z-index: 2000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.17);
}

.header-menu-message-txt {
    margin: 0;
    font-size: 12px;
    font-size: 1.2rem;
}

.header-menu-message-txt a {
    color: #495c73;
    font-size: 12px;
    font-size: 1.2rem;
    text-decoration: underline;
}

.header-menu-message-number {
    font-size: 16px !important;
    margin: 0 5px;
}

.message-balloon-content {
    display: none;
}

.message-balloon-triangle {
    position: absolute;
    left: 50%;
    top: 23px;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #efefeb;
    z-index: 2000;
    display: none;
}

.header-bottom {
    border-bottom: 5px solid #0094d6;
    background-color: #fff;
}
#sougou .header-bottom {
    border-bottom: 5px solid #1d3850;
    background-color: #fff;
}
.header-category {
    width: 91px;
    display: flex;
    align-items: flex-end;
}

.gNav-sub-btn {
    margin: 0;
}

.gNav-sub-btn a {
    background: -webkit-linear-gradient(top, #fff 51%, #f9f9f9 100%);
    background: linear-gradient(to bottom, #fff 51%, #f9f9f9 100%);
    border: 1px solid #ccc;
    border-bottom: 0;
    width: 91px;
    height: 33px;
    box-sizing: border-box;
    font-size: 13px;
    font-size: 1.3rem;
    position: relative;
    display: block;
    padding: 3px 0 0 14px;
    line-height: 1.3;
    border-radius: 4px 4px 0 0;
    text-decoration: none;
    font-weight: bold;
}

.gNav-sub-btn a:hover, .gNav-sub-btn a.is-active {
    background: $base-color;
    color: #fff;
}

.gNav-sub-btn-small {
    font-size: 9px;
    font-size: .9rem;
    display: block;
}

.gNav-sub-btn .ico-angle-down {
    position: absolute;
    top: 13px;
    right: 5px;
    font-size: 13px;
    font-size: 1.3rem;
}

.gNav-sub-content {
    display: none;
    position: absolute;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.17);
    z-index: 999;
}

.gNav-sub-tab {
    display: table-cell;
    vertical-align: top;
    width: 91px;
    background-color: #e2e3e7;
}

.gNav-sub-tab-item {
    border-bottom: 1px solid #e2e3e7;
}

.gNav-sub-tab-item a {
    width: 75px;
    background-color: #fff;
    color: $base-color;
    font-size: 13px;
    font-size: 1.3rem;
    font-weight: bold;
    display: block;
    padding: 12px 0 11px 16px;
    text-decoration: none;
    position: relative;
    box-sizing: content-box;
}

.gNav-sub-tab-item a.is-active {
    background-color: #0594d4;
    color: #fff;
}

.gNav-sub-tab-item a.is-active::before {
    position: absolute;
    z-index: 5;
    right: -1px;
    top: 50%;
    content: '';
    margin-top: -3px;
    width: 14px;
    height: 14px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    -webkit-transform: rotate(57deg) skewY(-29deg) translateY(-50%);
    transform: rotate(57deg) skewY(-29deg) translateY(-50%);
}

.gNav-sub-tab-item:first-child {
    border-top: 0;
}

.gNav-sub-tab-item a:hover {
    background-color: $base-color;
    color: #fff;
}

.gNav-sub-tab-panel {
    display: table-cell;
    vertical-align: top;
    padding: 30px 20px 45px;
    background-color: #fff;
    position: relative;
    box-shadow: -2px 1px 3px rgba(0, 0, 0, 0.25);
}

.gNav-sub-tab-panel::before {
    content: "";
    width: 20px;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    position: absolute;
    z-index: 6;
}

.gNav-sub-link-list {
    margin-left: 38px;
    float: left;
}

.gNav-sub-link-list:first-child {
    margin-left: 0;
}

.gNav-sub-link-item {
    margin-top: 10px;
}

.gNav-sub-link-item:first-child {
    margin-top: 0;
}

.gNav-sub-link-item a {
    color: #1d3850;
}

.gNav-sub-list-link {
    position: absolute;
    right: 10px;
    bottom: 10px;
    margin: 0;
    font-weight: bold;
}

.gNav-tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 0;
}

.gNav-tab-inner {
    padding: 1px 1px 0;
    border-radius: 4px 4px 0 0;
    border: 1px solid #ccc;
    border-bottom: 0;
}

.gNav-tab-inner:hover .gNav-tab-sub {
    display: block;
}

.gNav-tab-head {
    height: 35px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.3;
    border-radius: 4px 4px 0 0;
    position: relative;
    padding: 4px 0 4px 9px;
    cursor: pointer;

    .gNav-tab-head__inner {
         .gNav-tab-head__inner-break {
             display: inline-block;
         }
    }
}

.gNav-tab-item {
    width: 14%;
    padding-right: 5px;
    position: relative;
}

.gNav-tab-item a {
    text-decoration: none;
}

.gNav-tab-item--shinjuku .gNav-tab-head::before, .gNav-tab-item--yaesu .gNav-tab-head::before, .gNav-tab-item--akihabara .gNav-tab-head::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 9px;
    height: 100%;
    border-radius: 4px 0 0 0;
}

.gNav-tab-item--newuser .gNav-tab-head::before,
.gNav-tab-item--otona .gNav-tab-head::before,
.gNav-tab-item--goods .gNav-tab-head::before,
.gNav-tab-item--ikebukuro .gNav-tab-head::before,
.gNav-tab-item--ikebukuro-tl .gNav-tab-head::before,
.gNav-tab-item--free .gNav-tab-head::before,
.gNav-tab-item--sbsc .gNav-tab-head::before,
.gNav-tab-item--warensai .gNav-tab-head::before,
.gNav-tab-item--top .gNav-tab-head::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    border-radius: 4px 0 0 0;
}

.gNav-tab-item--otona,
.gNav-tab-item--ikebukuro,
.gNav-tab-item--ikebukuro-tl,
.gNav-tab-item--goods,
.gNav-tab-item--free {
    width: 9%;
}

.gNav-tab-item--newuser,
.gNav-tab-item--other {
    width: 15%;
}

.gNav-tab-item--newuser .gNav-tab-head,
.gNav-tab-item--otona .gNav-tab-head,
.gNav-tab-item--goods .gNav-tab-head,
.gNav-tab-item--other .gNav-tab-head,
.gNav-tab-item--ikebukuro .gNav-tab-head,
.gNav-tab-item--ikebukuro-tl .gNav-tab-head,
.gNav-tab-item--free .gNav-tab-head {
    height: 31px;
}

.gNav-tab-item--newuser .gNav-tab-sub,
.gNav-tab-item--otona .gNav-tab-sub,
.gNav-tab-item--goods .gNav-tab-sub,
.gNav-tab-item--other .gNav-tab-sub,
.gNav-tab-item--ikebukuro .gNav-tab-sub,
.gNav-tab-item--ikebukuro-tl .gNav-tab-sub,
.gNav-tab-item--free .gNav-tab-sub {
    top: 33px;
    z-index: 205;
}

.gNav-tab-item--free.gNav-tab-item--free--active .gNav-tab-inner {
    background: #E84949;
    border-color: #E84949;
}

.gNav-tab-item--free.gNav-tab-item--free--active .gNav-tab-head {
    padding-left: 0;
    background: #E84949;
    color: #fff;
}

.gNav-tab-item--free.gNav-tab-item--free--active .gNav-tab-head::before,
.gNav-tab-item--free.gNav-tab-item--free--active:hover .gNav-tab-head::before {
    background: #E84949;
}

.gNav-tab-item--free {
    .gNav-tab-head {
        padding-left: 6px;
    }

    .gNav-tab-head::before {
        background: -webkit-linear-gradient(top, #ff7777 49%, #E84949 50%);
        background: linear-gradient(to bottom, #ff7777 49%, #E84949 50%);
    }

    &:hover .gNav-tab-head::before {
        background: -webkit-linear-gradient(top, #E84949 49%, #ff7777 50%);
        background: linear-gradient(to bottom, #E84949 49%, #ff7777 50%);
    }

    .gNav-tab-sub-list {
        background: #ff7777;
    }

    .gNav-tab-sub-item > a {
        border-color: #E84949;
        color: #E84949;
    }

    .gNav-tab-sub-item:first-child > a::before {
        border-left-color: #E84949;
    }

    .gNav-tab-sub-item:first-child > a::after {
        border-left-color: #ff7777;
    }
}

.gNav-tab-item--sbsc {
    .gNav-tab-head::before {
        background: -webkit-linear-gradient(top, #68CA7F 49%, #009844 50%);
        background: linear-gradient(to bottom, #68CA7F 49%, #009844 50%);
    }

    &:hover .gNav-tab-head::before {
        background: -webkit-linear-gradient(top, #009844 49%, #68CA7F 50%);
        background: linear-gradient(to bottom, #009844 49%, #68CA7F 50%);
    }
}

.gNav-tab-item--warensai {
    .gNav-tab-head::before {
        background: -webkit-linear-gradient(top, #00A4E5 49%, #1278A1 50%);
        background: linear-gradient(to bottom, #00A4E5 49%, #1278A1 50%);
    }

    &:hover .gNav-tab-head::before {
        background: -webkit-linear-gradient(top, #1278A1 49%, #00A4E5 50%);
        background: linear-gradient(to bottom, #1278A1 49%, #00A4E5 50%);
    }
}

.gNav-tab-item--top.gNav-tab-item--top--active .gNav-tab-inner {
    background: #1d3850;
    border-color: #1d3850;
}

.gNav-tab-item--top.gNav-tab-item--top--active .gNav-tab-head {
    padding-left: 0;
    background: #1d3850;
    color: #fff;
}

.gNav-tab-item--top.gNav-tab-item--top--active .gNav-tab-head::before,
.gNav-tab-item--top.gNav-tab-item--top--active:hover .gNav-tab-head::before {
    background: #1d3850;
}
.gNav-tab-item--top {
    .gNav-tab-head::before {
        background: -webkit-linear-gradient(top, #67a7ff 49%, #1d3850 50%);
        background: linear-gradient(to bottom, #67a7ff 49%, #1d3850 50%);
    }
    
    &:hover .gNav-tab-head::before {
        background: -webkit-linear-gradient(top, #1d3850 49%, #67a7ff 50%);
        background: linear-gradient(to bottom, #1d3850 49%, #67a7ff 50%);
    }
    
    .gNav-tab-sub-list {
        background: #1d3850;
    }
    
    .gNav-tab-sub-item > a {
        border-color: #67a7ff;
        color: #67a7ff;
    }
    
    .gNav-tab-sub-item:first-child > a::before {
        border-left-color: #67a7ff;
    }
    
    .gNav-tab-sub-item:first-child > a::after {
        border-left-color: #1d3850;
    }
}


.gNav-tab-item--shinjuku .gNav-tab-head {
    background: -webkit-linear-gradient(top, #ddf3fb 49%, #cfeef9 50%);
    background: linear-gradient(to bottom, #ddf3fb 49%, #cfeef9 50%);
}

.gNav-tab-item--shinjuku .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #27b1d6 49%, #0094d6 50%);
    background: linear-gradient(to bottom, #27b1d6 49%, #0094d6 50%);
}

.gNav-tab-item--shinjuku:hover .gNav-tab-head {
    background: -webkit-linear-gradient(top, #cfeef9 49%, #ddf3fb 50%);
    background: linear-gradient(to bottom, #cfeef9 49%, #ddf3fb 50%);
}

.gNav-tab-item--shinjuku:hover .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #0094d6 49%, #27b1d6 50%);
    background: linear-gradient(to bottom, #0094d6 49%, #27b1d6 50%);
}

.gNav-tab-item--shinjuku .gNav-tab-sub-list {
    background: #7fc9ea;
}

.gNav-tab-item--shinjuku .gNav-tab-sub-item > a {
    border-color: #0094d6;
    color: #0094d6;
}

.gNav-tab-item--shinjuku .gNav-tab-sub-item:first-child > a::before {
    border-left-color: #0094d6;
}

.gNav-tab-item--shinjuku .gNav-tab-sub-item:first-child > a::after {
    border-left-color: #27b1d6;
}

.gNav-tab-item--yaesu .gNav-tab-head {
    background: -webkit-linear-gradient(top, #f6dede 49%, #f2d1d1 50%);
    background: linear-gradient(to bottom, #f6dede 49%, #f2d1d1 50%);
}

.gNav-tab-item--yaesu .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #893f3f 49%, #5a1818 50%);
    background: linear-gradient(to bottom, #893f3f 49%, #5a1818 50%);
}

.gNav-tab-item--yaesu:hover .gNav-tab-head {
    background: -webkit-linear-gradient(top, #f2d1d1 49%, #f6dede 50%);
    background: linear-gradient(to bottom, #f2d1d1 49%, #f6dede 50%);
}

.gNav-tab-item--yaesu:hover .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #5a1818 49%, #893f3f 50%);
    background: linear-gradient(to bottom, #5a1818 49%, #893f3f 50%);
}

.gNav-tab-item--yaesu .gNav-tab-sub-list {
    background: #a48485;
}

.gNav-tab-item--yaesu .gNav-tab-sub-item > a {
    border-color: #5a1818;
    color: #5a1818;
}

.gNav-tab-item--yaesu .gNav-tab-sub-item:first-child > a::before {
    border-left-color: #5a1818;
}

.gNav-tab-item--yaesu .gNav-tab-sub-item:first-child > a::after {
    border-left-color: #893f3f;
}

.gNav-tab-item--akihabara .gNav-tab-head {
    background: -webkit-linear-gradient(top, #ffeed0 49%, #ffe7bd 50%);
    background: linear-gradient(to bottom, #ffeed0 49%, #ffe7bd 50%);
}

.gNav-tab-item--akihabara .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #ffaf50 49%, #ffa91c 50%);
    background: linear-gradient(to bottom, #ffaf50 49%, #ffa91c 50%);
}

.gNav-tab-item--akihabara:hover .gNav-tab-head {
    background: -webkit-linear-gradient(top, #ffe7bd 49%, #ffeed0 50%);
    background: linear-gradient(to bottom, #ffe7bd 49%, #ffeed0 50%);
}

.gNav-tab-item--akihabara:hover .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #ffa91c 49%, #ffaf50 50%);
    background: linear-gradient(to bottom, #ffa91c 49%, #ffaf50 50%);
}

.gNav-tab-item--akihabara .gNav-tab-sub-list {
    background: #ffd48d;
}

.gNav-tab-item--akihabara .gNav-tab-sub-item > a {
    border-color: #ffa91c;
    color: #d98700;
}

.gNav-tab-item--akihabara .gNav-tab-sub-item:first-child > a::before {
    border-left-color: #ffa91c;
}

.gNav-tab-item--akihabara .gNav-tab-sub-item:first-child > a::after {
    border-left-color: #ffaf50;
}

.gNav-tab-item--ikebukuro .gNav-tab-head {
    padding-left: 6px;
}

.gNav-tab-item--ikebukuro .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #ff91d9 49%, #ff6ed9 50%);
    background: linear-gradient(to bottom, #ff91d9 49%, #ff6ed9 50%);
}

.gNav-tab-item--ikebukuro:hover .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #ff6ed9 49%, #ff91d9 50%);
    background: linear-gradient(to bottom, #ff6ed9 49%, #ff91d9 50%);
}

.gNav-tab-item--ikebukuro .gNav-tab-sub-list {
    background: #f4bcde;
}

.gNav-tab-item--ikebukuro .gNav-tab-sub-item > a {
    border-color: #f78acd;
    color: #d6469e;
}

.gNav-tab-item--ikebukuro .gNav-tab-sub-item:first-child > a::before {
    border-left-color: #f78acd;
}

.gNav-tab-item--ikebukuro .gNav-tab-sub-item:first-child > a::after {
    border-left-color: #ff91d9;
}

.gNav-tab-item--ikebukuro-tl .gNav-tab-head {
    padding-left: 6px;
}

.gNav-tab-item--ikebukuro-tl .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #c793f0 49%, #b482d8 50%);
    background: linear-gradient(to bottom, #c793f0 49%, #b482d8 50%);
}

.gNav-tab-item--ikebukuro-tl:hover .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #b482d8 49%, #c793f0 50%);
    background: linear-gradient(to bottom, #b482d8 49%, #c793f0 50%);
}

.gNav-tab-item--ikebukuro-tl .gNav-tab-sub-list {
    background: #c793f0;
}

.gNav-tab-item--ikebukuro-tl .gNav-tab-sub-item > a {
    border-color: #b482d8;
    color: #a08c98;
}

.gNav-tab-item--ikebukuro-tl .gNav-tab-sub-item:first-child > a::before {
    border-left-color: #b482d8;
}

.gNav-tab-item--ikebukuro-tl .gNav-tab-sub-item:first-child > a::after {
    border-left-color: #c793f0;
}

.gNav-tab-item--newuser .gNav-tab-head {
    padding-left: 6px;
    background: -webkit-linear-gradient(top, #ffffff 49%, #fafafa 50%);
    background: linear-gradient(to bottom, #ffffff 49%, #fafafa 50%);
}

.gNav-tab-item--newuser .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #85d8de 49%, #7bc8ce 50%);
    background: linear-gradient(to bottom, #85d8de 49%, #7bc8ce 50%);
}

.gNav-tab-item--newuser:hover .gNav-tab-head {
    background: -webkit-linear-gradient(top, #fafafa 49%, #ffffff 50%);
    background: linear-gradient(to bottom, #fafafa 49%, #ffffff 50%);
}

.gNav-tab-item--newuser:hover .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #7bc8ce 49%, #85d8de 50%);
    background: linear-gradient(to bottom, #7bc8ce 49%, #85d8de 50%);
}

.gNav-tab-item--otona .gNav-tab-head {
    padding-left: 6px;
}

.gNav-tab-item--otona .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #ff7592 49%, #f36381 50%);
    background: linear-gradient(to bottom, #ff7592 49%, #f36381 50%);
}

.gNav-tab-item--otona:hover .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #f36381 49%, #ff7592 50%);
    background: linear-gradient(to bottom, #f36381 49%, #ff7592 50%);
}

.gNav-tab-item--otona .gNav-tab-sub-list {
    background: #f1a9b9;
}

.gNav-tab-item--otona .gNav-tab-sub-item > a {
    border-color: #f36381;
    color: #f36783;
}

.gNav-tab-item--otona .gNav-tab-sub-item:first-child > a::before {
    border-left-color: #f36381;
}

.gNav-tab-item--otona .gNav-tab-sub-item:first-child > a::after {
    border-left-color: #fa94a8;
}

.gNav-tab-item--goods .gNav-tab-head {
    padding-left: 6px;
    background: -webkit-linear-gradient(top, #ffffff 49%, #fafafa 50%);
    background: linear-gradient(to bottom, #ffffff 49%, #fafafa 50%);
}

.gNav-tab-item--goods .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #1460b5 49%, #013e8a 50%);
    background: linear-gradient(to bottom, #1460b5 49%, #013e8a 50%);
}

.gNav-tab-item--goods:hover .gNav-tab-head {
    background: -webkit-linear-gradient(top, #fafafa 49%, #ffffff 50%);
    background: linear-gradient(to bottom, #fafafa 49%, #ffffff 50%);
}

.gNav-tab-item--goods:hover .gNav-tab-head::before {
    background: -webkit-linear-gradient(top, #013e8a 49%, #1460b5 50%);
    background: linear-gradient(to bottom, #013e8a 49%, #1460b5 50%);
}

.gNav-tab-item--other .gNav-tab-head {
    background: -webkit-linear-gradient(top, #ffffff 49%, #fafafa 50%);
    background: linear-gradient(to bottom, #ffffff 49%, #fafafa 50%);
}

.gNav-tab-item--other:hover .gNav-tab-head {
    background: -webkit-linear-gradient(top, #fafafa 49%, #ffffff 50%);
    background: linear-gradient(to bottom, #fafafa 49%, #ffffff 50%);
}

.gNav-tab-sub {
    display: none;
    position: absolute;
    top: 37px;
    left: 0;
    z-index: 100;
}

.gNav-tab-sub-list {
    border-radius: 0 0 4px 4px;
    padding: 5px;
}

.gNav-tab-sub-item {
    margin-top: 4px;
}

.gNav-tab-sub-item:first-child {
    margin-top: 0;
}

.gNav-tab-sub-item > a {
    border: 2px solid;
    position: relative;
    background-color: white;
    padding: 11px 12px 11px 20px;
    white-space: nowrap;
    border-radius: 4px;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.4;
    display: block;
    min-width: 180px;
}

.gNav-tab-sub-item > a:hover {
    opacity: .7;
}

.gNav-tab > .gNav-tab-item:last-child .gNav-tab-sub a {
    min-width: 110px;
}

.gNav-tab-sub-item:first-child > a::before {
    content: "";
    width: 20px;
    height: 24px;
    box-sizing: border-box;
    border-width: 5px 10px 5px 10px;
    border-style: solid;
    border-color: transparent;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
}

.gNav-tab-sub-item:first-child > a:after {
    content: "";
    border-width: 5px 10px 5px 10px;
    border-style: solid;
    border-color: transparent;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
}

.gNav-tab-sub-item--under > a {
    padding-left: 32px;
}

.header-tab {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.header-after {
    padding: 7px 0 6px 0;
}

.header-keyword {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
}

.header-keyword-head {
    color: #fff;
    font-size: 9px;
    font-weight: bold;
    line-height: 18px;
    padding: 0 3px 0 7px;
    background-color: #0797d7;
    margin-right: 19px;
    position: relative;
}

.header-keyword-head span {
    position: relative;
    z-index: 10;
}

.header-keyword-head::after {
    content: "";
    position: absolute;
    top: 3px;
    right: -6px;
    width: 12px;
    height: 12px;
    background-color: #0797d7;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.header-keyword-item {
    height: 18px;
    margin-right: 5px;
}

.header-keyword-item a {
    display: inline-block;
    vertical-align: top;
    color: #1d3850;
    background-color: white;
    border-radius: 9px;
    font-size: 9px;
    font-weight: bold;
    line-height: 18px;
    padding: 0 9px;
    text-decoration: none;
}

.header-keyword-item a:hover {
    opacity: .7;
}

#shinjuku .gNav-tab-item--shinjuku .gNav-tab-head,
.shinjuku .gNav-tab-item--shinjuku .gNav-tab-head {
    padding-left: 0;
    background: #0094d6;
    color: #fff;
}

#shinjuku .gNav-tab-item--shinjuku .gNav-tab-head::before,
.shinjuku .gNav-tab-item--shinjuku .gNav-tab-head::before {
    content: none;
}

#shinjuku .gNav-tab-item--shinjuku .gNav-tab-inner,
.shinjuku .gNav-tab-item--shinjuku .gNav-tab-inner {
    background: #0094d6;
    border-color: #0094d6;
}

#newuser .gNav-tab-item--newuser.gNav-tab-st5--active .gNav-tab-head,
.newuser .gNav-tab-item--newuser.gNav-tab-st5--active .gNav-tab-head {
    padding-left: 0;
    background: #83cbcf;
    color: #fff;
}

#newuser .gNav-tab-item--newuser.gNav-tab-st5--active .gNav-tab-head::before,
.newuser .gNav-tab-item--newuser.gNav-tab-st5--active .gNav-tab-head::before {
    content: none;
}

#newuser .gNav-tab-item--newuser.gNav-tab-st5--active .gNav-tab-inner,
.newuser .gNav-tab-item--newuser.gNav-tab-st5--active .gNav-tab-inner {
    background: #83cbcf;
    border-color: #83cbcf;
}

#yaesu .header-bottom,
.yaesu .header-bottom {
    border-bottom: 5px solid #5e1f1f;
}

#yaesu .header-keyword-head,
.yaesu .header-keyword-head {
    background-color: #5a1818;
}

#yaesu .header-keyword-head::after {
    background-color: #5a1818;
}

#yaesu .gNav-tab-item--yaesu .gNav-tab-head,
.yaesu .gNav-tab-item--yaesu .gNav-tab-head {
    padding-left: 0;
    background: #5e1f1f;
    color: #fff;
}

#yaesu .gNav-tab-item--yaesu .gNav-tab-head::before,
.yaesu .gNav-tab-item--yaesu .gNav-tab-head::before {
    content: none;
}

#yaesu .gNav-tab-item--yaesu .gNav-tab-inner,
.yaesu .gNav-tab-item--yaesu .gNav-tab-inner {
    background: #5e1f1f;
    border-color: #5e1f1f;
}

#akihabara .header-bottom,
.akihabara .header-bottom {
    border-bottom: 5px solid #ffac23;
}

#akihabara .header-keyword-head,
.akihabara .header-keyword-head {
    background-color: #ffa91c;
}

#akihabara .header-keyword-head::after,
.akihabara .header-keyword-head::after {
    background-color: #ffa91c;
}

#akihabara .gNav-tab-item--akihabara .gNav-tab-head,
.akihabara .gNav-tab-item--akihabara .gNav-tab-head {
    padding-left: 0;
    background: #ffac23;
    color: #fff;
}

#akihabara .gNav-tab-item--akihabara .gNav-tab-head::before,
.akihabara .gNav-tab-item--akihabara .gNav-tab-head::before {
    content: none;
}

#akihabara .gNav-tab-item--akihabara .gNav-tab-inner,
.akihabara .gNav-tab-item--akihabara .gNav-tab-inner {
    background: #ffac23;
    border-color: #ffac23;
}

#ikebukuro .header-bottom,
.ikebukuro .header-bottom {
    border-bottom: 5px solid #f68dce;
}

#ikebukuro .header-keyword-head,
.ikebukuro .header-keyword-head {
    background-color: #fa89cb;
}

#ikebukuro .header-keyword-head::after,
.ikebukuro .header-keyword-head::after {
    background-color: #fa89cb;
}

#ikebukuro .gNav-tab-item--ikebukuro .gNav-tab-head,
.ikebukuro .gNav-tab-item--ikebukuro .gNav-tab-head {
    padding-left: 0;
    background: #f68dce;
    color: #fff;
}

#ikebukuro .gNav-tab-item--ikebukuro .gNav-tab-head::before,
.ikebukuro .gNav-tab-item--ikebukuro .gNav-tab-head::before {
    content: none;
}

#ikebukuro .gNav-tab-item--ikebukuro .gNav-tab-inner,
.ikebukuro .gNav-tab-item--ikebukuro .gNav-tab-inner {
    background: #f68dce;
    border-color: #f68dce;
}

#ikebukuro-tl .header-bottom,
.ikebukuro-tl .header-bottom {
    border-bottom: 5px solid #b482d8;
}

#ikebukuro-tl .header-keyword-head,
.ikebukuro-tl .header-keyword-head {
    background-color: #c793f0;
}

#ikebukuro-tl .header-keyword-head::after,
.ikebukuro-tl .header-keyword-head::after {
    background-color: #c793f0;
}

#ikebukuro-tl .gNav-tab-item--ikebukuro-tl .gNav-tab-head,
.ikebukuro-tl .gNav-tab-item--ikebukuro-tl .gNav-tab-head {
    padding-left: 0;
    background: #b482d8;
    color: #fff;
}

#ikebukuro-tl .gNav-tab-item--ikebukuro-tl .gNav-tab-head::before,
.ikebukuro-tl .gNav-tab-item--ikebukuro-tl .gNav-tab-head::before {
    content: none;
}

#ikebukuro-tl .gNav-tab-item--ikebukuro-tl .gNav-tab-inner,
.ikebukuro-tl .gNav-tab-item--ikebukuro-tl .gNav-tab-inner {
    background: #b482d8;
    border-color: #b482d8;
}

.header-menu-number {
    background-color: #e9137a;
}

#newuser .header-bottom,
.newuser .header-bottom {
    border-bottom: 5px solid #83cbcf;
}

#newuser .header-keyword-head,
.newuser .header-keyword-head {
    background-color: #82cbd1;
}

#newuser .header-keyword-head::after,
.newuser .header-keyword-head::after {
    background-color: #82cbd1;
}
#sougou .header-keyword-head,
.sougou .header-keyword-head {
    background-color: #1d3850;
}

#sougou .header-keyword-head::after,
.sougou .header-keyword-head::after {
    background-color: #1d3850;
}

#newuser .gNav-tab-item--newuser .gNav-tab-head::before,
.newuser .gNav-tab-item--newuser .gNav-tab-head::before {
}

#newuser .gNav-tab-item--newuser .gNav-tab-inner,
.newuser .gNav-tab-item--newuser .gNav-tab-inner {
    padding: 1px 1px 0;
    border-radius: 4px 4px 0 0;
    border: 1px solid #ccc;
    border-bottom: 0;
}

#otona .gNav-tab-item--otona .gNav-tab-head::before,
.otona .gNav-tab-item--otona .gNav-tab-head::before {
    content: none;
}

#otona .header-bottom,
.otona .header-bottom {
    border-bottom: 5px solid #f36684;
}

#otona .header-keyword-head,
.otona .header-keyword-head {
    background-color: #f26785;
}

#otona .header-keyword-head::after {
    background-color: #f26785;
}

#otona .gNav-tab-item--otona .gNav-tab-head,
.otona .gNav-tab-item--otona .gNav-tab-head {
    padding-left: 0;
    background: #f36684;
    color: #fff;
}

#otona .gNav-tab-item--otona .gNav-tab-head::before
.otona .gNav-tab-item--otona .gNav-tab-head::before {
    content: none;
}

#otona .gNav-tab-item--otona .gNav-tab-inner,
.otona .gNav-tab-item--otona .gNav-tab-inner {
    background: #f36684;
    border-color: #f36684;
}

#footSec, #footInner, #foot-bottom {
    background: #fff;
    min-width: 1080px;
}

/* sprite-rank */
.menu {
    &-rankBox {
        .rankStatus:before,
        span.bar-rank:before {
            background: url(https://c.bookwalker.jp/louis/sp/img/reading-note/common/sprite-rank-sp.png) no-repeat 0 0;
            background-size: 60px auto;
            content: '';
            display: inline-block;
        }

        .rankStatus {
            &:before {
                width: 50px;
                height: 49px;
            }

            &.joker:before {
                background-position: 50% -475px;
            }

            &.ace:before {
                background-position: 50% -429px;
            }

            &.king:before {
                background-position: 50% -374px;
            }

            &.queen:before {
                background-position: 50% -318px;
            }

            &.jack:before {
                background-position: 50% -267px;
            }

            &.regular:before {
                background-position: 50% -220px;
            }

            &.light:before {
                background-position: 50% -173px;
            }

            &.noRank:before {
                background: none;
                width: 0;
                height: 0;

                .rankStatusTxt {
                    padding-left: 0;
                }
            }
        }

        span.bar-rank {
            &:before {
                width: 30px;
                height: 27px;
            }

            &.joker:before {
                background-position: 50% -684px;
                vertical-align: -7px;
            }

            &.ace:before {
                background-position: 50% -658px;
                vertical-align: -7px;
            }

            &.king:before {
                background-position: 50% -631px;
                vertical-align: -7px;
            }

            &.queen:before {
                background-position: 50% -601px;
                vertical-align: -7px;
            }

            &.jack:before {
                background-position: 50% -574px;
                vertical-align: -7px;
            }

            &.regular:before {
                background-position: 50% -547px;
                vertical-align: -7px;
            }

            &.light:before {
                background-position: 50% -521px;
                vertical-align: -7px;
            }
        }
    }
}

/* module-link */
.bw_link-simple {
    display: block;
    position: relative;
    text-align: right;
    margin-top: 5px;
    padding-right: 20px;

    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        width: 6px;
        height: 6px;
        margin-top: -1px;
        border-right: 2px solid #929292;
        border-bottom: 2px solid #929292;
        -webkit-transform: translateY(-50%) rotate(-45deg);
        transform: translateY(-50%) rotate(-45deg);
    }
}

/* Side Menu Personal */
.pslCont {
    .side-ttl-menu {
        margin: -8px 0 0;
        font-size: 130%;
        font-weight: bold;
        border-bottom: 3px double #cbced1;
        padding: 10px;
    }

    a:link, a:visited, a:active, a:hover {
        text-decoration: none;
    }

    a:active, a:hover {
        opacity: .7;
    }

}

/* MENU rank area */
.menu {
    &-rankBox {
        .rankStatus {
            display: block;
            padding: 2px 8px 8px 8px;
            box-sizing: border-box;
            overflow: hidden;

            &:before {
                float: left;
                width: 50px;
            }

            &Txt {
                float: left;
                padding-left: 10px;
                box-sizing: border-box;

                .status {
                    &Text {
                        display: block;
                        text-align: left;
                        font-size: 125%;
                        font-weight: bold;
                        line-height: 35px;
                    }

                    &Coin {
                        display: block;
                        text-align: left;
                        line-height: 5px;

                        span {
                            color: #3385f5;
                            font-size: 105%;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .rankCondition {
            &:before,
            &:after {
                display: block;
                margin: 0 7px;
                content: '';
                border-top: 1px dotted #cbced1;
                border-right: none;
                border-bottom: none;
                border-left: none;
            }

            .condition {
                &Next {
                    text-align: right;
                    margin-top: 5px;
                    margin-bottom: -8px;

                    .statusCoin .coinEm {
                        color: #3385f5;
                        font-weight: bold;
                    }
                }

                &Chart {
                    &Box {
                        display: block;
                        margin: -8px 0 0;
                    }

                    overflow: hidden;
                    margin: 0 -5px 5px;
                    text-align: center;

                    span {
                        display: inline-block;
                    }

                    .status {
                        position: relative;
                        width: 65%;
                        width: -webkit-calc(100% - 70px);
                        width: -moz-calc(100% - 70px);
                        width: calc(100% - 70px);
                        height: 15px;
                        margin-top: 10px;
                        margin-left: -5px;
                        background: #fff;
                        border-radius: 4px;
                        border: 1px solid #cbced1;
                        box-sizing: border-box;

                        span {
                            position: absolute;
                            left: 0;
                            height: 13px;
                            background: #e9137a;
                        }
                    }
                }
            }

            .bw_link-simple {
                font-size: 90%;
                line-height: 30px;
            }

            .bw_link-exp {
                padding: 0 5px;

                .ico-help {
                    font-size: 95%;
                    font-weight: normal;
                    color: #1d3850;
                }
            }
        }

        .emp {
            font-weight: bold;
            font-size: 115%;
        }

        .conditionLimit {
            padding: 10px 8px 8px 8px;
            line-height: 1.5;

            .purchase {
                color: #e40007;
                font-weight: bold;

                span {
                    font-size: 110%;
                }

                .rank-purchase-yen {
                    font-size: 80%;
                    font-weight: normal;
                }

                .rank-purchase-tax {
                    font-size: 80%;
                    font-weight: normal;
                }
            }

            .newPurchase {
                font-weight: bold;
            }
        }
    }

    &-coinBox {
        padding: 10px 8px 8px 8px;

        .balance {
            &-coin {
                display: block;
                line-height: 1.5;

                .balanceTxt {
                    display: block;
                    font-size: 110%;
                    font-weight: bold;
                    color: #1d3850;

                    span {
                        font-size: 110%;
                        font-weight: bold;
                    }
                    .balanceTxt-digit {
                        text-decoration: underline;
                    }
                    .ico {
                        font-size: 150%;
                        vertical-align: text-bottom;
                        margin-right: 5px;
                    }
                }

                .statusCoin {
                    display: block;
                    margin-left: 2em;

                    span {
                        color: #e40007;
                        font-weight: bold;
                        font-size: 110%;
                    }
                }
            }
        }
        .bw_link-simple {
            font-weight: bold;
            font-size: 110%;
        }
    }

    &-iconBox {
        margin: 0 7px;
        padding: 8px 0 8px 10px;

        + .menu-iconBox {
            border-top: 1px dotted #cbced1;
        }

        li {
            padding: 3px 0;

            a {
                line-height: 1.6;

                span {
                    vertical-align: middle;

                    &.ico {
                        padding-right: 5px;
                        font-size: 120%;

                        &-txt {
                            font-weight: bold;
                        }

                        &-external-link {
                            font-size: 100%;
                            padding-left: 5px;
                        }

                        // 個別アイコン位置調整
                        &.ico-medal-ribbon {
                            padding: 0 7px 0 2px;
                        }
                    }

                    &.button-text {
                        font-weight: bold;
                    }
                }
            }

            .badge {
                display: inline-block;
                margin-left: 5px;
                padding: 2px 4px;
                background: #e9137a;
                border-radius: 8px;
                font-size: 80%;
                color: #fff;
                font-weight: bold;
                text-align: center;
            }
        }
    }
}

.header-hover-menu-content {
    position: absolute;
    z-index: 100;
    right: 0;
    top: 43px;
    background: #fff;
    padding: 3px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 0 0 #ccc;
    border-radius: 4px;
    cursor: default;
}

.header-hover-menu-cursor-area {
    margin: -60px -10px -10px -10px;
    padding: 60px 10px 10px 10px;
}

.header-hover-menu-cursor-area:active {
    pointer-events: none;
}

.header-hover-menu-content--inner {
    display: flex;
    pointer-events: auto;
}
.header-hover-menu-content--left {
    width: 220px;
}
.header-hover-menu-content--right {
    width: 200px;
}
