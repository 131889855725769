@charset "UTF-8";

// ==================
// clearfix
// ==================

%clearfix {
	&:after {
		display: block;
		content: '';
		clear: both;
	}
}
