// ==============================================
// 読み込み
// ==============================================

@import "../../../anne/scss/pc/templates/layout/mixin";
@import "../../../anne/scss/pc/atoms/buttons/button";

// ==============================================
// スタイル
// ==============================================

#btn-author-follow{
	position: relative;
}
#btn-label-follow{
	position: relative;
}

.btn-follow-area > .btn-follow-label a {
	@include hover;
}

.btn-follow-author {
	margin: 2px 0 5px 0;
	a {
		@extend .a-basic-btn;
		.btn-text {
			span {
				display: none;
			}
		}
	}
}

.btn-follow-author-del {
	margin: 2px 0 5px 0;
	a {
		@extend .a-basic-btn--registered;
		.btn-text {
			span {
				display: inline-block;
			}
		}
	}
}

.btn-follow-label {
    margin: 14px 0 0 0;
	a {
		@extend .a-basic-btn;
		.btn-text {
			span {
				display: none;
			}
		}
	}
}

.btn-follow-label-del {
	margin: 14px 0 0 0;
	a {
		@extend .a-basic-btn--registered;
		.btn-text {
			span {
				display: inline-block;
			}
		}
	}
}

#followWrap {
	background: #fff;
	margin: 0 0 14px 0;
	padding: 0 0 14px 0;
	height: 100px;
}

#followWrap h3{
float: left;
display: inline;
margin: 11px 0 0 11px;
font-weight: bold;
}

.side-tl-wrap p{
float: right;
display: inline;
margin: 5px 5px 0 0;
}

.side-tl-wrap p a {
	text-indent: -9999px;
	overflow: hidden;
	display: block;
	background: url(/louis/pc/img/common/btn-newslist.png) no-repeat 0 0;
	width: 42px;
	height: 23px;
}

.btn-label-follow {
 position: relative;
 float: left;
 margin: 8px 0px 0px 10px;
}
.btn-label-follow a {
 cursor: pointer;
 text-indent: -9999px;
 overflow: hidden;
 display: inline-block;
 background: url(/louis/pc/img/common/follow2_1off.png) no-repeat 0 0;
 width: 96px;
 height: 35px;
}
.btn-label-follow a:hover {
 background: url(/louis/pc/img/common/follow2_1on.png) no-repeat 0 bottom;
}

.btn-label-follow-del {
 float: left;
 margin: 8px 0px 0px 10px;
}
.btn-label-follow-del a {
 cursor: pointer;
 text-indent: -9999px;
 overflow: hidden;
 display: inline-block;
 background: url(/louis/pc/img/common/follow2_1on.png) no-repeat 0 0;
 width: 96px;
 height: 35px;
}
.btn-label-follow-del a:hover {
 background: url(/louis/pc/img/common/follow2_1on.png) no-repeat 0 bottom;
}


.btn-author-follow {
 position: relative;
 float: right;
 margin: 8px 8px 0px 10px;
}
.btn-author-follow a {
 cursor: pointer;
 text-indent: -9999px;
 overflow: hidden;
 display: inline-block;
 background: url(/louis/pc/img/common/follow2_2off.png) no-repeat 0 0;
 width: 96px;
 height: 35px;
}
.btn-author-follow a:hover {
 background: url(/louis/pc/img/common/follow2_2on.png) no-repeat 0 bottom;
}
.btn-authors-follow {
 position: relative;
 float: right;
 margin: 8px 8px 0px 10px;
}
.btn-authors-follow a {
 cursor: pointer;
 text-indent: -9999px;
 overflow: hidden;
 display: inline-block;
 background: url(/louis/pc/img/common/follow2_2off.png) no-repeat 0 0;
 width: 96px;
 height: 35px;
}
.btn-authors-follow a:hover {
 background: url(/louis/pc/img/common/follow2_2on.png) no-repeat 0 bottom;
}

.btn-authors-follow-del {
 position: relative;
 float: right;
 margin: 8px 8px 0 10px;
}
.btn-authors-follow-del a {
 cursor: pointer;
 text-indent: -9999px;
 overflow: hidden;
 display: inline-block;
 background: url(/louis/pc/img/common/follow2_2on.png) no-repeat 0 0;
 width: 96px;
 height: 35px;
}
.btn-authors-follow-del a:hover {
 background: url(/louis/pc/img/common/follow2_2on.png) no-repeat 0 bottom;
}


.btn-author-follow-del {
 position: relative;
 float: right;
 margin: 8px 8px 0px 10px;
}
.btn-author-follow-del a {
 cursor: pointer;
 text-indent: -9999px;
 overflow: hidden;
 display: inline-block;
 background: url(/louis/pc/img/common/follow2_2on.png) no-repeat 0 0;
 width: 96px;
 height: 35px;
}
.btn-author-follow-del a:hover {
 background: url(/louis/pc/img/common/follow2_2on.png) no-repeat 0 bottom;
}

.notice-fl-del {
	visibility: hidden; 
  position: absolute;
  z-index: 99999;
  opacity: 1;

  margin: 0 auto;
	padding: 15px;
  margin-bottom: 20px;

	font-weight: bold;
  width: 94%; 
  height: 20px;

	top: 9%;

	color: #E40007;
  background-color: rgba(245, 245, 245, 0.9);
  border-color: #E40007;
  border-top: 1px solid;
  border-bottom: 1px solid;

  animation: fadeout 0.5s linear 0.5s;
  animation-fill-mode: forwards;
}

.notice-fl-add {
	visibility: hidden; 
  position: absolute;
  z-index: 99999;
  opacity: 1;

  margin: 0 auto;
	padding: 15px;
  margin-bottom: 20px;

	font-weight: bold;
  width: 94%; 
  height: 20px;

	top: 9%;

	color: #E40007;
  background-color: rgba(245, 245, 245, 0.9);
  border-color: #E40007;
  border-top: 1px solid;
  border-bottom: 1px solid;

  animation: fadeout 1s linear 2s 1;
  animation-fill-mode: forwards;
  -webkit-animation: fadeout 1s linear 2s 1;
  -webkit-animation-fill-mode: forwards;
}

.notice-fl-mod {
	visibility: hidden; 
  position: absolute;
  z-index: 99999;
  opacity: 1;

  margin: 0 auto;
	padding: 15px;
  margin-bottom: 20px;

	font-weight: bold;
  width: 94%; 
  height: 20px;

	top: 9%;

	color: #E40007;
  background-color: rgba(245, 245, 245, 0.9);
  border-color: #E40007;
  border-top: 1px solid;
  border-bottom: 1px solid;

  animation: fadeout 1s linear 2s 1;
  animation-fill-mode: forwards;
  -webkit-animation: fadeout 1s linear 2s 1;
  -webkit-animation-fill-mode: forwards;
}

ul#followTips li{
	text-decoration: none;
	color: #929292;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	position:relative;
	overflow: visible;
	text-align: center;
}

ul#followTips li div#followTipsDetail {
	display:none;
	border: 1px solid #bababa;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	padding: 7px;
	position:absolute;
	right:16px;
	top:21px;
	background: #eee;
	width: 490px;
	font-size:90%;
	white-space: normal;
	z-index: 9999;
	text-align: left;
	color: #2f2f2f;
}
.followListLi{
	position: relative;
	background: #fff;
	padding-bottom: 3px;
	padding-top: 2px;

	border-bottom: 0.1em solid #f5f5f5; 
}
.followListLi:last-child{
	border: none;
}

.followListLi a{
	text-decoration: none;
	font-size: 100%;
}

.follow-genre {
	font-size: 93%;
	color: #929292;
	float: left;
	margin: 0 8px 4px 10px;
	font-weight: bold;
	width: 75px;
}

// ポップアップメッセージ
.follow-message-list {
	width: 100%;
	max-width: 300px;
    position: absolute;
	top: -20px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 14px;
	font-weight: bold;
	animation: fadeout 0.8s linear 0.8s;
	animation-fill-mode: forwards;
	padding: 12px;
	border: 1px solid $base-color;
	border-radius: 4px;
	box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
	background-color: #ffffff;
	pointer-events: auto;
	text-align: center;
	z-index: 9999;
}

.follow-message {
	background: none repeat scroll 0 0 #FFFFFF;
	border: 1px solid #FA0215;
	padding: 5px 9px 5px 12px; 
	text-align: center;
	position: absolute;
	z-index: 9999;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	filter: alpha(opacity=80);
	-moz-opacity: 0.8;
	opacity: 0.9;
	bottom: 25px;
	right: 80px;
	white-space: nowrap;

  animation: fadeout 3s linear 2s 1;
  animation-fill-mode: forwards;
  -webkit-animation: fadeout 3s linear 2s 1;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadeout {
  0%   {  opacity:1;      }
  70%  {  opacity:0.2;    }
  99%  {  opacity:0;      }
  100% {  opacity:0;    z-index: -100;}
}
@-webkit-keyframes fadeout {
  0%   {  opacity:1;      }
  70%  {  opacity:0.2;    }
  99%  {  opacity:0;      }
  100% {  opacity:0;    z-index: -100;}
}

.followTitle{
	width: 136px;
	height: 28px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
}
.followTitle > a {
	font-size: 12px;
}

.followIcons{
	width: 66px;
	position: absolute;
	right: 0;
	top: 3px;
}

.followIcon{
	height: 12px;
	width: 33px;
	border: 1px solid transparent; 
	box-sizing: border-box;
	background-size: cover;
	background-repeat: no-repeat;
}

.followIcon > .left {
	float: left;
}
.followIcon > .right {
	float: right;
}

div.new {
	background-image: url(/louis/pc/img/common/31x10_icon_new.png);
}
div.sakidori {
	background-image: url(/louis/pc/img/common/31x10_icon_check.png);
}
div.pointup {
	background-image: url(/louis/pc/img/common/31x10_icon_coin.png);
}
div.otoku {
	background-image: url(/louis/pc/img/common/31x10_icon_otoku.png);
}

li.followListLi p.btn-moreItem a {
    color: #fff;
    font-weight: bold;
}
