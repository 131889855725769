@charset "UTF-8";

/*
	for print
*/

@media print {

#whole,
#caontainer {
	width: 640px;
}

#headSec,
#gNaviSec,
#breadCrumb,
#footSec {
	display: none;
}






























}